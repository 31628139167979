export class Firm{
    public FirmID: number;
    public AppUserID: number;
    public FirmName: string;
    public Address: string;
    public ContactNo: string;
    public Email: string;
    public GSTIN: string;
    public BankDetails: string;
    public GSTRegType:string;
    public AccountNo :string;
    public IFSCCode:string;
    public AccountDetails:string;
    public  ContactDetails:string;
    public Terms:string;

    constructor(){}
}