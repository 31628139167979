import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CustomDateAdapter, CustomDateParserFormatter } from 'src/app/custom-ngbDateParserFormatter';
import { FormGroup, FormControl } from '@angular/forms';
import { ToDo } from 'src/app/todo.model';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from 'src/app/spinner.service';
import { ServerAPIService } from 'src/app/serverapi.service';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { UserProfile } from 'src/app/user-profile/user-profile.model';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-todo-log',
  templateUrl: './todo-log.component.html',
  styleUrls: ['./todo-log.component.css'],
  providers: [{provide: NgbDateAdapter, useClass: CustomDateAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}]
})
export class TodoLogComponent implements OnInit {
  currentDate = new Date();
  todoLog:ToDo[];
  todologAssignedTo:ToDo[];
  users:object[];
  assid:number=0;
  selectedTasks:boolean = true;
  todoAssistantId:number=0;
  dtOptions: DataTables.Settings = {};
  dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();
  dtTrigger1: Subject<any> = new Subject();
   profileForm = new FormGroup({
    txtFromDate: new FormControl(''),
    txtToDate:new FormControl(''),
    ddlUsers:new FormControl(''),
    ddlStatus:new FormControl('')
  });
  toDoStatusArray:string[];

  constructor(public serverAPIService:ServerAPIService,
    private router:Router, private toaster:ToastrService, private spinnerService:SpinnerService
    ,private route : ActivatedRoute,private ref: ChangeDetectorRef) {
      this.dtOptions = {
        processing: true,
        paging:false,
        destroy: true,
        order:[],
      }
  }

  setAssistant(data: { value: string }): void {
      this.todoAssistantId=Number ( data);
   }

  // convenience getter for easy access to form fields
   get f() { return this.profileForm.controls; }

  ngOnInit() {
    $(".btn-group > .btn").click(function(){
      $(this).addClass("active").siblings().removeClass("active");
    });

    this.toDoStatusArray=[''];
    this.toDoStatusArray.push("Pending");
    this.toDoStatusArray.push("Completed");
    this.toDoStatusArray.shift();
    this.f.ddlStatus.setValue("Pending");

    let day:number=this.currentDate.getDate();
    let month:number=this.currentDate.getMonth();
    let year:number=this.currentDate.getFullYear();
    let fromDateYear:number = year;

    if(month<3)
      fromDateYear=fromDateYear-1;
    this.f.txtFromDate.setValue('01/'+'04/'+year);

    this.f.txtToDate.setValue((day.toString().length==1?'0'+day.toString():day.toString())+'/'+((month+1).toString().length==1
      ?'0'+(month+1).toString()
      :(month+1).toString())
      +'/'+year.toString());
      this.getAssistant();
  }

  viewRecord(){
    if (this.selectedTasks) {
    $('#example').DataTable().destroy();
    this.todoLog=[];
    this.dtTrigger.next();
      setTimeout(() => {
        this.getData();
      },0)
    }
    else{
      $('#example1').DataTable().destroy();
      this.todologAssignedTo=[];
      this.dtTrigger1.next();
      setTimeout(() => {
        this.getDataAssignedTo();
      },0)
    }
  }

   getData(){
    this.spinnerService.show();
    this.serverAPIService.getToDoLog(this.f.ddlUsers.value,this.f.txtFromDate.value,this.f.txtToDate.value)
    .subscribe(
      (response : ToDo[]) => {
        this.todoLog=response;
        if(this.f.ddlStatus.value == 'Pending')
          this.todoLog =  this.todoLog.filter(x =>  x.Status == 'Pending') ;
        else
          this.todoLog =  this.todoLog.filter(x =>  x.Status != 'Pending') ;
        this.dtTrigger.next();
        this.ref.detectChanges();
        this.spinnerService.hide();
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error("Something went wrong!");
      }
    )
  }

  getDataAssignedTo(){
    this.spinnerService.show();
    this.serverAPIService.getToDoLogAssignedTo(this.f.ddlUsers.value,this.f.txtFromDate.value,this.f.txtToDate.value)
    .subscribe(
      (response : ToDo[]) => {
        this.todologAssignedTo=response;
        if(this.f.ddlStatus.value == 'Pending')
          this.todologAssignedTo =  this.todologAssignedTo.filter(x =>  x.Status == 'Pending') ;
        else
          this.todologAssignedTo =  this.todologAssignedTo.filter(x =>  x.Status != 'Pending') ;
        this.dtTrigger1.next();
        this.ref.detectChanges();
        this.spinnerService.hide();
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error("Something went wrong!");
      }
    )
  }

   getAssistant(){
    this.serverAPIService.getAllUsers()
    .subscribe(
      (response : UserProfile[]) => {
        this.users = [{ id: 0, text: ''}];
        response.forEach(element => {
          this.users.push({id:element.AppUserID,text:element.AppUserName});
         });
         this.users.shift();
        this.f.ddlUsers.setValue(response.find(x=>x.AppUserName == "Self").AppUserID);
        this.getData();
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error("Something went wrong!");
      }
    )
  }

  switchTasks(isMyToDos:boolean){
        $('#example').DataTable().destroy();
        this.todoLog=[];
        this.dtTrigger=new Subject();
        this.dtTrigger.next();
        $('#example1').DataTable().destroy();
        this.todologAssignedTo=[];
        this.dtTrigger1=new Subject();
        this.dtTrigger1.next();
        if (isMyToDos) {
          this.selectedTasks = isMyToDos;
          setTimeout(() => {
            this.getData();
            this.dtTrigger.next();
          },0)
        }
        else{
          this.selectedTasks = isMyToDos;
          setTimeout(() => {
            this.getDataAssignedTo();
            this.dtTrigger1.next();
          },0)
        }
  }

}
