import { Injectable } from "@angular/core";
import { Http, Response, Headers, ResponseOptions } from '@angular/http';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of, BehaviorSubject, throwError } from "rxjs";
import "rxjs/Rx";
import { Router } from "@angular/router";
import { UserProfile } from './user-profile/user-profile.model';
import { ChangePassword } from './user-profile/change-password/change-password.model';
import { ForgotPassword } from './auth/forgot-password/forgot-password.model';
import { map, catchError } from 'rxjs/operators';
import { Firm } from './masters/firm.model';
import { Client } from './masters/client.model';
import { Task } from './user-service/task.model';
import { Work } from './user-service/work.model';
import { WorkDetails } from './user-service/work-details.model';
import { ToDo } from './todo.model';
import { SaleBill } from './sale-bill/salebill.model';
import { Receipt } from './receipt/receipt.model';
import { InwardOutward } from './inward-outward/inward-outward.model';
import { AdminDetails } from './purchase-form/admindetails.model';
import { PendingTasksReport } from './home/pending-tasks-report/pending-tasks-report.model';

export interface AuthToken{
    access_token: string;
    token_type: string;
    userName: string;
    expires_in: number;
    userFullName: string;
    userRole: string;
    userId: string;

  }

  @Injectable({
    providedIn: 'root'
  })

  export class ServerAPIService{
    //  public domainUrl: string ='http://localhost:6954/';//'https://api.aareasy.in/';//
    //  public webUrl: string ='http://localhost:4200/';//'https://aareasy.in/';//
       /** LIVE BE CAREFULL **/
    public domainUrl: string ='https://api.aareasy.in/';//
    public webUrl: string ='https://aareasy.in/';//
    /** LIVE BE CAREFULL **/
    //private authState = new BehaviorSubject<string>(this.isAuthenticated()?"LoggedIn":"LoggedOut");

    constructor(private http: Http,private router: Router){}
    public username : string;
    private loginRequestQueryParams : any;
    public GstRegServiceStatus:string[]=['Partial Data Received','Data Received','Work in Progess'
                                        ,'Applied','Completed','On Hold'];
    public TaskGSTReg:string="GST Registration";
    public TaskPanApplication:string="PAN Application";
    public TaskTanApplication:string="TAN Application";
    public TaskPTECReg:string="PTEC Registration";
    public TaskPTRCReg:string="PTRC Registration";
    public BillingTypes:string[]=['Monthly','Quarterly','Half Yearly','Yearly','One Time'];
    public GstMonthlyServiceStatus:string[]=['Partial Data Received','Data Received','Pending for Tax'
                                        ,'Work in Progess','Completed','On Hold'];
    public AccountingServiceStatus:string[]=['Partial Data Received','Data Received','Work in Progess','Completed','On Hold'];
    public ITAuditServiceStatus:string[]=['Partial Data Received','Data Received','Work in Progess','Completed','On Hold'];
    public ITReturnServiceStatus:string[]=['Partial Data Received','Data Received','Accounting Completed'
        ,'Work in Progess','Pending for Tax','Completed','On Hold'];
    public PTRCServiceStatus:string[]=['Partial Data Received','Data Received','Pending for Tax'
        ,'Work in Progess','Completed','On Hold'];
    public PTECServiceStatus:string[]=['Pending for Tax','Work in Progess','Completed','On Hold'];
    public AssesmentServiceStatus:string[]=['Data Received','Hearing On','Completed','On Hold'];
    public AllStatus:string[]=['','Open','Partial Data Received','Data Received','Accounting Completed'
        ,'Work in Progess','Pending for Tax','Hearing On','Completed','On Hold'];
    public smsTemplate:string="Hi {OwnerName}, status of your {TaskName} task for {Period} is {TaskStatus}.";
    public whatsappTemplate:string="नमस्कार {OwnerName}, आपल्या {TaskName} {Period} कामाचे सध्याचे स्टेटस {TaskStatus} आहे. त्याबद्दलची अधिकची माहिती खालील प्रमाणे,<br>"

    autoLogin(){
        return this.login(this.loginRequestQueryParams,this.username);
    }

    changePassword(obj:ChangePassword){
        return this.http.post(this.domainUrl+'api/Account/ChangePassword',obj,
        {headers: this.getAuthorizationHeader()})
        .map(
            (response: Response) => {
                return response = response;
            }
        )
        .catch(
            (error: Response) => {
                if(error.status === 401){
                    this.logout();
                    this.router.navigate(['/signin']);
                }else
                    return Observable.throw(error)
            }
        );
    }

    forgotPassword(obj:ForgotPassword){
      return this.http.post(this.domainUrl+'api/Account/ForgotPassword',obj,
      {headers: this.getAuthorizationHeader()})
      .map(
          (response: Response) => {
              return response = response;
          }
      )
      .catch(
          (error: Response) => {
              if(error.status === 401){
                  this.logout();
                  this.router.navigate(['/signin']);
              }else
                  return Observable.throw(error)
          }
      );
  }

    login(obj:any,username:string){

        this.loginRequestQueryParams = obj;
        const headers =  new Headers({"Content-Type":"application/x-www-form-urlencoded" });
        this.username = username;

        //console.log(InActive);
        return this.http.post(this.domainUrl+'TOKEN',obj, {headers: headers})
        .map(
            (response: Response) => {
                const data = response.json();
                if(response.status == 200){
                    localStorage.setItem('userName',data.userName);
                    localStorage.setItem('access_token',data.access_token);
                    localStorage.setItem('userRole',data.userRole);
                    localStorage.setItem('userId',data.userId);
                    localStorage.setItem('userFullName',data.userFullName);
                    localStorage.setItem('customerName',data.customerName);
                    localStorage.setItem('mobileNo',data.mobileNo);
                   // localStorage.setItem('InActive',data.InActive);
                    // localStorage.setItem('pin_no',data.pin_no);
                    //console.log('LOGIN SUCCESS');
                    this.postLoginOperations().then(() => {
                        //console.log('PLO SUCCESS');
                    }).catch((e) => console.log(e));
                    // console.log('AFTER PLO');
                }
                return response;
            }
        )
        .catch(
            (error: Response) => {
                return Observable.throw(error)
            }
        );

    }

    postLoginOperations(){

        return new Promise(resolve => {
           // this.setUserName();
            resolve();
        })

    }

    /** UserController Requests **/
    getUserProfile(){
        return this.http.get(this.domainUrl+'api/Customer/CustomerByUserID?mobileNo='+this.getLoggedInUser(),
        {headers:this.getAuthorizationHeader()})
        .map(
            (response: Response) => {
                return response.json();
            }
        )
        .catch(
            (error: Response) => {
                if(error.status === 401){
                    this.logout();
                    this.router.navigate(['/signin']);
                }else
                    return Observable.throw(error)
            }
        );
    }

    // setUserName(){
    //     this.getUserProfile()
    //     .subscribe(
    //         (data:UserProfile) => localStorage.setItem('user',data.UserName),
    //         (error:Response) => console.log(error)
    //     );
    // }

      /** Local Methods for Validation **/
      isAuthenticated(){
        return this.getAccessToken() != null;
    }
    isActive()
    {
      return this.getInActive()==null;
    }

    getAccessToken(){
        return localStorage.getItem("access_token");
    }

    getLoggedInUser(){
        return localStorage.getItem("userName");
    }

    getLoggedInUsersName(){
        return localStorage.getItem("userFullName");
    }
    getLoggedInUserId(){
        return localStorage.getItem('userId');
    }

    getCustomerName(){
        return localStorage.getItem("customerName");
    }

    getCAMobile(){
      return localStorage.getItem("mobileNo");
    }

    getLoggedInUsersRole(){
        return localStorage.getItem("userRole");
    }
    getInActive(){
      const headers = this.getAuthorizationHeader();
        return this.http.get(this.domainUrl+'api/Dashboard/GetLogin', { headers : headers })
        .pipe(map(
            (response) => {
                return response;
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));

  }

    logout(){
        localStorage.removeItem("userName");
        localStorage.removeItem("access_token");
        localStorage.removeItem("user");
        localStorage.removeItem("customerName");
    }

    getAuthorizationHeader(){
        return new Headers({"Authorization":"Bearer "+this.getAccessToken()});
    }

    createAssistant(obj: UserProfile){
        const headers = this.getAuthorizationHeader();
        return this.http.post(this.domainUrl+'api/ApiUser/CreateAssistant', obj, { headers : headers })
        .pipe(map(
            (response) => {
                return response;
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));

    }

    checkuserexists(userName:string) {
        const headers = this.getAuthorizationHeader();
        return this.http.get(this.domainUrl+'api/Account/CheckUserExists?userName='+userName,{headers:headers})
            .map(
                (response: Response) => {
                    return response.json();
                }
            )
            .catch(
                (error: Response) => {
                    return Observable.throw(error)
                }
            );
    }

    getAssistants(){
        const headers = this.getAuthorizationHeader();
        return this.http.get(this.domainUrl+'api/ApiUser/AllAssistants', { headers : headers })
        .pipe(map(
            (response) => {
                return response.json();
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));
    }

      getAccountById(id:number){
        const headers = this.getAuthorizationHeader();
        return this.http.get(this.domainUrl+'api/ApiUser/UserByID?ID='+id, { headers : headers })
        .pipe(map(
            (response) => {
                return response.json();
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));
      }

      updateAccount(obj: UserProfile){
        const headers = this.getAuthorizationHeader();
        return this.http.put(this.domainUrl+'api/ApiUser/UpdateUser', obj, { headers : headers })
        .pipe(map(
            (response) => {
                return response;
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));

      }

      getFirms(){
        const headers = this.getAuthorizationHeader();
        return this.http.get(this.domainUrl+'api/Firm/UserFirms', { headers : headers })
        .pipe(map(
            (response) => {
                return response.json();
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));
      }

      getFirmById(id:number){
        const headers = this.getAuthorizationHeader();
        return this.http.get(this.domainUrl+'api/Firm/FirmByID?ID='+id, { headers : headers })
        .pipe(map(
            (response) => {
                return response.json();
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));
      }

      updateFirm(obj: Firm){
        if(this.isAuthenticated()){
            return this.http.put(this.domainUrl+'api/Firm/UpdateFirm',obj,
            {headers:this.getAuthorizationHeader()})
            .map(
                (response: Response) => {
                    return response;
                }
            )
            .catch(
                (error: Response) => {
                    if(error.status === 401){
                        this.logout();
                        this.router.navigate(['/signin']);
                    }else
                        return Observable.throw(error)
                }
            );
        }
        }

      createFirm(obj: Firm){
        const headers = this.getAuthorizationHeader();
        return this.http.post(this.domainUrl+'api/Firm/CreateFirm', obj, { headers : headers })
        .pipe(map(
            (response) => {
                return response;
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));

      }

      getClients(){
        const headers = this.getAuthorizationHeader();
        return this.http.get(this.domainUrl+'api/Client/UserClients', { headers : headers })
        .pipe(map(
            (response) => {
                return response.json();
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));
      }

      getClientsList(serviceId:number){
        const headers = this.getAuthorizationHeader();
        return this.http.get(this.domainUrl+'api/Client/ClientList?serviceId='+serviceId, { headers : headers })
        .pipe(map(
            (response) => {
                return response.json();
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));
      }

      getClientById(id:number){
        const headers = this.getAuthorizationHeader();
        return this.http.get(this.domainUrl+'api/Client/ClientByID?ID='+id, { headers : headers })
        .pipe(map(
            (response) => {
                return response.json();
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));
      }

      createClient(obj: Client){
        const headers = this.getAuthorizationHeader();
        return this.http.post(this.domainUrl+'api/Client/CreateClient', obj, { headers : headers })
        .pipe(map(
            (response) => {
                return response;
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));

      }

      checkClientsExists(entityName:string){
        const headers = this.getAuthorizationHeader();
        return this.http.get(this.domainUrl+'api/Client/ClientExists?entityName='+entityName, { headers : headers })
        .pipe(map(
            (response) => {
                return response.json();
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));
      }

      checkClientExists(entityName:string){
        const headers = this.getAuthorizationHeader();
        return this.http.get(this.domainUrl+'api/Client/ClientExists?entityName='+entityName, { headers : headers })
        .pipe(map(
            (response) => {
                return response.json();
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));
      }

      getServices(){
        const headers = this.getAuthorizationHeader();
        return this.http.get(this.domainUrl+'api/Service/AllServices', { headers : headers })
        .pipe(map(
            (response) => {
                return response.json();
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));
      }

      getServiceTaskForUser(serviceId:number){
        const headers = this.getAuthorizationHeader();
        return this.http.get(this.domainUrl+'api/Service/ServiceTaskOfUser?serviceId='+serviceId, { headers : headers })
        .pipe(map(
            (response) => {
                return response.json();
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));
      }

      getUserTaskById(id:number){
        const headers = this.getAuthorizationHeader();
        return this.http.get(this.domainUrl+'api/Service/ServiceTaskOfUserById?taskId='+id, { headers : headers })
        .pipe(map(
            (response) => {
                return response.json();
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));
      }

      createCustomerTask(obj: Task){
        const headers = this.getAuthorizationHeader();
        return this.http.post(this.domainUrl+'api/Service/CreateCustomerTask', obj, { headers : headers })
        .pipe(map(
            (response) => {
                return response;
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));

      }

      updateCustomerTask(obj: Task){
        const headers = this.getAuthorizationHeader();
        return this.http.put(this.domainUrl+'api/Service/UpdateCustomerTask', obj, { headers : headers })
        .pipe(map(
            (response) => {
                return response;
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));

      }

      updateClient(obj: Client){
        const headers = this.getAuthorizationHeader();
        return this.http.put(this.domainUrl+'api/Client/UpdateClient', obj, { headers : headers })
        .pipe(map(
            (response) => {
                return response;
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));

      }

      getServiceTaskForClient(clientId:number,fy_year:string){
        const headers = this.getAuthorizationHeader();
        return this.http.get(this.domainUrl+'api/Service/ClientsServices?ClientID='+clientId+'&fin_year='+fy_year, { headers : headers })
        .pipe(map(
            (response) => {
                return response.json();
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));
      }

      getUsersFy(){
        const headers = this.getAuthorizationHeader();
        return this.http.get(this.domainUrl+'api/Service/UsersFy', { headers : headers })
        .pipe(map(
            (response) => {
                return response.json();
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));
      }

      getServiceTaskForClientUnadded(clientId:number,fy_year:string){
        const headers = this.getAuthorizationHeader();
        return this.http.get(this.domainUrl+'api/Service/ClientsUnaddedServices?ClientID='+clientId+'&fin_year='+fy_year, { headers : headers })
        .pipe(map(
            (response) => {
                return response.json();
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));
      }

      createRegTask(obj: Work){
        const headers = this.getAuthorizationHeader();
        return this.http.post(this.domainUrl+'api/Work/CreateGSTRegService', obj, { headers : headers })
        .pipe(map(
            (response) => {
                return response;
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));

      }

      getTasks(datestr:string,custId:number,assistantId:number,status:string){
        const headers = this.getAuthorizationHeader();
        //return this.http.get(this.domainUrl+'api/Service/ClientsServices?ClientID='+clientId+'&fin_year='+fy_year, { headers : headers })
        return this.http.get(this.domainUrl+'api/Work/GetPendingTasks?date='+datestr+'&customerId='+custId+'&assistantId='
        +assistantId+'&taskStatus='+status, { headers : headers })
        .pipe(map(
            (response) => {
                return response.json();
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));
      }

      updateWorkDetails(obj: WorkDetails){
        const headers = this.getAuthorizationHeader();
        return this.http.put(this.domainUrl+'api/Work/UpdateWorkStatus', obj, { headers : headers })
        .pipe(map(
            (response) => {
                return response;
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));

      }

      updateWorkDetails1(obj: PendingTasksReport){
        const headers = this.getAuthorizationHeader();
        return this.http.put(this.domainUrl+'api/Work/UpdateWorkStatus', obj, { headers : headers })
        .pipe(map(
            (response) => {
                return response;
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));

      }

      UpdateWorkStatusPending(obj: PendingTasksReport){
        const headers = this.getAuthorizationHeader();
        return this.http.put(this.domainUrl+'api/Work/UpdateWorkStatusPending', obj, { headers : headers })
        .pipe(map(
            (response) => {
                return response;
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));

      }

      updateClientgstdetails(obj: Client){
        const headers = this.getAuthorizationHeader();
        return this.http.put(this.domainUrl+'api/Client/UpdateGSTDetails', obj, { headers : headers })
        .pipe(map(
            (response) => {
                return response;
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));

      }

      getCurrentFy(){
        const headers = this.getAuthorizationHeader();
        //return this.http.get(this.domainUrl+'api/Service/ClientsServices?ClientID='+clientId+'&fin_year='+fy_year, { headers : headers })
        return this.http.get(this.domainUrl+'api/Values/GetCurrentFY', { headers : headers })
        .pipe(map(
            (response) => {
                return response.json();
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));
      }

      deleteService(serviceId:number,clientId:number,fy:string){
        const headers = this.getAuthorizationHeader();
        return this.http.delete(this.domainUrl+'api/Service/DeleteService?serviceId='+serviceId
        +'&clientId='+clientId+'&fy='+fy, { headers : headers })
        .pipe(map(
            (response) => {
                return response;
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));

      }

      createGSTMonthlyTask(obj: Work){
        const headers = this.getAuthorizationHeader();
        return this.http.post(this.domainUrl+'api/Work/CreateGSTMonthlyService', obj, { headers : headers })
        .pipe(map(
            (response) => {
                return response;
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));

      }

      createGSTQuarterlyTask(obj: Work){
        const headers = this.getAuthorizationHeader();
        return this.http.post(this.domainUrl+'api/Work/CreateGSTQuarterlyService', obj, { headers : headers })
        .pipe(map(
            (response) => {
                return response;
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));

      }

      getServiceById(serviceId:number){
        const headers = this.getAuthorizationHeader();
        //return this.http.get(this.domainUrl+'api/Service/ClientsServices?ClientID='+clientId+'&fin_year='+fy_year, { headers : headers })
        return this.http.get(this.domainUrl+'api/Service/GetServiceById?id='+serviceId, { headers : headers })
        .pipe(map(
            (response) => {
                return response.json();
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));
      }

      createGSTAnnualService(obj: Work){
        const headers = this.getAuthorizationHeader();
        return this.http.post(this.domainUrl+'api/Work/CreateGSTAnnualService',
            obj, { headers : headers })
        .pipe(map(
            (response) => {
                return response;
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));

      }

      getStatusChange(workDetailsId:number){
        const headers = this.getAuthorizationHeader();
        //return this.http.get(this.domainUrl+'api/Service/ClientsServices?ClientID='+clientId+'&fin_year='+fy_year, { headers : headers })
        return this.http.get(this.domainUrl+'api/Work/GetStatusChangeDetails?wdid='+workDetailsId, { headers : headers })
        .pipe(map(
            (response) => {
                return response.json();
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));
      }

      createAccountingTask(obj: Work){
        const headers = this.getAuthorizationHeader();
        return this.http.post(this.domainUrl+'api/Work/CreateAccountingService', obj, { headers : headers })
        .pipe(map(
            (response) => {
                return response;
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));

      }

      createITAuditTask(obj: Work){
        const headers = this.getAuthorizationHeader();
        return this.http.post(this.domainUrl+'api/Work/CreateITAuditService', obj, { headers : headers })
        .pipe(map(
            (response) => {
                return response;
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));

      }

      createITReturnTask(obj: Work){
        const headers = this.getAuthorizationHeader();
        return this.http.post(this.domainUrl+'api/Work/CreateITReturnService', obj, { headers : headers })
        .pipe(map(
            (response) => {
                return response;
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));

      }

      createPTRCMonthlyTask(obj: Work){
        const headers = this.getAuthorizationHeader();
        return this.http.post(this.domainUrl+'api/Work/CreatePTRCMonthlyService', obj, { headers : headers })
        .pipe(map(
            (response) => {
                return response;
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));

      }

      createPTRCYearlyTask(obj: Work){
        const headers = this.getAuthorizationHeader();
        return this.http.post(this.domainUrl+'api/Work/CreatePTRCYearlyService', obj, { headers : headers })
        .pipe(map(
            (response) => {
                return response;
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));

      }

      createPTECTask(obj: Work){
        const headers = this.getAuthorizationHeader();
        return this.http.post(this.domainUrl+'api/Work/CreatePTECService', obj, { headers : headers })
        .pipe(map(
            (response) => {
                return response;
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));

      }

      createAssessmentTask(obj: Work){
        const headers = this.getAuthorizationHeader();
        return this.http.post(this.domainUrl+'api/Work/CreateAssesmentService', obj, { headers : headers })
        .pipe(map(
            (response) => {
                return response;
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));

      }

      createTdsTask(obj: Work){
        const headers = this.getAuthorizationHeader();
        return this.http.post(this.domainUrl+'api/Work/CreateTDSService', obj, { headers : headers })
        .pipe(map(
            (response) => {
                return response;
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));

      }

       createMonthlyAccTask(obj: Work){
        const headers = this.getAuthorizationHeader();
        return this.http.post(this.domainUrl+'api/Work/CreateAccountingMonthlyService', obj, { headers : headers })
        .pipe(map(
            (response) => {
                return response;
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));

      }

       getDashboardCnt(){
        const headers = this.getAuthorizationHeader();
        //return this.http.get(this.domainUrl+'api/Service/ClientsServices?ClientID='+clientId+'&fin_year='+fy_year, { headers : headers })
        return this.http.get(this.domainUrl+'api/Dashboard/GetCounts', { headers : headers })
        .pipe(map(
            (response) => {
                return response.json();
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));
      }

      getUserwiseProgress(){
        const headers = this.getAuthorizationHeader();
        //return this.http.get(this.domainUrl+'api/Service/ClientsServices?ClientID='+clientId+'&fin_year='+fy_year, { headers : headers })
        return this.http.get(this.domainUrl+'api/Dashboard/GetUserwiseProgress', { headers : headers })
        .pipe(map(
            (response) => {
                return response.json();
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));
      }

       getTaskwiseCount(serviceType:string,fromDate:string,toDate:string){
        const headers = this.getAuthorizationHeader();
        //return this.http.get(this.domainUrl+'api/Service/ClientsServices?ClientID='+clientId+'&fin_year='+fy_year, { headers : headers })
        return this.http.get(this.domainUrl+'api/Dashboard/GetTaskwiseCount?serviceType='+serviceType
        +'&fromDate='+fromDate+'&toDate='+toDate, { headers : headers })
        .pipe(map(
            (response) => {
                return response.json();
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));
      }

      getUserwiseTask(fromDate:string,toDate:string,appUserId:number,clientId?:number){
        const headers = this.getAuthorizationHeader();
        if(clientId==null)
        {
            return this.http.get(this.domainUrl+'api/Dashboard/GetUserwiseWork?fromDate='+fromDate
            +'&toDate='+toDate+'&AppUserId='+appUserId+'&clientId=0', { headers : headers })
            .pipe(map(
                (response) => {
                    return response.json();
                }
            ),catchError(
                (error: Response) => {
                    return throwError(error);
                }
            ));
        }
        else
        {
            return this.http.get(this.domainUrl+'api/Dashboard/GetUserwiseWork?fromDate='+fromDate
            +'&toDate='+toDate+'&AppUserId='+appUserId+'&clientId='+clientId, { headers : headers })
            .pipe(map(
                (response) => {
                    return response.json();
                }
            ),catchError(
                (error: Response) => {
                    return throwError(error);
                }
            ));
        }
      }

      deleteClient(clientId:number){
        const headers = this.getAuthorizationHeader();
        return this.http.delete(this.domainUrl+'api/Client/DeleteClient?clientId='+clientId, { headers : headers })
        .pipe(map(
            (response) => {
                return response;
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));

      }

      getClientWork(workId:number){
        const headers = this.getAuthorizationHeader();
        //return this.http.get(this.domainUrl+'api/Service/ClientsServices?ClientID='+clientId+'&fin_year='+fy_year, { headers : headers })
        return this.http.get(this.domainUrl+'api/Service/GetWorkOfClient?workId='+workId
        , { headers : headers })
        .pipe(map(
            (response) => {
                return response.json();
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));
      }

      updateWork(obj: Work){
        const headers = this.getAuthorizationHeader();
        return this.http.post(this.domainUrl+'api/Work/UpdateWork', obj, { headers : headers })
        .pipe(map(
            (response) => {
                return response;
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));

      }

      getTaskList(){
        const headers = this.getAuthorizationHeader();
        return this.http.get(this.domainUrl+'api/Work/GetTaskName'
        , { headers : headers })
        .pipe(map(
            (response) => {
                return response.json();
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));
      }

      getGSTCustomerWiseTasks(fromDate:string){
        const headers = this.getAuthorizationHeader();
        return this.http.get(this.domainUrl+'api/Dashboard/GetCustomerwisewiseCount?fromDate='+fromDate
        , { headers : headers })
        .pipe(map(
            (response) => {
                return response.json();
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));
      }

      getITCustomerWiseTasks(){
        const headers = this.getAuthorizationHeader();
        return this.http.get(this.domainUrl+'api/Dashboard/GetCustomerwisewiseCountIT'
        , { headers : headers })
        .pipe(map(
            (response) => {
                return response.json();
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));
      }

      getOtherCustomerWiseTasks(fromDate:string){
        const headers = this.getAuthorizationHeader();
        return this.http.get(this.domainUrl+'api/Dashboard/GetCustomerwisewiseCountOther?fromDate='+fromDate
        , { headers : headers })
        .pipe(map(
            (response) => {
                return response.json();
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));
      }

      sendAlert(workDetailsId:number,remark:string,signature:string){
        const headers = this.getAuthorizationHeader();
        return this.http.post(this.domainUrl+'api/Work/SendAlert',{workdetailsId:workDetailsId,remark:remark,signature:signature},  { headers : headers })
        .pipe(map(
            (response) => {
                return response;
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));

      }

      sendAlertWhatsApp(workDetailsId:number,remark:string,signature:string){
        const headers = this.getAuthorizationHeader();
        return this.http.post(this.domainUrl+'api/Work/SendAlertWhatsApp',{workdetailsId:workDetailsId,remark:remark,signature:signature},  { headers : headers })
        .pipe(map(
            (response) => {
                return response;
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));

      }

      getSenderId(){
        const headers = this.getAuthorizationHeader();
        return this.http.get(this.domainUrl+'api/Work/GetSmsSenderId'
        , { headers : headers })
        .pipe(map(
            (response) => {
                return response.json();
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));
      }

      getWhatsAppSenderId(){
        const headers = this.getAuthorizationHeader();
        return this.http.get(this.domainUrl+'api/Work/GetWhatsAppSenderId'
        , { headers : headers })
        .pipe(map(
            (response) => {
                return response.json();
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));
      }

      getSmsLog(workDetailsId:number){
        const headers = this.getAuthorizationHeader();
        return this.http.get(this.domainUrl+'api/Work/GetSmsLog?WorkDetailsID='+workDetailsId
        , { headers : headers })
        .pipe(map(
            (response) => {
                return response.json();
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));
      }

      getTaskPopupData(ServiceID:number,TaskID:number,status:string,ServiceType:string,fromDate:string,toDate:string){
        const headers = this.getAuthorizationHeader();
        return this.http.get(this.domainUrl+'api/Dashboard/GetTaskPopupData?ServiceID='+ServiceID
        +'&TaskID='+TaskID+'&status='+status+'&ServiceType='+ServiceType+'&fromDate='+fromDate+'&toDate='+toDate
        , { headers : headers })
        .pipe(map(
            (response) => {
                return response.json();
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));
      }

      getTodaysAssignedToDoes(){
        const headers = this.getAuthorizationHeader();
        return this.http.get(this.domainUrl+'api/ToDo/TodaysAssignedToDoes'
        , { headers : headers })
        .pipe(map(
            (response) => {
                return response.json();
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));
      }

      getAllUsers(){
        const headers = this.getAuthorizationHeader();
        return this.http.get(this.domainUrl+'api/ToDo/GetUsers', { headers : headers })
        .pipe(map(
            (response) => {
                return response.json();
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));
    }

    getToDos(){
        const headers = this.getAuthorizationHeader();
        return this.http.get(this.domainUrl+'api/ToDo/TodaysUserToDoes', { headers : headers })
        .pipe(map(
            (response) => {
                return response.json();
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));
    }

    createToDo(obj: ToDo){
        const headers = this.getAuthorizationHeader();
        return this.http.post(this.domainUrl+'api/ToDo/CreateToDo', obj, { headers : headers })
        .pipe(map(
            (response) => {
                return response;
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));

      }

      updateToDo(obj: ToDo){
        const headers = this.getAuthorizationHeader();
        return this.http.put(this.domainUrl+'api/ToDo/UpdateToDo', obj, { headers : headers })
        .pipe(map(
            (response) => {
                return response;
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));

      }

    deleteToDo(todoId:number){
    const headers = this.getAuthorizationHeader();
    return this.http.delete(this.domainUrl+'api/ToDo/DeleteToDo?todoid='+todoId, { headers : headers })
    .pipe(map(
        (response) => {
            return response;
        }
    ),catchError(
        (error: Response) => {
            return throwError(error);
        }
    ));

    }

    getbills(fromDate:string,toDate:string){
        const headers = this.getAuthorizationHeader();
        return this.http.get(this.domainUrl+'api/SaleBill/GetBills?fromDate='+fromDate+'&toDate='+toDate, { headers : headers })
        .pipe(map(
            (response) => {
                return response.json();
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));
    }

    createGSTComposotionTask(obj: Work){
        const headers = this.getAuthorizationHeader();
        return this.http.post(this.domainUrl+'api/Work/CreateGSTCompositionService', obj, { headers : headers })
        .pipe(map(
            (response) => {
                return response;
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));

      }

    getbillById(salebillId:number){
        const headers = this.getAuthorizationHeader();
        return this.http.get(this.domainUrl+'api/SaleBill/GetBillByID?salebillId='+salebillId, { headers : headers })
        .pipe(map(
            (response) => {
                return response.json();
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));
    }

    generateBill(obj: SaleBill){
        const headers = this.getAuthorizationHeader();
        return this.http.post(this.domainUrl+'api/SaleBill/GenerateBill', obj, { headers : headers })
        .pipe(map(
            (response) => {
                return response;
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));

      }

    deleteBill(salebillId:number){
        const headers = this.getAuthorizationHeader();
        return this.http.delete(this.domainUrl+'api/SaleBill/DeleteBill?saleBillId='+salebillId, { headers : headers })
        .pipe(map(
            (response) => {
                return response;
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));

      }

      updateBill(obj: SaleBill){
        const headers = this.getAuthorizationHeader();
        return this.http.put(this.domainUrl+'api/SaleBill/UpdateBill', obj, { headers : headers })
        .pipe(map(
            (response) => {
                return response;
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));

      }

      getClientsServiceList(clientId:number){
        const headers = this.getAuthorizationHeader();
        return this.http.get(this.domainUrl+'api/Work/GetWorksListForClient?clientId='+clientId, { headers : headers })
        .pipe(map(
            (response) => {
                return response.json();
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));
      }

      createBill(obj: SaleBill){
        const headers = this.getAuthorizationHeader();
        return this.http.post(this.domainUrl+'api/SaleBill/CreateBill', obj, { headers : headers })
        .pipe(map(
            (response) => {
                return response;
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));

      }

      addToDoComment(obj: ToDo){
        const headers = this.getAuthorizationHeader();
        return this.http.post(this.domainUrl+'api/ToDo/AddComment', obj, { headers : headers })
        .pipe(map(
            (response) => {
                return response;
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));

      }

      getCommentedToDos(){
        const headers = this.getAuthorizationHeader();
        return this.http.get(this.domainUrl+'api/ToDo/GetCommentedToDos', { headers : headers })
        .pipe(map(
            (response) => {
                return response.json();
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));
    }

    getReceipts(fromDate:string,toDate:string){
        const headers = this.getAuthorizationHeader();
        return this.http.get(this.domainUrl+'api/Receipt/UsersReceipt?fromDate='+fromDate+'&toDate='+toDate, { headers : headers })
        .pipe(map(
            (response) => {
                return response.json();
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));
    }

    deleteReceipt(receiptid:number){
        const headers = this.getAuthorizationHeader();
        return this.http.delete(this.domainUrl+'api/Receipt/DeleteReceipt?receiptid='+receiptid, { headers : headers })
        .pipe(map(
            (response) => {
                return response;
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));

    }

    createReceipt(obj: Receipt){
        const headers = this.getAuthorizationHeader();
        return this.http.post(this.domainUrl+'api/Receipt/CreateReceipt', obj, { headers : headers })
        .pipe(map(
            (response) => {
                return response;
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));

    }

    updateReceipt(obj: Receipt){
        const headers = this.getAuthorizationHeader();
        return this.http.put(this.domainUrl+'api/Receipt/UpdateReceipt', obj, { headers : headers })
        .pipe(map(
            (response) => {
                return response;
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));
    }

    getreceiptById(receiptid:number){
        const headers = this.getAuthorizationHeader();
        return this.http.get(this.domainUrl+'api/Receipt/ReceiptByID?ID='+receiptid, { headers : headers })
        .pipe(map(
            (response) => {
                return response.json();
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));
    }

    addCommentWorkDetails(obj: WorkDetails){
        const headers = this.getAuthorizationHeader();
        return this.http.post(this.domainUrl+'api/Work/AddComment', obj, { headers : headers })
        .pipe(map(
            (response) => {
                return response;
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));

      }
      addCommentpendingDetails(obj:PendingTasksReport){
        const headers = this.getAuthorizationHeader();
        return this.http.post(this.domainUrl+'api/Work/AddComment', obj, { headers : headers })
        .pipe(map(
            (response) => {
                return response;
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));

      }

      getBalance(clientId:number){
        const headers = this.getAuthorizationHeader();
        return this.http.get(this.domainUrl+'api/Client/ClientBalance?clientId='+clientId, { headers : headers })
        .pipe(map(
            (response) => {
                return response.json();
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));
    }

    getPeriodWiseTasks(fy:string,customerId:number,assistantId:number,period:string){
        const headers = this.getAuthorizationHeader();
        return this.http.get(this.domainUrl+'api/Work/GetPendingTasksPeriodWise?fy='+fy
        +"&customerId="+customerId+"&assistantId="+assistantId+"&period="+period, { headers : headers })
        .pipe(map(
            (response) => {
                return response.json();
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));
    }

    discontinueService(obj: Work){
        const headers = this.getAuthorizationHeader();
        return this.http.post(this.domainUrl+'api/Work/DiscontinueService', obj, { headers : headers })
        .pipe(map(
            (response) => {
                return response;
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));

      }

      getCalenderData(){
        const headers = this.getAuthorizationHeader();
        return this.http.get(this.domainUrl+'api/Dashboard/GetCalenderData', { headers : headers })
        .pipe(map(
            (response) => {
                return response.json();
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));
    }

    createInOut(obj: InwardOutward){
        const headers = this.getAuthorizationHeader();
        return this.http.post(this.domainUrl+'api/InOut/CreateInOut', obj, { headers : headers })
        .pipe(map(
            (response) => {
                return response;
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));

      }

      updatInOut(obj: InwardOutward){
        if(this.isAuthenticated()){
            return this.http.put(this.domainUrl+'api/InOut/UpdateInOut',obj,
            {headers:this.getAuthorizationHeader()})
            .map(
                (response: Response) => {
                    return response;
                }
            )
            .catch(
                (error: Response) => {
                    if(error.status === 401){
                        this.logout();
                        this.router.navigate(['/signin']);
                    }else
                        return Observable.throw(error)
                }
            );
        }
    }

    getInOut(fromDate:string,toDate:string){
        const headers = this.getAuthorizationHeader();
        return this.http.get(this.domainUrl+'api/InOut/UserInOut?fromDate='+fromDate+'&toDate='+toDate
            , { headers : headers })
        .pipe(map(
            (response) => {
                return response.json();
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));
      }

      getInOutById(id:number){
        const headers = this.getAuthorizationHeader();
        return this.http.get(this.domainUrl+'api/InOut/InOutByID?ID='+id, { headers : headers })
        .pipe(map(
            (response) => {
                return response.json();
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));
    }

    deleteInOut(inoutId:number){
        const headers = this.getAuthorizationHeader();
        return this.http.delete(this.domainUrl+'api/InOut/DeleteInOut?ID='+inoutId, { headers : headers })
        .pipe(map(
            (response) => {
                return response;
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));

    }

    getLastIn(inDate:string,clientName:string){
        const headers = this.getAuthorizationHeader();
        return this.http.get(this.domainUrl+'api/InOut/GetTop5In?outDate='+inDate+'&entityName='+clientName
            , { headers : headers })
        .pipe(map(
            (response) => {
                return response.json();
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));
      }

    createProjectReportTask(obj: Work){
        const headers = this.getAuthorizationHeader();
        return this.http.post(this.domainUrl+'api/Work/CreateProjectReportService', obj, { headers : headers })
        .pipe(map(
            (response) => {
                return response;
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));

      }


    createCompanyAuditTask(obj: Work){
        const headers = this.getAuthorizationHeader();
        return this.http.post(this.domainUrl+'api/Work/CreateCompanyAuditService', obj, { headers : headers })
        .pipe(map(
            (response) => {
                return response;
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));

      }

      checkExpiry(){
        const headers = this.getAuthorizationHeader();
        return this.http.get(this.domainUrl+'api/ApiUser/CheckExpiry', { headers : headers })
        .pipe(map(
            (response) => {
                return response.json();
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));
      }

    /** ReportsController Requests **/
    /** Client Ledger **/
    getAccountLedger(clientId:number,fromDate:string,toDate:string){
        const headers = this.getAuthorizationHeader();
        return this.http.get(this.domainUrl+'api/Reports/AccountLedger?ClientID='+clientId+'&fromDate='+fromDate+'&toDate='+toDate
            , { headers : headers })
         .pipe(map(
            (response) => {
                return response.json();
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));
    }

    check15Disc(){
        const headers = this.getAuthorizationHeader();
        return this.http.get(this.domainUrl+'api/ApiUser/check15Disc', { headers : headers })
        .pipe(map(
            (response) => {
                return response.json();
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));
      }

    /** ToDo Log **/
    getToDoLog(Id:number,fromDate:string,toDate:string){
        const headers = this.getAuthorizationHeader();
        return this.http.get(this.domainUrl+'api/Reports/ToDoLog?id='+Id+'&fromDate='+fromDate+'&toDate='+toDate
            , { headers : headers })
         .pipe(map(
            (response) => {
                return response.json();
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));
      }

      getToDoLogAssignedTo(Id:number,fromDate:string,toDate:string){
        const headers = this.getAuthorizationHeader();
        return this.http.get(this.domainUrl+'api/Reports/ToDoLogAssignedTo?id='+Id+'&fromDate='+fromDate+'&toDate='+toDate
            , { headers : headers })
         .pipe(map(
            (response) => {
                return response.json();
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));
      }

      postOrder(obj:AdminDetails){
        return this.http.post(this.domainUrl+'api/AdminDetails/AddPaymentOrder',obj,
        {headers:this.getAuthorizationHeader()})
        .map(
            (response: Response) => {
                return response;
            }
        )
        .catch(
            (error: Response) => {
                if(error.status === 401){
                    this.logout();
                    this.router.navigate(['/signin']);
                }else
                    return Observable.throw(error)
            }
        );
    }

    getAdminDetailsById(orderId:number){
        return this.http.get(this.domainUrl+'api/AdminDetails/GetPaymentOrder?ID='+orderId,
        {headers:this.getAuthorizationHeader()})
        .map(
            (response: Response) => {
                return response.json();
            }
        )
        .catch(
            (error: Response) => {
                if(error.status === 401){
                    this.logout();
                    this.router.navigate(['/signin']);
                }else
                    return Observable.throw(error)
            }
        );
    }

    updatePayment(obj:AdminDetails){
        return this.http.post(this.domainUrl+'api/AdminDetails/UpdatePayment',obj,
        {headers:this.getAuthorizationHeader()})
        .map(
            (response: Response) => {
                return response;
            }
        )
        .catch(
            (error: Response) => {
                if(error.status === 401){
                    this.logout();
                    this.router.navigate(['/signin']);
                }else
                    return Observable.throw(error)
            }
        );
    }

    getAdminUser(){
        return this.http.get(this.domainUrl+'api/ApiUser/GetAdminUser',
        {headers:this.getAuthorizationHeader()})
        .map(
            (response: Response) => {
                return response.json();
            }
        )
        .catch(
            (error: Response) => {
                if(error.status === 401){
                    this.logout();
                    this.router.navigate(['/signin']);
                }else
                    return Observable.throw(error)
            }
        );
    }

    getPlanDetails(userID:number){
        return this.http.get(this.domainUrl+'api/ApiUser/GetPlanDetails?ID='+userID,
        {headers:this.getAuthorizationHeader()})
        .map(
            (response: Response) => {
                return response.json();
            }
        )
        .catch(
            (error: Response) => {
                if(error.status === 401){
                    this.logout();
                    this.router.navigate(['/signin']);
                }else
                    return Observable.throw(error)
            }
        );
    }

    getAlertClientCount(fyYear:string,period:string,taskId:number,taskStatus:string){
        return this.http.get(this.domainUrl+'api/Work/GetAlertTaskCount?fyYear='+fyYear
        +'&period='+period+'&taskId='+taskId+'&taskStatus='+taskStatus,
        {headers:this.getAuthorizationHeader()})
        .map(
            (response: Response) => {
                return response.json();
            }
        )
        .catch(
            (error: Response) => {
                if(error.status === 401){
                    this.logout();
                    this.router.navigate(['/signin']);
                }else
                    return Observable.throw(error)
            }
        );
    }

    sendBulkAlert(sms1:string, fyYear:string,period:string,taskId:number,taskStatus:string,sendSmsToWorkDetailsIds:number[]){
        const headers = this.getAuthorizationHeader();
        return this.http.post(this.domainUrl+'api/Work/SendCommSMS?sms1='+sms1+'&fyYear='+fyYear
        +'&period='+period+'&taskId='+taskId+'&taskStatus='+taskStatus+'&sendSmsToWorkDetailsIds='+sendSmsToWorkDetailsIds.join(),null,  { headers : headers })
        .pipe(map(
            (response) => {
                return response;
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));

      }

       sendBulkWhatsApp(sms1:string, fyYear:string,period:string,taskId:number,taskStatus:string,sendSmsToWorkDetailsIds:number[]){
        const headers = this.getAuthorizationHeader();
        return this.http.post(this.domainUrl+'api/Work/SendCommWhatsApp?sms1='+sms1+'&fyYear='+fyYear
        +'&period='+period+'&taskId='+taskId+'&taskStatus='+taskStatus+'&sendSmsToWorkDetailsIds='+sendSmsToWorkDetailsIds.join(),null,  { headers : headers })
        .pipe(map(
            (response) => {
                return response;
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));

      }





    /** Unassigned Tasks **/
    getUnassignedTasks(fy_year:string){
        const headers = this.getAuthorizationHeader();
        return this.http.get(this.domainUrl+'api/Reports/UnassignedTasks?FinancialYear='+fy_year
            , { headers : headers })
         .pipe(map(
            (response) => {
                return response.json();
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));
    }

    /** Receivables **/
    getReceivables(){
      const headers = this.getAuthorizationHeader();
      return this.http.get(this.domainUrl+'api/Reports/Receivables'
            , { headers : headers })
         .pipe(map(
            (response) => {
                return response.json();
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));
    }
    postClientExcel(formData:FormData){
      return this.http.post(this.domainUrl+'api/Client/Post',formData,
      {headers:this.getAuthorizationHeader()})
      .pipe(map(
          (response) => {
              return response;
          }
      ),catchError(
          (error: Response) => {
              return throwError(error);
          }
      ));
  }

  getSmsBalance(){
        return this.http.get(this.domainUrl+'api/ApiUser/GetSmsBalance',
        {headers:this.getAuthorizationHeader()})
        .map(
            (response: Response) => {
                return response.json();
            }
        )
        .catch(
            (error: Response) => {
                return throwError(error);
            }
        );
    }

    getAlertClientCountData(fyYear:string,period:string,taskId:number,taskStatus:string){
        return this.http.get(this.domainUrl+'api/Work/GetAlertTaskCountData?fyYear='+fyYear
        +'&period='+period+'&taskId='+taskId+'&taskStatus='+taskStatus,
        {headers:this.getAuthorizationHeader()})
        .map(
            (response: Response) => {
                return response.json();
            }
        )
        .catch(
            (error: Response) => {
                return throwError(error);
            }
        );
    }

    getPendingTasksReport(datestr:string,assistantId:number,serviceGroup:string){
      const headers = this.getAuthorizationHeader();
      return this.http.get(this.domainUrl+'api/Reports/PendingTasks?date='+datestr+'&assistantId='
      +assistantId+'&serviceGroup='+serviceGroup, { headers : headers })
      .pipe(map(
          (response) => {
              return response.json();
          }
      ),catchError(
          (error: Response) => {
              return throwError(error);
          }
      ));
    }

    getClientListReport(fyYear:string,serviceGroup:string){
      const headers = this.getAuthorizationHeader();
      return this.http.get(this.domainUrl+'api/Reports/ClientList?fyYear='+fyYear+'&serviceGroup='+serviceGroup, { headers : headers })
      .pipe(map(
          (response) => {
              return response.json();
          }
      ),catchError(
          (error: Response) => {
              return throwError(error);
          }
      ));
    }

    getCAInfo(){
      const headers = this.getAuthorizationHeader();
      return this.http.get(this.domainUrl+'api/ApiUser/GetCAInfo'
            , { headers : headers })
         .pipe(map(
            (response) => {
                return response.json();
            }
        ),catchError(
            (error: Response) => {
                return throwError(error);
            }
        ));
    }

    emailSaleBill(saleBillId:number){
      const headers = this.getAuthorizationHeader();
      return this.http.post(this.domainUrl+'api/SaleBill/EmailSaleBill',{saleBillId:saleBillId}, { headers : headers })
      .pipe(map(
          (response) => {
              return response;
          }
      ),catchError(
          (error: Response) => {
              return throwError(error);
          }
      ));

    }

    CarryForwardClients(CarryForwardFormData:FormData){
      return this.http.post(this.domainUrl+'api/Client/CarryForwardClients',CarryForwardFormData,
      {headers:this.getAuthorizationHeader()})
      .pipe(map(
          (response) => {
              return response;
          }
      ),catchError(
          (error: Response) => {
              return throwError(error);
          }
      ));
    }

    assignBulkTasks(assistantId:number,arrWorkIds:number[]){
      const headers = this.getAuthorizationHeader();
      return this.http.post(this.domainUrl+'api/Work/AssignBulkTasks',{ assistantId:assistantId, strWorkIds:arrWorkIds.join() },  { headers : headers })
      .pipe(map(
          (response) => {
              return response;
          }
      ),catchError(
          (error: Response) => {
              return throwError(error);
          }
      ));

    }

    changeBulkTasksStatus(workStatus:string,arrWorkDetailsIds:number[]){
      const headers = this.getAuthorizationHeader();
      return this.http.post(this.domainUrl+'api/Work/ChangeBulkTasksStatus',{workStatus:workStatus,strWorkDetailsIds:arrWorkDetailsIds.join()},  { headers : headers })
      .pipe(map(
          (response) => {
              return response;
          }
      ),catchError(
          (error: Response) => {
              return throwError(error);
          }
      ));

    }

    getTAPORDMemberID(){
      const headers = this.getAuthorizationHeader();
      return this.http.get(this.domainUrl+'api/ApiUser/GetTAPORDMemberID', { headers : headers })
      .pipe(map(
          (response) => {
              return response.json();
          }
      ),catchError(
          (error: Response) => {
              return throwError(error);
          }
      ));
    }

    isRenew(){
      const headers = this.getAuthorizationHeader();
      return this.http.get(this.domainUrl+'api/AdminDetails/isRenew', { headers : headers })
      .pipe(map(
          (response) => {
              return response.json();
          }
      ),catchError(
          (error: Response) => {
              return throwError(error);
          }
      ));
    }

    renewPlanVisibility(){
      const headers = this.getAuthorizationHeader();
      return this.http.get(this.domainUrl+'api/ApiUser/GetRenewPlanVisibility', { headers : headers })
      .pipe(map(
          (response) => {
              return response.json();
          }
      ),catchError(
          (error: Response) => {
              return throwError(error);
          }
      ));
    }
  }
