import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ServerAPIService } from '../serverapi.service';
import { SpinnerService } from '../spinner.service';
import { Renewplanvisibility } from './renewplanvisibility.model';

@Component({
  selector: 'app-renew-form',
  templateUrl: './renew-form.component.html',
  styleUrls: ['./renew-form.component.css']
})
export class RenewFormComponent implements OnInit,AfterViewInit {
  plans:Renewplanvisibility;
  silver:boolean = false;
  gold:boolean = false;
  diamond:boolean = false;

  constructor(private serverAPIService:ServerAPIService,
    private spinnerService:SpinnerService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.spinnerService.show();
      this.serverAPIService.renewPlanVisibility()
      .subscribe(
        (response: Renewplanvisibility) => {
            if(response){
              this.silver = response.Silver;
              this.gold = response.Gold;
              this.diamond = response.Diamond;
            }
            this.spinnerService.hide();
          }
      );
    });
  }
}
