import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServerAPIService } from 'src/app/serverapi.service';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from 'src/app/spinner.service';
import { Subject } from 'rxjs';
import { Service } from '../service.model';
declare let $: any;

@Component({
  selector: 'app-service-list',
  templateUrl: './service-list.component.html',
  styleUrls: ['./service-list.component.css']
})
export class ServiceListComponent implements OnInit {

  services:Service[];
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  constructor(private serverAPIService:ServerAPIService,
    private router:Router, private toaster:ToastrService, private spinnerService:SpinnerService) {
      this.dtOptions = {
        // pagingType: 'full_numbers',
        // pageLength: 10,
        processing: true,
        paging:false,
      }
  }

  ngOnInit() {
    this.getData();
  }

  getData(){
    this.spinnerService.show();
    this.serverAPIService.getServices()
    .subscribe(
      (response : Service[]) => {
        this.spinnerService.hide();
        this.services=response;
        this.dtTrigger.next();
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error("Something went wrong!");
      }
    )
  }

}
