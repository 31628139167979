import { Component, OnInit, AfterViewInit, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { Client } from 'src/app/masters/client.model';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ServerAPIService } from 'src/app/serverapi.service';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from 'src/app/spinner.service';
import { SaleBill } from '../salebill.model';
import { Firm } from 'src/app/masters/firm.model';
import { SaleBillDetails } from '../salebillDetails.model';
import { Work } from 'src/app/user-service/work.model';
declare let $: any;

@Component({
  selector: "app-print-bill",
  templateUrl: "./print-bill.component.html",
  styleUrls: ["./print-bill.component.css"],
})
export class PrintBillComponent
  implements OnInit, AfterViewInit, AfterContentChecked {
  clientId: number;
  saleBillId: number;
  clients: Client;
  firms: Firm;
  cl: object[];
  works: Work[];
  wl: object[];
  actFirm: Firm[];
  saleBill: SaleBill;
  serviceName: string;
  gstPercent: number = 18;
  gstAmount: number = 0;
  roundoff: number = 0;
  totalAmount: number = 0;
  subtotal: number = 0;
  salebDet: SaleBillDetails[];

  tempDesc: string = "";
  tempAmount: number = 0;

  constructor(
    public serverAPIService: ServerAPIService,
    private route: ActivatedRoute,
    private router: Router,
    private toaster: ToastrService,
    private spinnerService: SpinnerService,
    private ref: ChangeDetectorRef
  ) {}

  ngAfterViewInit() {}

  ngAfterContentChecked() {
    this.ref.detectChanges();
  }

  ngOnInit() {
    this.spinnerService.show();
    this.route.params.subscribe((params: Params) => {
      if (params["id"] != null) {
        this.saleBillId = parseInt(params["id"]);
        this.getFirms();
      }
    });
  }

  getFirms() {
    // this.spinnerService.show();
    this.serverAPIService.getFirms().subscribe(
      (response: Firm[]) => {
        this.actFirm = response;
        this.getBillById(this.saleBillId);
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error("Something went wrong!");
      }
    );
  }

  getClientById() {
    //this.spinnerService.show();
    this.serverAPIService.getClientById(this.saleBill.ClientID).subscribe(
      (data: Client) => {
        this.clients = data;
        this.spinnerService.hide();
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error("Something went wrong!");
      }
    );
  }

  getBillById(id: number) {
    this.spinnerService.show();
    this.serverAPIService.getbillById(id).subscribe(
      (data: SaleBill) => {
        this.saleBill = data;
        this.clientId = this.saleBill.ClientID;
        this.getClientById();
        this.firms = this.actFirm.filter(
          (o) => o.FirmID == this.saleBill.FirmID
        )[0];
        // for (let sd of this.saleBill.saleBillDetails) {
        //   this.subtotal+=parseFloat(sd.Amount.toString());
        // }
        this.subtotal =
          this.saleBill.TotalAmount +
          this.saleBill.DiscountAmount -
          this.saleBill.GstAmount -
          this.saleBill.RoundOff;
        this.gstPercent = this.saleBill.GstPercent;
        this.gstAmount = this.saleBill.GstAmount;
        this.roundoff = this.saleBill.RoundOff;
        this.totalAmount = this.saleBill.TotalAmount;
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error("Something went wrong!");
      }
    );
  }

  print(): void {
    let printContents, popupWin;
    printContents = document.getElementById("print-section").innerHTML;
    popupWin = window.open("", "_blank", "top=0,left=0,height=100%,width=auto");
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
        <link rel="stylesheet" href="/assets/vendors/mdi/css/materialdesignicons.min.css">
        <link rel="stylesheet" href="/assets/vendors/css/vendor.bundle.base.css">
        <link rel="stylesheet" href="/assets/css/style.css">
          <title>Print tab</title>
          <style>
          .right{text-align: right;}
          .h3, h3 {
            font-size: 1.75rem;
          }
          .h2, h2 {
            font-size: 2rem;
          }
          p,body {
            font-size: 1.3rem;
            line-height: 1.5;
          }
          .table th, .table td{
            font-size:1.3rem;
            border:solid 1px black !important;
          }
          .h4, h4 {
            font-size: 1.5rem;
          }
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`);
    popupWin.document.close();
  }

  email(): void {
    if(this.clients.Email == '' || this.clients.Email == null){
      this.toaster.warning('Unable to send bill. Email address not found for selected client.');
      return;
    }

    this.spinnerService.show();
    this.serverAPIService.emailSaleBill(this.saleBillId).subscribe(
      (data: any) => {
        this.spinnerService.hide();
        this.toaster.success("Bill sent successfully!");
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error("Unable to send bill. Please try again.");
      }
    );
  }
}
