export class ToDo{
    public ToDoID:number;
    public AppUserID:number;
    public AssignedUserID :number;
    public AssignedDate:Date;
    public ToDoDate:Date;
    public Description:string;
    public Completed:boolean;
    public ToDoDatestr:string;
    public AssignedUserName:string;
    public Comments:string;
    public UpdatedDate:Date;
    public Status:string;
    
    constructor(){}
}