import { Component, OnInit, AfterViewInit, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { ServerAPIService } from 'src/app/serverapi.service';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from 'src/app/spinner.service';
import { Subject } from 'rxjs';
import { Client } from '../client.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Service } from 'src/app/user-service/service.model';
declare let $: any;

@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.css']
})
export class ClientListComponent implements OnInit,AfterContentChecked,AfterViewInit {
  clients:Client[];
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  tempClientName:string;
  tempClientId:number;
  cnt:number=0;
  srv:object[];
  serviceId:number=0;

  constructor(public serverAPIService:ServerAPIService,
    private router:Router, private toaster:ToastrService, private spinnerService:SpinnerService
    ,private modalService: NgbModal,private ref: ChangeDetectorRef) {
      this.dtOptions = {
        // pagingType: 'full_numbers',
        // pageLength: 10,
        destroy: true,
        processing: true,
        paging:false,
      }
  }

  ngAfterContentChecked() {
    this.ref.detectChanges();
}


  public onServiceSelected(event) {
    const value = event;
    if(value!=''){
        this.serviceId=value;
      }

      $('#example').DataTable().destroy();
      this.clients=[];
      this.dtTrigger.next();
      setTimeout(() => this.getData(),0);
 }

  getCount(){
    this.cnt=this.cnt+1;
  }

  ngOnInit() {
    // this.getData();
    // this.getServices();
  }

  ngAfterViewInit(): void{
    setTimeout(() => {
      this.getServices();
    });
  }

   getServices(){
    this.serverAPIService.getServices()
     .subscribe(
       (response : Service[]) => {
         this.srv=[];
         this.srv = [{ id: 0, text: 'All'}];
         response.forEach(element => {
          this.srv.push({id:element.ServiceID,text:element.ServiceName});
         });
       },
       (error) => {
         this.spinnerService.hide();
         this.toaster.error("Something went wrong!");
       }
     )
  }

  getData(){
    this.spinnerService.show();
    this.serverAPIService.getClientsList(this.serviceId)
    .subscribe(
      (response : Client[]) => {
        this.spinnerService.hide();
        this.clients=response;
        this.cnt=this.clients.length;
        this.dtTrigger.next();
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error("Something went wrong!");
      }
    )
  }

  deleteClient(content,name:string,clientId:number){
    this.tempClientId=clientId;
    this.tempClientName=name;
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'});
  }

  deleteConfirm(){
    this.spinnerService.show();
    this.serverAPIService.deleteClient(this.tempClientId)
    .subscribe(
      (response ) => {
        this.toaster.success("Client Deleted");
        this.modalService.dismissAll();
        $('#example').DataTable().destroy();
        this.clients=[];
        this.dtTrigger.next();
        this.getData();
        this.spinnerService.hide();

      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error("Something went wrong!");
      }
    )
  }

}
