import { Component,  OnInit, AfterContentInit, AfterContentChecked, AfterViewInit, AfterViewChecked } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import{ToastrService}from 'ngx-toastr';
import { SpinnerService } from 'src/app/spinner.service'
import { Subscription } from 'rxjs';
import '@angular/compiler';
import { ServerAPIService } from 'src/app/serverapi.service';
import { ToDo } from 'src/app/todo.model';
import { UserProfile } from 'src/app/user-profile/user-profile.model';
declare let $: any;

@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.css']
})
export class AppLayoutComponent implements OnInit,AfterViewChecked {

  title = 'Aareasy';
  spinnerTemplate: string = `<div class="mdl-spinner mdl-js-spinner is-active"></div>`;
  isSpinnerVisible = false;
  subSpinner : Subscription;
  currentFyYear:string;
  usersTodo:ToDo[];
  uncomToDo:ToDo[];
  userlogin:UserProfile;
  adminUser:UserProfile;

  constructor(private spinnerService : SpinnerService, public serverAPIService:ServerAPIService,
    private toastr: ToastrService, private route:ActivatedRoute,  private router:Router, private toaster:ToastrService)
  {  }

  ngAfterViewChecked() {
  //  console.log("test");
}


  ngOnInit() : void {
    document.querySelector('.sidebar').addEventListener('click', function () {
      document.querySelector('.sidebar').classList.remove('active');
      this.classList.remove('active');
    }, false);

    this.subSpinner = this.spinnerService.getSpinnerState
    .subscribe((display : boolean) => {
      if (display){
        this.isSpinnerVisible = true;
      }else{
        this.isSpinnerVisible = false;
      }
    });


    // document.querySelector('[data-toggle="offcanvas"]').addEventListener("click", function() {
    //   $('.sidebar-offcanvas').toggleClass('active')
    // },false);

    this.serverAPIService.getCurrentFy()
    .subscribe(
      (response : any) => {
        this.currentFyYear=response;
      },
      (error) => {
        //this.spinnerService.hide();
        //this.toaster.error("Something went wrong!");
      }
    )

    this.serverAPIService.getAccountById(parseInt( localStorage.getItem("userId").toString()))
    .subscribe(
      (response : UserProfile) => {
        this.userlogin=response;
      },
      (error) => {
        //this.spinnerService.hide();
        //this.toaster.error("Something went wrong!");
      }
    )

    this.serverAPIService.getAdminUser()
    .subscribe(
      (response : UserProfile) => {
        this.adminUser=response;
      },
      (error) => {
        //this.spinnerService.hide();
        //this.toaster.error("Something went wrong!");
      }
    )

    this.getUserToDos();
    this.getCommentedToDos();

    this.serverAPIService.checkExpiry()
    .subscribe(
      (response : boolean) => {
        if(response){
          this.serverAPIService.isRenew().subscribe(
            (response:boolean)=>{
              if(response){
                this.router.navigate(['/renew']);
              }
              else{
                this.router.navigate(['/purchase']);
              }
            },
            (error) => {
              //this.spinnerService.hide();
              this.toaster.error("Something went wrong!");
            }
          )
        }
      },
      (error) => {
        //this.spinnerService.hide();
        this.toaster.error("Something went wrong!");
      }
    )
  }

  toggleMenu(){
   // $('#sidebar').toggleClass('active')
   if(document.querySelector('.sidebar-offcanvas').classList.contains('active'))
    document.querySelector('.sidebar-offcanvas').classList.remove('active');
  else
    document.querySelector('.sidebar-offcanvas').classList.add('active');
     // this.classList.remove('active');
  }

  toggleMin(){
    if (($('body').hasClass('sidebar-toggle-display')) || ($('body').hasClass('sidebar-absolute'))) {
      $('body').toggleClass('sidebar-hidden');
    } else {
      $('body').toggleClass('sidebar-icon-only');
    }
  }

  getUserToDos(){
    this.serverAPIService.getToDos()
    .subscribe(
      (response : ToDo[]) => {
        this.usersTodo=response;
      },
      (error) => {
        //this.spinnerService.hide();
        //this.toaster.error("Something went wrong!");
      }
    )
  }

  getCommentedToDos(){
    //this.spinnerService.show();
    this.serverAPIService.getCommentedToDos()
    .subscribe(
      (response : ToDo[]) => {
        this.uncomToDo=response;
      },
      (error) => {
        //this.spinnerService.hide();
        //this.toaster.error("Something went wrong!");
      }
    )
  }

    onLogout(){
      this.serverAPIService.logout();
      this.router.navigate(['/signin']);
      this.toastr.info("Logged Out!");
    }

    ngOnDestroy(): void {
      if (this.subSpinner != null){
        this.subSpinner.unsubscribe();
      }
    }

}
