import { SaleBillDetails } from './salebillDetails.model';

export class SaleBill{
    public  SaleBillID:number;
    public  WorkID:number;
    public  BillDate:Date;
    public BillType:string;
    public Description :string;
    public BillNo :string;
    public GstPercent :number;
    public GstAmount :number;
    public RoundOff:number;
    public TotalAmount: number;
    public ClientID :number;
    public FirmID :number;
    public ServiceID :number;
    public AppUserID :number;
    public CreatedBy :string;
    public  CreationDate:Date;
    public EntityName:string;
    public ServiceName :string;
    public FimName :string;
    public BillStatus :string;
    public BillDateStr :string;
    public saleBillDetails:SaleBillDetails[];
    public DiscountAmount:number;
    public EntityEmail:string;
    public ClientName:string;

    constructor(){}
}
