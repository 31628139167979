import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { NgForm, FormBuilder, FormGroup, Validators, FormControl, MaxLengthValidator } from '@angular/forms';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { ServerAPIService } from 'src/app/serverapi.service';
import { SpinnerService } from 'src/app/spinner.service';
import { ToastrService } from 'ngx-toastr';
import { Firm } from '../firm.model';

@Component({
  selector: 'app-firm-details',
  templateUrl: './firm-details.component.html',
  styleUrls: ['./firm-details.component.css']
})
export class FirmDetailsComponent implements OnInit ,AfterViewInit{
  @ViewChild('f',{static : true}) formRef : NgForm;
  isEditMode = false;
  firmID : number = 0;
  registerForm: FormGroup;
  submitted = false;
  profileForm = new FormGroup({
    FirmName: new FormControl(''),
    ContactNo: new FormControl(''),
    Email:new FormControl(''),
    Address:new FormControl(''),
    GSTIN:new FormControl(''),
    BankDetails:new FormControl(''),
    ddlGSTRegType:new FormControl(''),
    AccountNo:new FormControl(''),
    IFSCCode:new FormControl(''),
    AccountDetails:new FormControl(''),
    ContactDetails:new FormControl('')
  });
  regTypeArray:string[]=['Unregistred','Regular','Composition'];
  
  constructor(private serverAPIService:ServerAPIService,private route : ActivatedRoute,
    private router:Router, private toaster:ToastrService, private spinnerService:SpinnerService
    ,private formBuilder: FormBuilder) { }

    ngOnInit() {
    }

    // convenience getter for easy access to form fields
    get f() { return this.profileForm.controls; }
  
    
    ngAfterViewInit(): void{
      setTimeout(() => {
        this.spinnerService.show();
        this.route.params
        .subscribe(
          (params: Params) => {
            if(params["id"] != null){
              this.isEditMode = true;
              this.getFirmById(params["id"]);
            } else{
              this.spinnerService.hide();
            }
          }
        );
      });
    }

    getFirmById(id : number){
    
      this.serverAPIService.getFirmById(id)
      .subscribe(
        (data:Firm) => {
          this.spinnerService.hide();
          this.firmID = data.FirmID
          this.f.FirmName.setValue(data.FirmName);
          this.f. ContactNo.setValue(data.ContactNo);
          this.f.Email.setValue(data.Email);
          this.f.Address.setValue(data.Address);
          this.f.GSTIN.setValue(data.GSTIN);
          this.f.BankDetails.setValue(data.BankDetails);
          this.f.ddlGSTRegType.setValue(data.GSTRegType);
          this.f.AccountNo.setValue(data.AccountNo);
          this.f.IFSCCode.setValue(data.IFSCCode);
          this.f.AccountDetails.setValue(data.AccountDetails);
          this.f.ContactDetails.setValue(data.ContactDetails);
        },
        (error) => {
          this.spinnerService.hide();
          this.toaster.error('Something went wrong!');
        }
      );
  
      
    }
  

    onSubmit(form: NgForm){
      if(this.f.ddlGSTRegType.value!='Unregistred' && this.f.GSTIN.value==''){
        this.toaster.warning('Enter GSTIN');
        return;
      }

      if(this.isEditMode)
        this.update(form);
      else 
        this.create(form);
    }

    create(form: NgForm){

      this.spinnerService.show();
      let frm = new Firm();
  
      frm.Address = this.f.Address.value;
      frm.BankDetails=this.f.BankDetails.value;
      frm.ContactNo=this.f.ContactNo.value;
      frm.Email=this.f.Email.value;
      frm.FirmName=this.f.FirmName.value;
      frm.GSTIN=this.f.GSTIN.value;
      frm.GSTRegType=this.f.ddlGSTRegType.value;
      frm.AccountNo=this.f.AccountNo.value;
      frm.IFSCCode=this.f.IFSCCode.value;
      frm.AccountDetails=this.f.AccountDetails.value;
      frm.ContactDetails=this.f.ContactDetails.value;

      this.serverAPIService.createFirm(frm)
      .subscribe(
        (data: any) => {
          this.spinnerService.hide();
          this.toaster.success("Firm created successfully!");
  
          this.router.navigate(['/master/firm']);
        },
        (error) => {
          this.spinnerService.hide();
          this.toaster.error(error);
        });

     
    }
  
    update(form: NgForm){
  
      let frm = new Firm();
      
      frm.FirmID=this.firmID;
      frm.Address = this.f.Address.value;
      frm.BankDetails=this.f.BankDetails.value;
      frm.ContactNo=this.f.ContactNo.value;
      frm.Email=this.f.Email.value;
      frm.FirmName=this.f.FirmName.value;
      frm.GSTIN=this.f.GSTIN.value;
      frm.GSTRegType=this.f.ddlGSTRegType.value;
      frm.AccountNo=this.f.AccountNo.value;
      frm.IFSCCode=this.f.IFSCCode.value;
      frm.AccountDetails=this.f.AccountDetails.value;
      frm.ContactDetails=this.f.ContactDetails.value;
  
      this.spinnerService.show();
  
      this.serverAPIService.updateFirm(frm)
      .subscribe(
        (data: any) => {
          this.spinnerService.hide();
          this.toaster.success("Firm updated successfully!");
          this.router.navigate(['/master/firm']);
        },
        (error) => {
          this.spinnerService.hide();
          this.toaster.error(error);
        });
    }

}
