import { Component, OnInit, AfterViewInit, ChangeDetectorRef, AfterContentChecked, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ServerAPIService } from 'src/app/serverapi.service';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from 'src/app/spinner.service';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { Client } from 'src/app/masters/client.model';
import { NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CustomDateAdapter, CustomDateParserFormatter } from 'src/app/custom-ngbDateParserFormatter';
import { Service } from 'src/app/user-service/service.model';
declare let $: any;

@Component({
  selector: 'app-gsr-reg-details',
  templateUrl: './gsr-reg-details.component.html',
  styleUrls: ['./gsr-reg-details.component.css'],
  providers: [{provide: NgbDateAdapter, useClass: CustomDateAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}]
})
export class GsrRegDetailsComponent implements OnInit ,AfterViewInit,AfterContentChecked{
  clientId:number;
  client:Client;
  regTypeArray:string[]=['Regular','Composition','Unregistred'];
  serviceName:string;
  feeLabel:string;
  profileForm = new FormGroup({
    txtGSTIN: new FormControl(''),
    ddlGSTRegType:new FormControl(''),
    txtGSTUserName:new FormControl(''),
    txtGSTRegDate:new FormControl(''),
    txtPan:new FormControl(''),
    txtTan:new FormControl(''),
    txtPtec:new FormControl(''),
    txtPtrc:new FormControl('')
  });
  taskID:number;

  constructor(private serverAPIService:ServerAPIService,private route : ActivatedRoute,
    private router:Router, private toaster:ToastrService, private spinnerService:SpinnerService
    ,private ref: ChangeDetectorRef) {
  }

  ngAfterViewInit(){
  }

   // convenience getter for easy access to form fields
   get f() { return this.profileForm.controls; }

   ngAfterContentChecked() {
    this.ref.detectChanges();
  }

  ngOnInit() {
    setTimeout(() => {
      this.spinnerService.show();
      this.route.params
      .subscribe(
        (params: Params) => {
          if(params["id"] != null){
            this.clientId=parseInt( params["id"]);
            this.taskID=parseInt( params["taskid"]);
            this.getClientById(this.clientId);
            if(this.taskID==1)
              this.serviceName="GST Registration";
            else if(this.taskID==37)
              this.serviceName="PAN Application";
            else if(this.taskID==38)
              this.serviceName="TAN Application";
            else if(this.taskID==39)
              this.serviceName="PTEC Registration";
            else if(this.taskID==40)
              this.serviceName="PTRC Registration";
          } else{
            this.spinnerService.hide();
          }
        }
      );
   });
  }

  getClientById(id : number){
    this.spinnerService.show();
    this.serverAPIService.getClientById(id)
    .subscribe(
      (data:Client) => {
        this.client=data;
        if(this.taskID==1){
          this.f.txtGSTIN.setValue(data.GSTIN);
          this.f.txtGSTRegDate.setValue(data.GSTRegDateString);
          this.f.txtGSTUserName.setValue(data.GSTUserName);
          this.f.ddlGSTRegType.setValue(data.GSTRegType);
        }
        else if(this.taskID==37){
          this.f.txtPan.setValue(data.PAN);
        }
        else if(this.taskID==38){
          this.f.txtTan.setValue(data.TAN);
        }
        else if(this.taskID==39){
          this.f.txtPtec.setValue(data.PTEC);
        }
        else if(this.taskID==40){
          this.f.txtPtrc.setValue(data.PTRC);
        }
        this.spinnerService.hide();
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error('Something went wrong!');
      }
    );
  }

  onSubmit(form: NgForm){

    // if(this.isEditMode)
    //   this.update(form);
    // else 
      this.update(form);
  }

  update(form: NgForm){

    this.spinnerService.show();
    let t = new Client();

    if(this.taskID==1){
      t.GSTIN=this.f.txtGSTIN.value;
      t.GSTRegDateString=this.f.txtGSTRegDate.value;
      t.GSTRegType=this.f.ddlGSTRegType.value;
      t.GSTUserName=this.f.txtGSTUserName.value;
    }
    else if(this.taskID==37){
      t.PAN=this.f.txtPan.value;
    }
    else if(this.taskID==38){
      t.TAN=this.f.txtTan.value;
    }
    else if(this.taskID==39){
      t.PTEC=this.f.txtPtec.value;
    }
    else if(this.taskID==40){
      t.PTRC=this.f.txtPtrc.value;
    }
    t.ClientID=this.client.ClientID;

    this.serverAPIService.updateClientgstdetails(t)
    .subscribe(
      (data: any) => {
        this.spinnerService.hide();
        this.toaster.success("Client Details updated successfully!");

        this.router.navigate(['/service/pending-task-list']);
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error(error);
      });

   
  }

}
