import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServerAPIService } from 'src/app/serverapi.service';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from 'src/app/spinner.service';
import { Subject } from 'rxjs';
import { Firm } from '../firm.model';
declare let $: any;

@Component({
  selector: 'app-firm-list',
  templateUrl: './firm-list.component.html',
  styleUrls: ['./firm-list.component.css']
})
export class FirmListComponent implements OnInit,AfterViewInit {
  firms:Firm[];
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  constructor(private serverAPIService:ServerAPIService,
    private router:Router, private toaster:ToastrService, private spinnerService:SpinnerService) {
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,

      }
  }

  ngOnInit() {
       this.getData();
   }
 
   ngAfterViewInit() {
     
   }
 
   getData(){
     this.spinnerService.show();
     this.serverAPIService.getFirms()
     .subscribe(
       (response : Firm[]) => {
         this.spinnerService.hide();
         this.firms=response;
         this.dtTrigger.next();
         // this.dataSource.data = response;
         // this.dataSource.sort = this.sort;
         // this.dataSource.paginator = this.paginator;
       },
       (error) => {
         this.spinnerService.hide();
         this.toaster.error("Something went wrong!");
       }
     )
   }

}
