import { Component, OnInit, AfterViewInit, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CustomDateAdapter, CustomDateParserFormatter } from 'src/app/custom-ngbDateParserFormatter';
import { Client } from 'src/app/masters/client.model';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ServerAPIService } from 'src/app/serverapi.service';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from 'src/app/spinner.service';
import { FormGroup, FormControl, NgForm } from '@angular/forms';
import { SaleBill } from '../salebill.model';
import { Firm } from 'src/app/masters/firm.model';
import { Service } from 'src/app/user-service/service.model';
import { SaleBillDetails } from '../salebillDetails.model';
import { Work } from 'src/app/user-service/work.model';
declare let $: any;

@Component({
  selector: 'app-billdetails',
  templateUrl: './billdetails.component.html',
  styleUrls: ['./billdetails.component.css'],
  providers: [{provide: NgbDateAdapter, useClass: CustomDateAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}]
})
export class BilldetailsComponent implements OnInit,AfterViewInit,AfterContentChecked {
  clientId:number;
  saleBillId:number;
  clients:Client[];
  firms:object[];
  cl:object[];
  works:Work[];
  wl:object[];
  actFirm:Firm[];
  saleBill:SaleBill;
  serviceName:string;
  gstPercent:number=18;
  gstAmount:number=0;
  roundoff:number=0;
  totalAmount:number=0;
  subtotal:number=0;
  tempSubtotal:number=0;
  salebDet:SaleBillDetails[];
  profileForm = new FormGroup({
    ddlFirm: new FormControl(''),
    txtBillDate:new FormControl(''),
    txtClientName:new FormControl(''),
    txtDescription:new FormControl(''),
    txtTempDesc:new FormControl(''),
    txtDiscountAmount:new FormControl(''),
    txtTempAmount:new FormControl(''),
    txtRoundOff:new FormControl(''),
    ddlClient:new FormControl(''),
    ddlWork:new FormControl(''),
    ddlBillingType:new FormControl('')
  });
  billingtp:string[]=this.serverAPIService.BillingTypes;
  isupdate:boolean=false;
  currentDate = new Date();

  tempDesc:string='';
  tempAmount:number=0;
  balance:string='';

  constructor(private serverAPIService:ServerAPIService,private route : ActivatedRoute,
    private router:Router, private toaster:ToastrService, private spinnerService:SpinnerService
    ,private ref: ChangeDetectorRef) {
  }

  ngAfterViewInit(){
  }

  // convenience getter for easy access to form fields
  get f() { return this.profileForm.controls; }


  ngAfterContentChecked() {
    this.ref.detectChanges();
  }

  addDetails(){
    if(this.f.txtTempDesc.value=='' || this.f.txtTempAmount.value==''){
      this.toaster.warning("Enter description and amount");
      return;
    }

    let sb=new SaleBillDetails();
    sb.Amount=parseFloat(this.f.txtTempAmount.value);
    sb.Description=this.f.txtTempDesc.value;
    if(this.salebDet==undefined){
      this.salebDet=[];
    }
    if(this.salebDet.length>0){
      let cnt=this.salebDet.sort(x=>x.SaleBillDetailsID)[this.salebDet.length-1].SaleBillDetailsID;
      sb.SaleBillDetailsID=cnt+1;
    }
    else
      sb.SaleBillDetailsID=0;

    this.salebDet.push(sb);
    this.setTotal();
    this.f.txtTempDesc.setValue('');
    this.f.txtTempAmount.setValue('');

  }

  deleteDetails(sbd:SaleBillDetails){
    let ind=this.salebDet.indexOf(sbd);
    this.salebDet.splice(ind,1);
    this.setTotal();
  }

  public onFirmSelected(event) {
    this.f.ddlFirm.setValue(event);
    this.setTotal();
  }

  public onClientSelected(event) {
    if(event!=''){
      this.f.ddlClient.setValue(event);
      this.serverAPIService.getClientsServiceList(this.f.ddlClient.value)
     .subscribe(
       (response:Work[]) => {
         this.works=response;
         this.wl = [{ id: 0, text: 'Not Applicable'}];
         response.forEach(element => {
           this.wl.push({id:element.WorkID,text:element.ServiceName});
          });
        //this.spinnerService.hide();
        this.serverAPIService.getBalance(this.f.ddlClient.value)
        .subscribe(
          (data:number) => {
            if(data<0)
              this.balance=((-1)*data).toString()+' Dr';
            else
              this.balance=(data).toString()+' Cr';
            this.spinnerService.hide();
          },
          (error) => {
            this.spinnerService.hide();
            this.toaster.error('Something went wrong!');
          }
        );
       },
       (error) => {
         this.spinnerService.hide();
         this.toaster.error('Something went wrong!');
       }
     );
    }
  }

  ngOnInit() {
    $('.js-example-basic-single').select2({width: 'resolve'});
    let day:number=this.currentDate.getDate();
    let month:number=this.currentDate.getMonth();
    let year:number=this.currentDate.getFullYear();
    if(month==12){
      month=0;
      year=year+1;
    }
    this.f.txtBillDate.setValue(((day).toString().length==1 ?'0'+(day).toString() :(day).toString())+'/'+((month+1).toString().length==1 ?'0'+(month+1).toString() :(month+1).toString())
      +'/'+year.toString());

      setTimeout(() => {
        this.spinnerService.show();
        this.route.params
        .subscribe(
          (params: Params) => {
            if(params["id"] != null){
              this.saleBillId=parseInt( params["id"]);
              this.getFirms();
              this.isupdate=true;
            } else{
              this.getClientById();
              this.getFirms();
              this.spinnerService.hide();
            }
          }
        );
      });
  //  }
    // else{


    // }

  }

  getBillById(id : number){
    this.spinnerService.show();
    this.serverAPIService.getbillById(id)
    .subscribe(
      (data:SaleBill) => {
        this.saleBill=data;
        //this.spinnerService.hide();
        this.clientId=this.saleBill.ClientID;
        this.f.ddlFirm.setValue(this.saleBill.FirmID);
        this.f.txtBillDate.setValue(this.saleBill.BillDateStr);
        this.f.txtClientName.setValue(this.saleBill.ClientName);
        this.f.txtDescription.setValue(this.saleBill.Description);
        this.f.ddlBillingType.setValue(this.saleBill.BillType);
        this.f.ddlClient.setValue(this.saleBill.ClientID);
        this.f.txtDiscountAmount.setValue(this.saleBill.DiscountAmount);
        this.salebDet=this.saleBill.saleBillDetails;
        this.setTotal();
        if(this.saleBill.ServiceID!=null)
          this.getServiceById(this.saleBill.ServiceID);
         // this.spinnerService.hide();
        this.getWorks();

        this.serverAPIService.getBalance(this.f.ddlClient.value)
        .subscribe(
          (data:number) => {
            if(data<0)
              this.balance=((-1)*data).toString()+' Dr';
            else
              this.balance=(data).toString()+' Cr';
            this.spinnerService.hide();
          },
          (error) => {
            this.spinnerService.hide();
            this.toaster.error('Something went wrong!');
          }
        );
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error('Something went wrong!');
      }
    );
  }

  getWorks(){
    // this.spinnerService.show();
     this.serverAPIService.getClientsServiceList(this.saleBill.ClientID)
     .subscribe(
       (response:Work[]) => {
         this.works=response;
         this.wl = [{ id: 0, text: 'Not Applicable'}];
         response.forEach(element => {
           this.wl.push({id:element.WorkID,text:element.ServiceName});
          });
         // this.wl.shift();
          if(this.saleBill.WorkID!=null)
            this.f.ddlWork.setValue(this.saleBill.WorkID);
        this.spinnerService.hide();
       },
       (error) => {
         this.spinnerService.hide();
         this.toaster.error('Something went wrong!');
       }
     );
   }

  roundChange(){
    let tempTotal=this.tempSubtotal+this.gstAmount;
    this.roundoff=parseFloat( this.f.txtRoundOff.value.toString());
    this.totalAmount=tempTotal+this.roundoff;
  //  this.totalAmount=Math.round((this.totalAmount + Number.EPSILON) * 100) / 100
  }

  discountChange(){
    if(this.salebDet != undefined && this.salebDet.length>0)
      this.setTotal();
  }

  setTotal(){
    let fr=this.actFirm.filter(o=>o.FirmID==this.f.ddlFirm.value);
    if(fr[0].GSTRegType!='Regular')
      this.gstPercent=0;
    else
      this.gstPercent=18;

    this.subtotal=0;
    for (let sd of this.salebDet) {
      this.subtotal+=parseFloat(sd.Amount.toString());
    }

    this.tempSubtotal = 0;
    this.tempSubtotal = this.tempSubtotal + this.subtotal;
    if(this.f.txtDiscountAmount.value !="" && this.f.txtDiscountAmount.value != null)
      this.tempSubtotal = this.tempSubtotal - parseFloat( this.f.txtDiscountAmount.value.toString());
    else
      this.f.txtDiscountAmount.setValue(0);

    this.gstAmount=( ( (parseFloat(this.tempSubtotal.toString()))*(parseFloat(this.gstPercent.toString())))/100);
    this.gstAmount=Math.round((this.gstAmount + Number.EPSILON) * 100) / 100
    let tempTotal=this.tempSubtotal+this.gstAmount;
    this.totalAmount=Math.floor(tempTotal);
    this.roundoff=this.totalAmount-parseFloat( tempTotal.toString());
    this.roundoff= Math.round((this.roundoff + Number.EPSILON) * 100) / 100
    this.f.txtRoundOff.setValue(this.roundoff);
  }

  getServiceById(id : number){
    this.spinnerService.show();
    this.serverAPIService.getServiceById(id)
    .subscribe(
      (data:Service) => {
        this.serviceName=data.ServiceName;
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error('Something went wrong!');
      }
    );
  }

  getClientById(){
    this.spinnerService.show();
    this.serverAPIService.getClients()
    .subscribe(
      (data:Client[]) => {
        this.clients=data;
        this.cl = [{ id: 0, text: ''}];
        data.forEach(element => {
          this.cl.push({id:element.ClientID,text:element.EntityName});
         });
         this.cl.shift();
         if(this.isupdate)
          this.getBillById(this.saleBillId);
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error('Something went wrong!');
      }
    );
  }

  getFirms(){
   // this.spinnerService.show();
    this.serverAPIService.getFirms()
    .subscribe(
      (response:Firm[]) => {
        this.actFirm=response;
        this.firms = [{ id: 0, text: ''}];
        response.forEach(element => {
          this.firms.push({id:element.FirmID,text:element.FirmName});
         });
         this.firms.shift();
      //  this.spinnerService.hide();
         if(this.isupdate)
            this.getClientById();
          else
            this.spinnerService.hide();
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error('Something went wrong!');
      }
    );
  }

  onSubmit(form: NgForm){
    if(this.salebDet.length==0){
      this.toaster.warning("Enter at least one detail");
      return;
    }

    this.spinnerService.show();

    let sb=new SaleBill();
    sb.BillDateStr=this.f.txtBillDate.value;
    sb.BillType=this.f.ddlBillingType.value;
    sb.FirmID=this.f.ddlFirm.value;
    sb.ClientID=this.f.ddlClient.value;
    sb.WorkID=this.f.ddlWork.value;
    sb.ClientName=this.f.txtClientName.value;
    sb.Description=this.f.txtDescription.value;
    sb.GstAmount=this.gstAmount;
    sb.GstPercent=this.gstPercent;
    sb.RoundOff=this.f.txtRoundOff.value;
    sb.TotalAmount=this.totalAmount;
    sb.saleBillDetails=this.salebDet;
    sb.DiscountAmount = this.f.txtDiscountAmount.value;
    if(this.isupdate)
    {
      sb.SaleBillID=this.saleBill.SaleBillID;
      this.spinnerService.show();
        this.serverAPIService.updateBill(sb)
      .subscribe(
        (data: any) => {
          this.spinnerService.hide();
          this.toaster.success("Bill Updated successfully!");

          this.router.navigate(['/salebill']);
        },
        (error) => {
          this.spinnerService.hide();
          this.toaster.error(error._body);
        });
    }
    else{
      this.spinnerService.show();
        this.serverAPIService.createBill(sb)
      .subscribe(
        (data: any) => {
          this.spinnerService.hide();
          this.toaster.success("Bill Created successfully!");
          window.open(this.serverAPIService.webUrl+ "salebill/print-bill/"+data._body.toString(), "_blank");
          this.router.navigate(['/salebill']);
        },
        (error) => {
          this.spinnerService.hide();
          this.toaster.error(error._body);
        });
    }
  }

}
