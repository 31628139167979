import { Component, OnInit, AfterViewInit, ChangeDetectorRef, AfterContentChecked, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ServerAPIService } from 'src/app/serverapi.service';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from 'src/app/spinner.service';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { Client } from 'src/app/masters/client.model';
import { Firm } from 'src/app/masters/firm.model';
import { UserProfile } from 'src/app/user-profile/user-profile.model';
import { Work } from 'src/app/user-service/work.model';
import { FinancialYear } from 'src/app/fy.model';
declare let $: any;
import { Options } from 'select2';
import { Service } from 'src/app/user-service/service.model';

@Component({
  selector: 'app-accounting-details',
  templateUrl: './accounting-details.component.html',
  styleUrls: ['./accounting-details.component.css']
})
export class AccountingDetailsComponent implements OnInit,AfterViewInit,AfterContentChecked  {
  clientId:number;
  serviceId:number;
  fy:string;
  client:Client;
  profileForm = new FormGroup({
    ddlFirm: new FormControl(''),
    txtFee:new FormControl(''),
    ddlAssistant:new FormControl(''),
    txtRec:new FormControl(''),
    ddlBillingTp:new FormControl(''),
    Fynm:new FormControl('')
  });
  firms:object[];
  assitants:object[];
  billingtps:string[];
  serviceName:string;
  fyYear:string;
  fydata:string[];
  fylist:FinancialYear[];
  public options: Options;

  constructor(private serverAPIService:ServerAPIService,private route : ActivatedRoute,
    private router:Router, private toaster:ToastrService, private spinnerService:SpinnerService
    ,private ref: ChangeDetectorRef) { }

    ngAfterViewInit(){
    }

     // convenience getter for easy access to form fields
     get f() { return this.profileForm.controls; }


    ngAfterContentChecked() {
      this.ref.detectChanges();
    }

    ngOnInit() {
      // $(".js-example-basic-single").select2({dropdownAutoWidth : true});
    // $('.js-example-basic-single').select2({width: 'resolve'});
      //this.data=['01','02','03','04','05','06','07','08','09','10','11','12'];
      this.options = {
        width: '300',
        multiple: true,
        tags: true
      };

      this.billingtps=this.serverAPIService.BillingTypes;
      setTimeout(() => {
        this.spinnerService.show();
        this.route.params
        .subscribe(
          (params: Params) => {
            if(params["id"] != null){
              this.clientId=parseInt( params["id"]);
              this.serviceId=parseInt(params["srvid"]);
              this.fy=params["fy"];
              this.getFy(params["id"]);
              this.getClientById(this.clientId);

              this.getServiceById(this.serviceId);
            } else{
              this.spinnerService.hide();
            }
          }
        );
     });
    }

    getServiceById(id : number){
      this.spinnerService.show();
      this.serverAPIService.getServiceById(id)
      .subscribe(
        (data:Service) => {
          this.serviceName=data.ServiceName;
        },
        (error) => {
          this.spinnerService.hide();
          this.toaster.error('Something went wrong!');
        }
      );
    }

    getFy(clientId:number){
      this.spinnerService.show();
      this.serverAPIService.getUsersFy()
      .subscribe(
        (response : FinancialYear[]) => {
          //this.spinnerService.hide();
          this.fylist=response;
          this.fydata=[''];
          response.forEach(role => {
            this.fydata.push(role.FinancialYear);
          });

          this.fyYear=this.fy;
          // this.serverAPIService.getCurrentFy()
          // .subscribe(
          //   (response : any) => {
          //     this.fyYear=response;
          //   },
          //   (error) => {
          //     //this.spinnerService.hide();
          //     //this.toaster.error("Something went wrong!");
          //   }
          // )
        },
        (error) => {
          this.spinnerService.hide();
          this.toaster.error("Something went wrong!");
        }
      )
    }


    getClientById(id : number){
      this.spinnerService.show();
      this.serverAPIService.getClientById(id)
      .subscribe(
        (data:Client) => {
          this.client=data;
          this.getFirms();
        },
        (error) => {
          this.spinnerService.hide();
          this.toaster.error('Something went wrong!');
        }
      );
    }

    getFirms(){
      //this.spinnerService.show();
      this.serverAPIService.getFirms()
      .subscribe(
        (response : Firm[]) => {
          this.firms = [{ id: 0, text: ''}];
          response.forEach(element => {
            this.firms.push({id:element.FirmID,text:element.FirmName});
           });
           this.firms.shift();
         // this.spinnerService.hide();
         this.getAssistant();
        },
        (error) => {
          this.spinnerService.hide();
          this.toaster.error("Something went wrong!");
        }
      )
    }

    getAssistant(){
      //this.spinnerService.show();
      this.serverAPIService.getAssistants()
      .subscribe(
        (response : UserProfile[]) => {
          this.assitants = [{ id: 0, text: ''}];
          response.forEach(element => {
            this.assitants.push({id:element.AppUserID,text:element.AppUserName});
           });
           this.assitants.shift();
          this.spinnerService.hide();
        },
        (error) => {
          this.spinnerService.hide();
          this.toaster.error("Something went wrong!");
        }
      )
    }

    onSubmit(form: NgForm){

      // if(this.isEditMode)
      //   this.update(form);
      // else
        this.create(form);
    }

    create(form: NgForm){

      this.spinnerService.show();
      let t = new Work();

       if(this.f.ddlAssistant.value !=undefined && this.f.ddlAssistant.value!=''){
        //t.AppUserID= parseInt( this.f.ddlAssistant.value);
          t.AssistantMap=[];
        this.f.ddlAssistant.value.forEach(element => {
          t.AssistantMap.push({AssistantMapID:0, AssistantID:element,WorkID:0});
        });
      }
      t.BillingType=this.f.ddlBillingTp.value;
      t.ClientID=this.client.ClientID;
      t.Completed=false;
      t.Fee=this.f.txtFee.value;
      t.FileNo=this.f.txtRec.value;
      t.FinancialYear=this.f.Fynm.value;
      t.FirmID=this.f.ddlFirm.value;
      t.ServiceID=this.serviceId;
      //this.spinnerService.show();

      if(this.serviceId==13 || this.serviceId==30 || this.serviceId==32 || this.serviceId==37){
        this.serverAPIService.createMonthlyAccTask(t)
        .subscribe(
          (data: any) => {
            this.spinnerService.hide();
            this.toaster.success("Service added successfully!");

            this.router.navigate(['/service/client-service-list/'+this.clientId]);
          },
          (error) => {
            this.spinnerService.hide();
            this.toaster.error(error._body);
          });
      }
      else{
        this.serverAPIService.createAccountingTask(t)
        .subscribe(
          (data: any) => {
            this.spinnerService.hide();
            this.toaster.success("Service added successfully!");

            this.router.navigate(['/service/client-service-list/'+this.clientId]);
          },
          (error) => {
            this.spinnerService.hide();
            this.toaster.error(error._body);
          });
      }

    }

}
