import { Component, OnInit, AfterViewInit, ChangeDetectorRef, AfterContentChecked, ElementRef } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { ServerAPIService } from 'src/app/serverapi.service';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from 'src/app/spinner.service';
import { Subject } from 'rxjs';
import { UserwiseWork } from '../dashboard.model';
import { NgbDateAdapter, NgbDateParserFormatter, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { Client } from 'src/app/masters/client.model';
import { FormGroup, FormControl } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { UserProfile } from 'src/app/user-profile/user-profile.model';
import { PendingTasksReport } from './pending-tasks-report.model';
import { MmyyDateAdapter, MmyyDateParserFormatter } from 'src/app/dateformat';
import { StatusChange } from 'src/app/user-service/statuschange.model';
import { TaskList, WorkDetails } from 'src/app/user-service/work-details.model';
import { SmsLog } from 'src/app/task/smsLog.model';
import { FinancialYear } from 'src/app/fy.model';
declare let $: any;

@Component({
  selector: 'app-pending-tasks-report',
  templateUrl: './pending-tasks-report.component.html',
  styleUrls: ['./pending-tasks-report.component.css'],
  // providers: [{provide: NgbDateAdapter, useClass: CustomDateAdapter},
  //   {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}]
  providers: [{provide: NgbDateAdapter, useClass: MmyyDateAdapter},
    {provide: NgbDateParserFormatter, useClass: MmyyDateParserFormatter},DatePipe
  ]
})
export class PendingTasksReportComponent implements OnInit,AfterViewInit,AfterContentChecked {
  isChecked: boolean = false;
  arrWorkDetailsID: number[] = [];
  selectedBulkStatus: string = "";
  wBulkStatusArray:string[];

  assitants:UserwiseWork[];
  arrPendingTasks:PendingTasksReport[];
  astarray:object[];
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject();
  uid:number=0;
  currentDate = new Date();
  clientArray:Client[];
  taskType:string[]=['ALL','GST','INCOME TAX','OTHER']
  monthstr:string;

  workDetails:WorkDetails[];
  workDetailsAct:WorkDetails[];
  appuserId:number;
  assistantId:number;
  public tableWidget: any;
  tStatus:string='Selected Month';
  tstArray:string[];
  closeResult: string;
  wStatus:string;
  wStatusArray:string[];
  workDetailsText:string;
  commentsText:string='';

  tempClientId:number=0;
  tempWorkDetailsId:number=0;
  tempWorkId:number=0;
  tempTaskId:number=0;
  tempEntity:string='';
  tempTaskNm:string='';
  tempPeriod:string='';
  tempDueDate:string='';
  tempCurrentStatus:string='';
  tempSendToMobile:string='';

  openCnt:number=0;
  partialDataCnt:number=0;
  dataRecCnt:number=0;
  wipCnt:number=0;
  pendingForTaxCnt:number=0;
  appliedCnt:number=0;
  holdCnt:number=0;
  pendingCnt:number=0;
  completedCnt:number=0;
  accCnt:number=0;

  stserv:StatusChange[];
  dateHeading:string='Month';
  detailsHead:string='Details';

  cliEntity:string='';
  cliEmail:string='';
  cliOwner:string='';
  cliOwnerMob:string='';
  cliConPerson:string='';
  cliConMob:string='';
  cliGstin:string='';
  cliGSTRegType:string='';
  cliGSTUserName:string='';
  cliGSTRegDateString:string='';
  cliPAN:string='';
  cliITReturnUserName :string='';
  cliPTEC:string='';
  cliPTECAmount:string='';
  cliTAN :string='';
  cliContactDetails :string='';
  cliPhyRec:string='';
  cliFileNo:string='';
  serviceType='GST';
  taskNm:string='All';
  taskListorg:TaskList[];
  taskList:TaskList[];
  senderId:string='';
  whatsAppSenderId='';
  smslg:SmsLog[];
  cliPTRC:string='';

  isPeriodWise:boolean=false;
  fylist:FinancialYear[];
  fydata:string[];
  fyYear:string='';
  period:string[]=['All','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec','Jan','Feb','Mar','Q-1','Q-2','Q-3','Q-4']
  prd:string='';
  workSt:string="All Pending";

  vefificationType:string[]=['E Verified','By Post'];
  vType:string='';
  vdate:string='';
  vVis:boolean=false;

  smsFixedText:string='';
  smVariableText:string='';
  smPrefixVariableText:string='';
  tempRemark:string='';
  sendsms:boolean=true;

  openStatusText:string='Pending';

  profileForm = new FormGroup({
    txtFromDate: new FormControl(''),
    txtToDate:new FormControl(''),
    txtDueDate:new FormControl(''),
    ddlAssistant:new FormControl(''),
    ddlMonth:new FormControl(''),
    ddlTaskType:new FormControl(''),
  });

  dtElement: DataTableDirective;

  ngAfterContentChecked() {
    this.ref.detectChanges();
  }
   // convenience getter for easy access to form fields
   get f() { return this.profileForm.controls; }

  getDaysInMonth(month,year) {
   return new Date(year, month, 0).getDate();
  }

  constructor(public serverAPIService:ServerAPIService,
    private toaster:ToastrService, private spinnerService:SpinnerService,private router:Router
    ,private route : ActivatedRoute,private ref: ChangeDetectorRef,private modalService: NgbModal,private datePipe: DatePipe,private el: ElementRef,) {
      this.dtOptions = {
        processing: true,
        paging:false,
        destroy: true,
        order:[],
        dom: "Bftrpi",
        scrollY:"55vh",
        scrollX: true,
        buttons: {
          buttons: [{
             extend: 'excel',
             text: '<i class="mdi mdi-file-excel"></i> Export To Excel',
             titleAttr: 'Export To Excel',
             title:'PendingTasksReport - Aareasy'
            }
          ],
          dom: {
            button: {
              tag: "button",
              className: "btn btn-gradient-success btn-fw text-light btn-sm"
            },
            buttonLiner: {
              tag: null
            }
          }
        }
      }
  }


  ngOnInit() {
    let exampleId: any = $('#example');
    this.tableWidget = exampleId.DataTable({
      select: true
    });
    let day:number=this.currentDate.getDate();
    let month:number=this.currentDate.getMonth();
    let year:number=this.currentDate.getFullYear();
    if(month==12){
      month=0;
      //year=year+1;
    }
    this.f.txtFromDate.setValue('01'+ '/'+((month+1).toString().length==1
    ?'0'+(month+1).toString()
    :(month+1).toString())
    +'/'+year.toString());
    this.f.txtToDate.setValue((day.toString().length==1?'0'+day.toString():day.toString())+'/'+((month+1).toString().length==1
      ?'0'+(month+1).toString()
      :(month+1).toString())
      +'/'+year.toString());

      var dateObj = new Date();
      var currentMonth = dateObj.getUTCMonth() + 1;
      this.f.ddlMonth.setValue(currentMonth);
      this.f.ddlTaskType.setValue("ALL");
  }

  getAssistant(){
    this.serverAPIService.getAssistants()
    .subscribe(
      (response : UserProfile[]) => {
        this.astarray = [{ id: 0, text: 'ALL'}];
        response.forEach(element => {
          this.astarray.push({id:element.AppUserID,text:element.AppUserName});
         });
         if(this.uid!=0)
          this.f.ddlAssistant.setValue(this.uid);
         else
          this.f.ddlAssistant.setValue(this.astarray[0]["id"]);
        if(this.serverAPIService.getLoggedInUsersRole()=='Assistant')
          this.f.ddlAssistant.setValue(this.serverAPIService.getLoggedInUserId());
        this.getData();
      },
      () => {
        this.spinnerService.hide();
        this.toaster.error("Something went wrong!");
      }
    )
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.spinnerService.show();
      this.route.params
      .subscribe(
        (params: Params) => {
            this.monthstr = (new Date().getMonth() + 1).toString().slice(-2) + '/' + new Date().getFullYear().toString();
            this.f.txtDueDate.setValue(this.monthstr);
            this.getAssistant();
            this.wBulkStatusArray = [];
            this.wBulkStatusArray = [''];
            this.wBulkStatusArray = this.wBulkStatusArray.concat(this.serverAPIService.ITReturnServiceStatus);
            this.spinnerService.hide();
        }
      );
    });
  }


  viewRecord(){
   $('#example').DataTable().destroy();
   this.isChecked = false;
   this.arrWorkDetailsID = [];
    this.arrPendingTasks=null;
    this.dtTrigger.next();
    setTimeout(() => this.getData(),0)

  }

  getData(){
    this.spinnerService.show();
    this.serverAPIService.getPendingTasksReport(this.f.txtDueDate.value,this.f.ddlAssistant.value,this.f.ddlTaskType.value)
    .subscribe(
      (response : PendingTasksReport[]) => {
        this.arrPendingTasks=response;
        this.dtTrigger.next();this.ref.detectChanges();
        this.spinnerService.hide();
      },
      () => {
        this.spinnerService.hide();
        this.toaster.error("Something went wrong!");
      }
    )
  }

  open(content,WorkDetailsID:number) {
    this.tempClientId=0;
    this.tempCurrentStatus='';
    this.tempDueDate='';
    this.tempEntity='';
    this.tempPeriod='';
    this.tempTaskId=0;
    this.tempTaskNm='';
    this.tempWorkDetailsId=0;
    this.tempWorkId=0;
    this.wStatus='';
    this.workDetailsText='';
    this.commentsText='';
    this.vType='';
    this.vdate='';
    this.vVis=false;

    let cl: PendingTasksReport = this.arrPendingTasks.filter(x => x.WorkDetailsID === WorkDetailsID)[0];
    this.tempClientId=cl.ClientID;
    this.tempCurrentStatus=cl.WorkStatus;
    var start = new Date(cl.DueDate);
    this.tempDueDate=cl.DueDate!=undefined?(start.getDate().toString() +'/'+
    (start.getMonth()+1).toString()+'/'+
    start.getFullYear().toString()):'';// new Date(Date.parse(cl.DueDate)).toString();;
    this.tempEntity=cl.EntityName;
    this.tempPeriod=cl.WorkPeriod;
    this.tempTaskId=cl.TaskID;
    this.tempTaskNm=cl.TaskName.trimRight();
    this.tempWorkDetailsId=WorkDetailsID;
    this.tempWorkId=cl.WorkID;
    this.commentsText=cl.Comment;
    if(this.tempTaskNm=="GST Registration"){
      this.wStatusArray=this.serverAPIService.GstRegServiceStatus;
    }
    else if(this.tempTaskNm=="GSTR 1"){
      this.wStatusArray=this.serverAPIService.GstMonthlyServiceStatus;
    }
    else if(this.tempTaskNm=="GSTR 3B"){
      this.wStatusArray=this.serverAPIService.GstMonthlyServiceStatus;
    }
    else if(this.tempTaskNm=="Accounting Yearly"){
      this.wStatusArray=this.serverAPIService.AccountingServiceStatus;
    }
    else if(this.tempTaskNm=="Income Tax Audit"){
      this.wStatusArray=this.serverAPIService.ITAuditServiceStatus;
    }
    else if(this.tempTaskNm=="Income Tax Return"){
      this.wStatusArray=this.serverAPIService.ITReturnServiceStatus;
    }
    else if(this.tempTaskNm=="PTEC"){
      this.wStatusArray=this.serverAPIService.PTECServiceStatus;
    }
    else if(this.tempTaskNm=="Assessment"){
      this.wStatusArray=this.serverAPIService.AssesmentServiceStatus;
    }
    else{
      this.wStatusArray=this.serverAPIService.GstMonthlyServiceStatus;
    }

    //this.el.nativeElement.ViewChild('#ddlStatus').focus();


    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'},).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      content.find('#ddlStatus').focus();
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  updateStatus(){
    if(this.wStatus==''){
      this.toaster.warning('Select Status');
      return;
    }
    // if(this.wStatus !='Completed' && this.workDetailsText==''){
    //   this.toaster.warning('Enter work details');
    //   return;
    // }
    // if(this.wStatus=="Pending for Tax" &&  isNaN(parseFloat( this.workDetailsText))){
    //   this.toaster.warning('Enter tax amount');
    //   return;
    // }

    this.modalService.dismissAll();
    let wd1=new PendingTasksReport();
    wd1.ClientID=this.tempClientId;
    wd1.StatusDetails=this.workDetailsText;
    wd1.TaskID=this.tempTaskId;
    wd1.WorkDetailsID=this.tempWorkDetailsId;
    wd1.WorkID=this.tempWorkId;
    wd1.WorkStatus=this.wStatus;
    let cmt='';
    if(this.vType!=''){
      wd1.VerificationType=this.vType;
      cmt=cmt+this.vType;
      wd1.Comment=cmt;
    }
    if(this.vdate!=''){
      wd1.VerificationDateStr=this.vdate;
      cmt=cmt+' ,Date: '+this.vdate;
      wd1.Comment=cmt;
    }

    this.spinnerService.show();
    if (this.tableWidget) {
      this.tableWidget.destroy()
     this.tableWidget=null
    }
    this.serverAPIService.UpdateWorkStatusPending(wd1)
      .subscribe(
        (response : any) => {
          this.toaster.show("Task status updated successfully!!!");
          //this.spinnerService.hide();
          $('#example').DataTable().destroy();
          this.arrPendingTasks=null;
          this.dtTrigger.next();
          this.openCnt=0;
          this.partialDataCnt=0;
          this.dataRecCnt=0;
          this.wipCnt=0;
          this.pendingForTaxCnt=0;
          this.appliedCnt=0;
          this.holdCnt=0;
          this.completedCnt=0;
          this.accCnt=0;
          this.serverAPIService.getPendingTasksReport(this.f.txtDueDate.value,this.f.ddlAssistant.value,this.f.ddlTaskType.value)
          .subscribe(
            (response : PendingTasksReport[]) => {
              this.arrPendingTasks=response;
              this.dtTrigger.next();this.ref.detectChanges();
              this.spinnerService.hide();
            },
            () => {
              this.spinnerService.hide();
              this.toaster.error("Something went wrong!");
            }
          )
          this.spinnerService.hide();
         // this.router.navigate(['/home/pending-tasks-report']);
        },

        (error) => {
          this.spinnerService.hide();
          this.toaster.error("Something went wrong!");
        }
      )
  }
  addComment(){
    if(this.commentsText ==null || this.commentsText==''){
      this.toaster.warning('Enter Comment');
      return;
    }

    let wd1=new PendingTasksReport();
    wd1.ClientID=this.tempClientId;
    wd1.StatusDetails=this.workDetailsText;
    wd1.TaskID=this.tempTaskId;
    wd1.WorkDetailsID=this.tempWorkDetailsId;
    wd1.WorkID=this.tempWorkId;
    wd1.WorkStatus=this.wStatus;
    wd1.Comment=this.commentsText;

    this.spinnerService.show();
    if (this.tableWidget) {
      this.tableWidget.destroy()
     this.tableWidget=null
    }
    this.serverAPIService.addCommentpendingDetails(wd1)
      .subscribe(
        (response : any) => {
          this.toaster.show("Cooment added successfully!!!");
          this.spinnerService.hide();
        },
        (error) => {
          this.spinnerService.hide();
          this.toaster.error("Something went wrong!");
        }
      )
  }
  public onStatusSelected(event) {
    const value = event.target.value;
    if(value!=''){
        this.wStatus=value;
        if(value=="Pending for Tax")
          this.detailsHead="Tax Amount";
        else
          this.detailsHead="Details";
        if(value=="Completed" && this.serviceType=="INCOME TAX")
          this.vVis=true;
        else this.vVis=false;
      }
 }
 public onVtypeSelected(event) {
  const value = event.target.value;
  if(value!=''){
      this.vType=value;
    }
}
///////////////////////////////////////////////////////////
//Bulk Task Status Change -  Start
///////////////////////////////////////////////////////////
public onBulkStatusSelected(event) {
  const value = event.target.value;
  if (value != "") {
    this.selectedBulkStatus = value;
  }
}

selectAllClients(isSelectAll: boolean) {
  if (isSelectAll) {
    for (let row of this.arrPendingTasks) {
      row.isSelected = true;
    }
    this.arrWorkDetailsID = this.arrPendingTasks.map((a) => a.WorkDetailsID);
  } else {
    for (let row of this.arrPendingTasks) {
      row.isSelected = false;
    }
    this.arrWorkDetailsID = [];
  }
}

getProoduct(isSelected, product) {
  const index: number = this.arrWorkDetailsID.indexOf(product);
  if (isSelected) {
    if (index == -1) {
      this.arrWorkDetailsID.push(product);
    }
  } else {
    if (index !== -1) {
      this.arrWorkDetailsID.splice(index, 1);
      this.isChecked = false;
    }
  }
  if (this.arrWorkDetailsID.length == this.arrPendingTasks.length) this.isChecked = true;

}

changeBulkTasksStatus(){
  if(this.arrWorkDetailsID.length <= 0){
    this.toaster.warning('Please select at least one task to change status!');
    return;
  }
  this.spinnerService.show();
  this.serverAPIService.changeBulkTasksStatus(this.selectedBulkStatus,this.arrWorkDetailsID)
     .subscribe(
       (response:any) => {
        this.selectAllClients(false);
        this.isChecked = false;
        this.viewRecord();
        this.selectedBulkStatus = "";
        this.spinnerService.hide();
        this.toaster.success('Tasks status updated successfully!');

       },
       (error) => {
         console.log(error);
         this.spinnerService.hide();
         this.toaster.error('Something went wrong!');
       }
     );
}
///////////////////////////////////////////////////////////
//Bulk Task Status Change -  End
///////////////////////////////////////////////////////////
}




