import { Component, OnInit, AfterViewInit, ViewChild, TemplateRef } from '@angular/core';
import { NgForm, FormBuilder, FormGroup, Validators, FormControl, MaxLengthValidator } from '@angular/forms';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { ServerAPIService } from 'src/app/serverapi.service';
import { SpinnerService } from 'src/app/spinner.service';
import { ToastrService } from 'ngx-toastr';
import { Firm } from '../firm.model';
import { Client } from '../client.model';
import { NgbModal, NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CustomDateAdapter, CustomDateParserFormatter } from 'src/app/custom-ngbDateParserFormatter';

@Component({
  selector: 'app-client-details',
  templateUrl: './client-details.component.html',
  styleUrls: ['./client-details.component.css'],
  providers: [{provide: NgbDateAdapter, useClass: CustomDateAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}]
})
export class ClientDetailsComponent implements OnInit,AfterViewInit {
  @ViewChild('f',{static : true}) formRef : NgForm;
  isEditMode = false;
  clientID : number = 0;
  registerForm: FormGroup;
  submitted = false;
  profileForm = new FormGroup({
    EntityName: new FormControl(''),
    OwnersName: new FormControl(''),
    OwnersMobile:new FormControl(''),
    ContactPersonName:new FormControl(''),
    ContactPersonMobile:new FormControl(''),
    Email:new FormControl(''),
    Address:new FormControl(''),
    Taluka:new FormControl(''),
    District:new FormControl(''),
    PIN:new FormControl(''),
    ContactDetails:new FormControl(''),
    txtGSTIN:new FormControl(''),
    ddlGSTRegType:new FormControl(''),
    txtGSTUserName:new FormControl(''),
    txtGSTRegDate:new FormControl(''),
    txtPan:new FormControl(''),
    txtITReturnUserNm:new FormControl(''),
    txtItReturnPswd:new FormControl(''),
    txtPtec:new FormControl(''),
    txtPtrc:new FormControl(''),
    txtTan:new FormControl(''),
    txtFileNo:new FormControl(''),
    txtVat:new FormControl(''),
    txtPFRegNo:new FormControl(''),
    txtESICRegNo:new FormControl(''),
    txtDSCExpiryDate:new FormControl(''),
    txtPTECAmount:new FormControl('')
  });
  districtArray:string[]=['Ratnagiri','Sindhudurg','Pune','Raigad'];
  talukaArray:string[]=['Ratnagiri','Chiplun','Sangameshwar','Khed','Dapoli','Rajapur'
    ,'Guhagar','Lanja','Mandangad'];
  regTypeArray:string[]=['Regular','Composition','Unregistered'];
  currentDate = new Date();

  @ViewChild('mymodal') editModal : TemplateRef<any>;

  constructor(private serverAPIService:ServerAPIService,private route : ActivatedRoute,
    private router:Router, private toaster:ToastrService, private spinnerService:SpinnerService
    ,private formBuilder: FormBuilder,private modalService: NgbModal) { }

    ngOnInit() {
      $(".form-check label,.form-radio label").append('<i class="input-helper"></i>');
      let day:number=this.currentDate.getDate();
      let month:number=this.currentDate.getMonth();
      let year:number=this.currentDate.getFullYear();
      if(month==12){
        month=0;
        year=year+1;
      }
      this.f.txtGSTRegDate.setValue((day.toString().length==1?('0'+day.toString()):day.toString())+'/'+((month+1).toString().length==1 ?'0'+(month+1).toString() :(month+1).toString())
        +'/'+year.toString());
      this.f.txtDSCExpiryDate.setValue((day.toString().length==1?('0'+day.toString()):day.toString())+'/'+((month+1).toString().length==1 ?'0'+(month+1).toString() :(month+1).toString())
        +'/'+year.toString());
    }

    checkValue(event: any){
      //console.log(event.target.checked);
      if(event.target.checked){
        this.f.ContactPersonMobile.setValue(this.f.OwnersMobile.value);
        this.f.ContactPersonName.setValue(this.f.OwnersName.value);
      }
      else{
        this.f.ContactPersonMobile.setValue('');
        this.f.ContactPersonName.setValue('');
      }
   }

    public onOptionsSelected(event) {
      const value = event.target.value;
      if(value!=''){
        this.talukaArray=[];
        if(value=="Ratnagiri"){
          this.talukaArray=['Ratnagiri','Chiplun','Sangameshwar','Khed','Dapoli','Rajapur'
          ,'Guhagar','Lanja','Mandangad']
        }
        else if(value=="Pune"){
          this.talukaArray=['Pune City','Haveli','Khed','Baramati','Junnar','Shirur','Indapur'
          ,'Daund','Mawal','Ambegaon','Purandhar','Bhor','Mulshi','Velhe']
        }
        else if(value=="Raigad"){
          this.talukaArray=['Panvel','Alibag','Karjat','Khalapur','Pen','Mahad','Roha','Uran','Mangaon'
        ,'Shrivardhan','Murud','Sudhagad','Mhasla','Poladpur','Tala']
        }
        else{
          this.talukaArray=['Kudal','Sawantwadi','Kankavli','Devgad','Malwan','Vengurla'
          ,'Dodamarg','Vaibhavvadi']
        }
      }
   }

    // convenience getter for easy access to form fields
    get f() { return this.profileForm.controls; }


    ngAfterViewInit(): void{
      setTimeout(() => {
        this.spinnerService.show();
        this.route.params
        .subscribe(
          (params: Params) => {
            if(params["id"] != null){
              this.isEditMode = true;
              this.getClientById(params["id"]);
            } else{
              this.spinnerService.hide();
            }
          }
        );
      });
    }

    getClientById(id : number){

      this.serverAPIService.getClientById(id)
      .subscribe(
        (data:Client) => {
          this.clientID=data.ClientID;
          this.spinnerService.hide();
          this.f.EntityName.setValue(data.EntityName);
          this.f.OwnersName.setValue(data.OwnersName);
          this.f.OwnersMobile.setValue(data.OwnersMobile);
          this.f.ContactPersonName.setValue(data.ContactPersonName);
          this.f.ContactPersonMobile.setValue(data.ContactPersonMobile);
          this.f.Email.setValue(data.Email);
          this.f.Address.setValue(data.Address);
          this.f.ContactDetails.setValue(data.ContactDetails);
          this.f.District.setValue(data.District);
          if(data.District=="Ratnagiri"){
            this.talukaArray=['Ratnagiri','Chiplun','Sangameshwar','Khed','Dapoli','Rajapur'
            ,'Guhagar','Lanja','Mandangad']
          }
          else if(data.District=="Pune"){
            this.talukaArray=['Pune City','Haveli','Khed','Baramati','Junnar','Shirur','Indapur'
            ,'Daund','Mawal','Ambegaon','Purandhar','Bhor','Mulshi','Velhe']
          }
          else if(data.District=="Raigad"){
            this.talukaArray=['Panvel','Alibag','Karjat','Khalapur','Pen','Mahad','Roha','Uran','Mangaon'
          ,'Shrivardhan','Murud','Sudhagad','Mhasla','Poladpur','Tala']
          }
          else{
            this.talukaArray=['Kudal','Sawantwadi','Kankavli','Devgad','Malwan','Vengurla'
            ,'Dodamarg','Vaibhavvadi']
          }
          this.f.Taluka.setValue(data.Taluka);
          this.f.PIN.setValue(data.PIN);

          this.f.ddlGSTRegType.setValue(data.GSTRegType);
          this.f.txtGSTIN.setValue(data.GSTIN);
          this.f.txtGSTUserName.setValue(data.GSTUserName);
          if(data.GSTRegDateString !='')
            this.f.txtGSTRegDate.setValue(data.GSTRegDateString);

          this.f.txtItReturnPswd.setValue(data.ITReturnPassword);
          this.f.txtITReturnUserNm.setValue(data.ITReturnUserName);
          this.f.txtPan.setValue(data.PAN);

          this.f.txtPtec.setValue(data.PTEC);
          this.f.txtPtrc.setValue(data.PTRC);
          this.f.txtTan.setValue(data.TAN);
          this.f.txtFileNo.setValue(data.FileNo);
          this.f.txtVat.setValue(data.VAT);
          this.f.txtPFRegNo.setValue(data.PFRegNo);
          this.f.txtESICRegNo.setValue(data.ESICRegNo);
          if(data.DSCExpiryDateString !='')
            this.f.txtDSCExpiryDate.setValue(data.DSCExpiryDateString);
          this.f.txtPTECAmount.setValue(data.PTECAmount);
          // this.f.EntityName.disable();
          // this.f.OwnersName.disable();
        },
        (error) => {
          this.spinnerService.hide();
          this.toaster.error('Something went wrong!');
        }
      );


    }


    onSubmit(form: NgForm){
      if(this.f.ddlGSTRegType.value !='' && this.f.ddlGSTRegType.value!='Unregistered'
      && this.f.txtGSTIN.value==''){
        this.toaster.warning("Enter GSTIN");
        return;
      }

      if(this.isEditMode)
        this.update(form);
      else
        this.create(form);
    }

    create(form: NgForm){
      this.spinnerService.show();
      let frm = new Client();

      frm.EntityName = this.f.EntityName.value;
      frm.OwnersName=this.f.OwnersName.value;
      frm.OwnersMobile=this.f.OwnersMobile.value;
      frm.ContactPersonName=this.f.ContactPersonName.value;
      frm.ContactPersonMobile=this.f.ContactPersonMobile.value;
      frm.Email=this.f.Email.value;
      frm.Address=this.f.Address.value;
      frm.Taluka=this.f.Taluka.value;
      frm.District=this.f.District.value;
      frm.PIN=this.f.PIN.value;
      frm.ContactDetails=this.f.ContactDetails.value;

      frm.GSTRegType=this.f.ddlGSTRegType.value !=''? this.f.ddlGSTRegType.value:'';
      frm.GSTIN=this.f.txtGSTIN.value !=''? this.f.txtGSTIN.value:'';
      frm.GSTRegDateString=this.f.txtGSTRegDate.value !=''? this.f.txtGSTRegDate.value:'';
      frm.GSTUserName=this.f.txtGSTUserName.value !=''? this.f.txtGSTUserName.value:'';

      frm.ITReturnPassword=this.f.txtItReturnPswd.value !=''? this.f.txtItReturnPswd.value:'';
      frm.ITReturnUserName=this.f.txtITReturnUserNm.value !=''? this.f.txtITReturnUserNm.value:'';
      frm.PAN=this.f.txtPan.value !=''? this.f.txtPan.value:'';

      frm.PTEC=this.f.txtPtec.value !=''? this.f.txtPtec.value:'';
      frm.PTRC=this.f.txtPtrc.value !=''? this.f.txtPtrc.value:'';
      frm.TAN=this.f.txtTan.value !=''? this.f.txtTan.value:'';
      frm.FileNo=this.f.txtFileNo.value;

      frm.VAT = this.f.txtVat.value !='' ? this.f.txtVat.value:'';
      frm.PFRegNo = this.f.txtPFRegNo.value !='' ? this.f.txtPFRegNo.value:'';
      frm.ESICRegNo = this.f.txtESICRegNo.value !='' ? this.f.txtESICRegNo.value:'';
      frm.DSCExpiryDateString=this.f.txtDSCExpiryDate.value !=''? this.f.txtDSCExpiryDate.value:'';
      frm.PTECAmount = parseFloat(this.f.txtPTECAmount.value);

      this.spinnerService.show();

      this.serverAPIService.checkClientExists(frm.EntityName)
      .subscribe(
        (data: any) => {
          this.serverAPIService.createClient(frm)
            .subscribe(
              (data: any) => {
                this.toaster.success("Client created successfully!");
                //this.open( $('#mymodal'));
                this.clientID=parseInt( data._body);
                this.modalService.open(this.editModal);
               // this.router.navigate(['/master/client']);
              },
              (error) => {
                this.spinnerService.hide();
                if(JSON.parse(error['_body']).Message !=undefined)
                  this.toaster.error(JSON.parse(error['_body']).Message);
                else
                  this.toaster.error(error);
              });
        },
        (error) => {
          this.spinnerService.hide();
          if(JSON.parse(error['_body']).Message !=undefined)
            this.toaster.error(JSON.parse(error['_body']).Message);
          else
            this.toaster.error(error);
        });


    }

    addservice(){
      this.modalService.dismissAll();
      this.router.navigate(['/service/client-service-list/'+this.clientID]);
    }

    backtolist(){
      this.modalService.dismissAll();
      this.router.navigate(['/master/client']);
    }

    open(content) {
      this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'});
    }

    update(form: NgForm){

      let frm = new Client();

      frm.ClientID=this.clientID;
      frm.EntityName=this.f.EntityName.value;
      frm.OwnersName=this.f.OwnersName.value;
      frm.OwnersMobile=this.f.OwnersMobile.value;
      frm.ContactPersonName=this.f.ContactPersonName.value;
      frm.ContactPersonMobile=this.f.ContactPersonMobile.value;
      frm.Email=this.f.Email.value;
      frm.Address=this.f.Address.value;
      frm.Taluka=this.f.Taluka.value;
      frm.District=this.f.District.value;
      frm.PIN=this.f.PIN.value;
      frm.ContactDetails=this.f.ContactDetails.value;

      frm.GSTRegType=this.f.ddlGSTRegType.value !=''? this.f.ddlGSTRegType.value:'';
      frm.GSTIN=this.f.txtGSTIN.value !=''? this.f.txtGSTIN.value:'';
      frm.GSTRegDateString=this.f.txtGSTRegDate.value !=''? this.f.txtGSTRegDate.value:'';
      frm.GSTUserName=this.f.txtGSTUserName.value !=''? this.f.txtGSTUserName.value:'';

      frm.ITReturnPassword=this.f.txtItReturnPswd.value !=''? this.f.txtItReturnPswd.value:'';
      frm.ITReturnUserName=this.f.txtITReturnUserNm.value !=''? this.f.txtITReturnUserNm.value:'';
      frm.PAN=this.f.txtPan.value !=''? this.f.txtPan.value:'';

      frm.PTEC=this.f.txtPtec.value !=''? this.f.txtPtec.value:'';
      frm.PTRC=this.f.txtPtrc.value !=''? this.f.txtPtrc.value:'';
      frm.TAN=this.f.txtTan.value !=''? this.f.txtTan.value:'';
      frm.FileNo=this.f.txtFileNo.value;

      frm.VAT = this.f.txtVat.value !=''? this.f.txtVat.value:'';
      frm.PFRegNo = this.f.txtPFRegNo.value !=''? this.f.txtPFRegNo.value:'';
      frm.ESICRegNo = this.f.txtESICRegNo.value !=''? this.f.txtESICRegNo.value:'';
      frm.DSCExpiryDateString=this.f.txtDSCExpiryDate.value !=''? this.f.txtDSCExpiryDate.value:'';
      frm.PTECAmount =  parseFloat(this.f.txtPTECAmount.value);

      this.spinnerService.show();

      this.serverAPIService.updateClient(frm)
      .subscribe(
        (data: any) => {
          this.spinnerService.hide();
          this.toaster.success("Client updated successfully!");
          this.router.navigate(['/master/client']);
        },
        (error) => {
          this.spinnerService.hide();
          this.toaster.error(error);
        });
    }

}
