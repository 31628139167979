// let table = document.querySelector('#testTable');
// let button = document.querySelector('#button');
// function selectNode(node){
//   let range  =  document.createRange();
//   range.selectNodeContents(node)
//   let select =  window.getSelection()
//   select.removeAllRanges()
//   select.addRange(range)
// }
// button.addEventListener('click',function(){
//   selectNode(table);
//   document.execCommand('copy')

// })
document.addEventListener('copy', function (e) {
  var copyText = $("#td").text();
  e.clipboardData.setData('text/plain', copyText);
  e.preventDefault(); // default behaviour is to copy any selected text
});
exports.myFunction = function myFunction() {
  //alert('External custom js');

    document.execCommand('copy');


}
