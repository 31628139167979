import { Component, OnInit, AfterViewInit, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { ServerAPIService } from 'src/app/serverapi.service';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from 'src/app/spinner.service';
import { Subject } from 'rxjs';
import { UserwiseWork } from '../dashboard.model';
import { NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { Client } from 'src/app/masters/client.model';
import { FormGroup, FormControl } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { UserProfile } from 'src/app/user-profile/user-profile.model';
import { ClientListReport } from './client-list-report.model';
import { MmyyDateAdapter, MmyyDateParserFormatter } from 'src/app/dateformat';
import { FinancialYear } from 'src/app/fy.model';
declare let $: any;

@Component({
  selector: 'app-client-list-report',
  templateUrl: './client-list-report.component.html',
  styleUrls: ['./client-list-report.component.css'],
})
export class ClientListReportComponent implements OnInit,AfterViewInit,AfterContentChecked {
  arrClientList:ClientListReport[];
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject();
  taskType:string[]=['ALL','GST','INCOME TAX','OTHER']
  profileForm = new FormGroup({
    ddlTaskType:new FormControl(''),
    txtfy:new FormControl(''),
  });
  public tableWidget: any;
  dtElement: DataTableDirective;
  cnt:number=0;
  fyYear:string='';
  fydata:string[];
  fylist:FinancialYear[];

  ngAfterContentChecked() {
    this.ref.detectChanges();
  }
   // convenience getter for easy access to form fields
   get f() { return this.profileForm.controls; }

  constructor(public serverAPIService:ServerAPIService,
    private toaster:ToastrService, private spinnerService:SpinnerService
    ,private route : ActivatedRoute,private ref: ChangeDetectorRef) {
      this.dtOptions = {
        processing: true,
        paging:false,
        destroy: true,
        order:[],
        dom: "Bftrpi",
        scrollY:"55vh",
        scrollX: true,
  buttons: {
    buttons: [{
       extend: 'excel',
       text: '<i class="mdi mdi-file-excel"></i> Export To Excel',
       titleAttr: 'Export To Excel',
       title:''
      }
    ],
    dom: {
      button: {
        tag: "button",
        className: "btn btn-gradient-success btn-fw text-light btn-sm"
      },
      buttonLiner: {
        tag: null
      }
    }
  }
      }
  }


  ngOnInit() {
      this.f.ddlTaskType.setValue("GST");
  }

  ngAfterViewInit(): void {
    setTimeout(()=>{
      this.getFy();
    });
  }

  public onTypeSelected(event) {
      $('#example').DataTable().destroy();
      this.arrClientList=[];
      this.dtTrigger.next();
      setTimeout(() => this.getData(),0);
 }

  getData(){
    console.log("getData");
    this.spinnerService.show();
    this.serverAPIService.getClientListReport(this.f.txtfy.value,this.f.ddlTaskType.value)
    .subscribe(
      (response : ClientListReport[]) => {
        this.arrClientList=response;
        this.cnt=this.arrClientList.length;
        this.dtTrigger.next();this.ref.detectChanges();
        this.spinnerService.hide();
      },
      () => {
        this.spinnerService.hide();
        this.toaster.error("Something went wrong!");
      }
    )
  }

  changeFy(fy:string){
    this.fyYear=fy;
    this.f.txtfy.setValue(this.fyYear);
    $('#example').DataTable().destroy();
      this.arrClientList=[];
      this.dtTrigger.next();
      setTimeout(() => this.getData(),0);
  }

  getFy(){
    $('#example').DataTable().destroy();
    this.arrClientList=null;
    this.dtTrigger.next();
    this.spinnerService.show();
    this.serverAPIService.getUsersFy()
    .subscribe(
      (response : FinancialYear[]) => {
        this.fylist=response;
        this.fydata=[''];
        response.forEach(role => {
          this.fydata.push(role.FinancialYear);
        });
        this.fydata.shift;
        this.serverAPIService.getCurrentFy()
        .subscribe(
          (response : any) => {
            this.fyYear=response;
            this.f.txtfy.setValue(this.fyYear);
            this.getData();
            this.spinnerService.hide();
          },
          (error) => {
          }
        )
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error("Something went wrong!");
      }
    )
  }
}




