export class Task{
    public  TaskID :number;
    public  ServiceID :number;
    public TaskName :string;
    public  TaskType: string;
    public DueDate:string;
    public RequiredDocument:string;
    public ServiceName:string;
    public CustomerTaskID:number;
    public CustomerID:number;

    constructor(){}
}