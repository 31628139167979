import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { ServerAPIService } from '../serverapi.service';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(public serverAPIService: ServerAPIService, public router: Router) {}

  canActivate(): boolean {
    if (!this.serverAPIService.isAuthenticated()) {
      this.router.navigate(['/signin']);
      return false;
    }
    return true;
  }

}
