export class Client{
    public  ClientID :number;
    public AppUserID :number;
    public  EntityName :string;
    public OwnersName :string;
    public  ContactPersonName: string;
    public ContactPersonMobile:string;
    public OwnersMobile:string;
    public Email:string;
    public Address:string;
    public Taluka:string;
    public  District :string;
    public  PIN :string;
    public  GSTIN :string;
    public  GSTRegType :string;
    public  GSTUserName :string;
    public  PAN :string;
    public  PTEC :string;
    public  TAN :string;
    public  ITReturnUserName :string;
    public  ITReturnPassword :string;
    public GSTRegDateString:string;
    public ContactDetails:string;
    public  PTRC: string;
    public FileNo:string;
    public  VAT :string;
    public  PFRegNo :string;
    public  ESICRegNo :string;
    public DSCExpiryDateString:string;
    public PTECAmount :number;

    constructor(){}
}
