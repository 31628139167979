import { Component, OnInit } from '@angular/core';
import { AdminDetails } from '../admindetails.model';
import { ServerAPIService } from 'src/app/serverapi.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerService } from 'src/app/spinner.service';
import { CommunicationService } from 'src/app/communation.service';

@Component({
  selector: 'app-order-completed',
  templateUrl: './order-completed.component.html',
  styleUrls: ['./order-completed.component.css']
})
export class OrderCompletedComponent implements OnInit {

  order: AdminDetails = new AdminDetails();
  gstClients:string='';
  itClients:string='';
  otherClients:string='';

  constructor(public serverAPIService:ServerAPIService,private toastr: ToastrService,
    private route:ActivatedRoute, private router:Router,private commService:CommunicationService,
    private spinnerService: SpinnerService) { }

    ngOnInit() {
      if(!this.serverAPIService.isAuthenticated()){
        this.router.navigate(['/signin']);
      }else{
        this.loadOrder();
      }
    }

    loadOrder(){
      this.spinnerService.show();
  
      let orderId:number = this.route.snapshot.queryParams['id'];
      this.serverAPIService.getAdminDetailsById(orderId)
      .subscribe(
        (data: AdminDetails) =>{
          this.order = data;
         // console.log(data);
         if(data.PaymentType=="Silver"){
           this.gstClients="50";
           this.itClients='150';
           this.otherClients='150';
         }
         else  if(data.PaymentType=="Gold"){
          this.gstClients="75";
          this.itClients='250';
          this.otherClients='250';
        }
        else
        {
          this.gstClients="Unlimited";
          this.itClients='Unlimited';
          this.otherClients='Unlimited';
        }
         this.commService.emitChange();
          this.spinnerService.hide();
        },
        (error: Response) =>{
          this.spinnerService.hide();
          this.commService.emitChange();
          this.toastr.error("Failed to place order please try again!")
        }
      )
    }

}
