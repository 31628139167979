import { Component, OnInit, AfterViewInit, AfterContentChecked, ChangeDetectorRef, Injectable } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ServerAPIService } from 'src/app/serverapi.service';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from 'src/app/spinner.service';
import { Subject } from 'rxjs';
import { Task } from '../task.model';
import { NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import { CustomNgbDateParserFormatter } from 'src/app/custom-ngbDateParserFormatter';
import { FormGroup, FormControl, NgForm } from '@angular/forms';
//import { NgbDateCustomParserFormatter } from 'src/app/dateformat';
declare let $: any;

@Component({
  selector: 'app-task-details',
  templateUrl: './task-details.component.html',
  styleUrls: ['./task-details.component.css'],
  providers: [
    
   ]
})
export class TaskDetailsComponent implements OnInit,AfterContentChecked {

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  serviceName:string="";
  taskId:number;
  model: NgbDateStruct;
  dateString: string;
  task:Task;
  datenum:String[];
  datefor:String[];
  monthnum:String[];

  isEditMode = false;
  firmID : number = 0;
  registerForm: FormGroup;
  submitted = false;
  profileForm = new FormGroup({
    ServiceName:new FormControl(''),
    TaskName:new FormControl(''),
    TaskType:new FormControl(''),
    RequiredDocument:new FormControl(''),
    dateStr:new FormControl(''),
    datestrType:new FormControl(''),
    monthStr:new FormControl('')
  });

  constructor(private serverAPIService:ServerAPIService,private route : ActivatedRoute,
    private router:Router, private toaster:ToastrService, private spinnerService:SpinnerService
    ,private ref: ChangeDetectorRef,private ngbDateParserFormatter: NgbDateParserFormatter) {
  }

  ngAfterContentChecked() {
    this.ref.detectChanges();
}

 // convenience getter for easy access to form fields
 get f() { return this.profileForm.controls; }
  

  ngOnInit() {
    setTimeout(() => {
      this.spinnerService.show();
      this.route.params
      .subscribe(
        (params: Params) => {
          if(params["id"] != null){
            this.taskId=params["id"];
            this.getTaskForUser(params["id"]);
          } else{
            this.spinnerService.hide();
          }
        }
      );
    });

    
  }

  onCitySelect(event) {
    console.log(event);
}

  getTaskForUser(id:number){
    this.spinnerService.show();
    this.serverAPIService.getUserTaskById(id)
    .subscribe(
      (response : Task) => {
        this.spinnerService.hide();
        this.task=response;
        this.f.ServiceName.setValue( this.task.ServiceName);
        this.f.TaskName.setValue( this.task.TaskName);
        this.f.TaskType.setValue(this.task.TaskType);
        this.f.RequiredDocument.setValue(this.task.RequiredDocument);
        if(this.task.CustomerTaskID != null)
          this.isEditMode=true;

        this.datenum=['01','02','03','04','05','06','07','08','09','10'
        ,'11','12','13','14','15','16','17','18','19','20'
        ,'21','22','23','24','25','26','27','28','29','30','31'];

        if(this.task.TaskType=="Yearly"){
          this.monthnum=['01','02','03','04','05','06','07','08','09','10','11','12'];
        }
        if(this.task.TaskType=="Monthly"){
          this.datefor=['of Next Month','of Current Month','of Previous Month'];
          if(this.task.DueDate !=undefined && this.task.DueDate !=null){
            this.f.datestrType.setValue(new String( this.task.DueDate.substring(this.task.DueDate.split(' ')[0].length
            ,this.task.DueDate.length)).trimLeft());
            this.f.dateStr.setValue(this.task.DueDate.split(' ')[0]);
          }
        }
        else if(this.task.TaskType=="Quarterly"){
          this.datefor=['of Next Month of Quarter End','of Last Month of Quarter'];
          if(this.task.DueDate !=undefined && this.task.DueDate !=null){
            this.f.datestrType.setValue(new String( this.task.DueDate.substring(this.task.DueDate.split(' ')[0].length
            ,this.task.DueDate.length)).trimLeft());
            this.f.dateStr.setValue(this.task.DueDate.split(' ')[0]);
          }
        }
        else if(this.task.TaskType=="Yearly"){
          this.datefor=['of Current Financial Year','of Next Financial Year'];
          if(this.task.DueDate !=undefined && this.task.DueDate !=null){
            this.f.datestrType.setValue(new String( this.task.DueDate.substring(this.task.DueDate.split(' ')[0].length
            ,this.task.DueDate.length)).trimLeft());
            this.f.dateStr.setValue(this.task.DueDate.split(' ')[0].split('/')[0]);
            this.f.monthStr.setValue(this.task.DueDate.split(' ')[0].split('/')[1]);
          }
        }

        this.dtTrigger.next();
       // $('.js-example-basic-single').select2();
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error("Something went wrong!");
      }
    )
  }

  onSubmit(form: NgForm){

    if(this.task.CustomerTaskID==undefined|| this.task.CustomerTaskID==null)
      this.create(form);
    else 
      this.update(form);
  }

  

  create(form: NgForm){

    this.spinnerService.show();
    let frm = new Task();

    frm.CustomerID=this.task.CustomerID;
    let dueDate:string="";
    console.log($('#datestrType').find(':selected'));
    if(this.task.TaskType=="Yearly"){
      dueDate=this.f.dateStr.value+'/'+this.f.monthStr.value+' '+this.f.datestrType.value;
    }
    else if(this.task.TaskType != "One Time"){
      dueDate=this.f.dateStr.value+' '+this.f.datestrType.value;
    }
    frm.DueDate=dueDate;
    frm.RequiredDocument=this.f.RequiredDocument.value;
    frm.TaskID=this.task.TaskID;
    frm.TaskType=this.task.TaskType;
    this.spinnerService.show();

    this.serverAPIService.createCustomerTask(frm)
    .subscribe(
      (data: any) => {
        this.spinnerService.hide();
        this.toaster.success("Task created successfully!");

        this.router.navigate(['/service/task-list/'+this.task.ServiceID]);
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error(error);
      });
  }

  update(form: NgForm){

    let frm = new Task();
    
    frm.CustomerID=this.task.CustomerID;
    let dueDate:string="";
    if(this.task.TaskType=="Yearly"){
      dueDate=this.f.dateStr.value+'/'+this.f.monthStr.value+' '+this.f.datestrType.value;
    }
    else if(this.task.TaskType != "One Time"){
      dueDate=this.f.dateStr.value+' '+this.f.datestrType.value;
    }
    frm.DueDate=dueDate;
    frm.RequiredDocument=this.f.RequiredDocument.value;
    frm.TaskID=this.task.TaskID;
    frm.TaskType=this.task.TaskType;
    frm.CustomerTaskID=this.task.CustomerTaskID;

    this.spinnerService.show();

    this.serverAPIService.updateCustomerTask(frm)
    .subscribe(
      (data: any) => {
        this.spinnerService.hide();
        this.toaster.success("Task updated successfully!");
        this.router.navigate(['/service/task-list/'+this.task.ServiceID]);
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error(error);
      });
  }

}
