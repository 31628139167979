import { Component, OnInit, AfterViewInit, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { NgbDateAdapter, NgbDateParserFormatter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomDateAdapter, CustomDateParserFormatter } from 'src/app/custom-ngbDateParserFormatter';
import { Client } from 'src/app/masters/client.model';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ServerAPIService } from 'src/app/serverapi.service';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from 'src/app/spinner.service';
import { FormGroup, FormControl, NgForm } from '@angular/forms';
import { Firm } from 'src/app/masters/firm.model';
import { InwardOutward } from '../inward-outward.model';
import { Options } from 'select2';
declare let $: any;

@Component({
  selector: 'app-in-out-details',
  templateUrl: './in-out-details.component.html',
  styleUrls: ['./in-out-details.component.css'],
  providers: [{provide: NgbDateAdapter, useClass: CustomDateAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}]
})
export class InOutDetailsComponent implements OnInit,AfterViewInit,AfterContentChecked {
  inoutid:number;
  clients:Client[];
  cl:object[];
  inout:InwardOutward;
  profileForm = new FormGroup({
    ddlType: new FormControl(''),
    txtDate:new FormControl(''),
    ddlFromTo:new FormControl(''),
    ddlClient:new FormControl(''),
    txtFromTo:new FormControl(''),
    txtDetails:new FormControl(''),
    txtPlacedAt:new FormControl('')
  });
  isupdate:boolean=false;
  currentDate = new Date();
  tp:string[]=['In','Out'];
  typeNm:string='In';
  frmto:string[]=['','Client','Other'];
  clVis:boolean=false;
  clientins:Options[];
  public options: Options;
  showInwards:boolean=false;

  constructor(private serverAPIService:ServerAPIService,private route : ActivatedRoute,
    private router:Router, private toaster:ToastrService, private spinnerService:SpinnerService
    ,private ref: ChangeDetectorRef,private modalService: NgbModal) {
  }

  ngAfterViewInit(){
  }

  // convenience getter for easy access to form fields
  get f() { return this.profileForm.controls; }

  public inwardChange(event) {
    let st='';
    event.forEach(element => {
      st=st+(st.length==0?'': ', ')+element;
    });
    this.f.txtDetails.setValue(st);
  }

  public onClientSelected(event) {
    if(event!=''){
      this.f.txtFromTo.setValue(event);
      if(this.f.ddlType.value=="Out"){
        this.serverAPIService.getLastIn(this.f.txtDate.value,event)
        .subscribe(
          (data:InwardOutward[]) => {
            this.clientins = [{ id: 0, text: ''}];
            data.forEach(element => {
              this.clientins.push({id:element.Details, text:element.Details});
            });

            if(this.clientins.length>0){
              this.clientins.shift();
              this.showInwards=true;
            }
            this.spinnerService.hide();
          },
          (error) => {
            this.spinnerService.hide();
            this.toaster.error('Something went wrong!');
          }
        );

      }
      else
        this.showInwards=false;
    }
    else
      this.showInwards=false;
  }

  ngAfterContentChecked() {
    this.ref.detectChanges();
  }

  public onTypeSelected(event) {
      this.typeNm=event.target.value;
      if (event.target.value == "In")
        this.showInwards = false;
      else if(this.f.ddlClient.value != '')
        this.showInwards = true;
  }

  public onFromToSelected(event){
    if(event.target.value=='Client')
      this.clVis=true;
    else{
      this.clVis=false;
      this.showInwards = false;
      this.f.txtDetails.setValue('');
      this.f.txtFromTo.setValue('');
      this.f.ddlClient.setValue('');
    }

  }

  ngOnInit() {
    //$('.js-example-basic-single').select2({width: 'resolve'});
    this.options = {
      width: '300',
      multiple: true,
      tags: true
    };
    let day:number=this.currentDate.getDate();
    let month:number=this.currentDate.getMonth();
    let year:number=this.currentDate.getFullYear();
    if(month==12){
      month=0;
      year=year+1;
    }
    this.f.txtDate.setValue(((day).toString().length==1 ?'0'+(day).toString() :(day).toString())+'/'+((month+1).toString().length==1 ?'0'+(month+1).toString() :(month+1).toString())
      +'/'+year.toString());

    this.f.ddlType.setValue("In");
      setTimeout(() => {
        this.spinnerService.show();
        this.route.params
        .subscribe(
          (params: Params) => {
            if(params["id"] != null){
              this.inoutid=parseInt( params["id"]);
              this.isupdate=true;
              this.getClientById();
              this.getInoutById(this.inoutid);
            } else{
              this.getClientById();
              this.spinnerService.hide();
            }
          }
        );
      });
  }

  getInoutById(id : number){
    this.spinnerService.show();
    this.serverAPIService.getInOutById(id)
    .subscribe(
      (data:InwardOutward) => {
        this.inout=data;
        this.f.ddlType.setValue(data.InOutType);
        this.f.txtDate.setValue(data.InOutDateStr);
        this.f.txtFromTo.setValue(data.FromTo);
        this.f.txtDetails.setValue(data.Details);
        this.f.txtPlacedAt.setValue(data.PlacedAt);
        this.f.ddlFromTo.setValue('Other');
        console.log(data.ClientId);
        console.log(this.f.ddlClient.value);
        if(data.ClientId>0)
          this.f.ddlClient.setValue(data.FromTo);
        console.log(this.f.ddlClient.value);
        this.spinnerService.hide();

      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error('Something went wrong!');
      }
    );
  }

  getClientById(){
    this.spinnerService.show();
    this.serverAPIService.getClients()
    .subscribe(
      (data:Client[]) => {
        this.clients=data;
        this.cl = [{ id: '', text: ''}];
        data.forEach(element => {
          this.cl.push({id:element.EntityName,text:element.EntityName});
         });
         this.cl.shift();
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error('Something went wrong!');
      }
    );
  }

  onSubmit(form: NgForm,content){
    this.spinnerService.show();

    let sb=new InwardOutward();
    sb.Details=this.f.txtDetails.value;
    sb.FromTo=this.f.txtFromTo.value;
    sb.InOutDateStr=this.f.txtDate.value;
    sb.InOutType=this.f.ddlType.value;
    sb.PlacedAt=this.f.txtPlacedAt.value;

    if(this.isupdate)
    {
      sb.InwardOutwardID=this.inout.InwardOutwardID;
      this.spinnerService.show();
        this.serverAPIService.updatInOut(sb)
      .subscribe(
        (data: any) => {
          this.spinnerService.hide();
          this.toaster.success("Record Updated successfully!");
          if(this.f.ddlType.value=='In' && this.f.ddlClient.value !="")
            this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'});
          else
            this.router.navigate(['/in-out']);
        },
        (error) => {
          this.spinnerService.hide();
          this.toaster.error(error._body);
        });
    }
    else{
      this.spinnerService.show();
        this.serverAPIService.createInOut(sb)
      .subscribe(
        (data: any) => {
          this.spinnerService.hide();
          this.toaster.success("Record Created successfully!");
          if(this.f.ddlType.value=='In' && this.f.ddlFromTo.value=='Client')
            this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'});
          else
            this.router.navigate(['/in-out']);


        },
        (error) => {
          this.spinnerService.hide();
          this.toaster.error(error._body);
        });
    }
  }
  gotopending(){
    this.modalService.dismissAll();
    this.router.navigate(['/service/pending-task-list/p']);
  }
  goToUnassignedTasks(){
    this.modalService.dismissAll();
    let ClientId = 0;
    ClientId = this.clients.filter(o => o.EntityName == this.f.ddlClient.value)[0].ClientID;
    this.router.navigate(['home/unassigned-tasks/'+ClientId]);
  }
  gotolist(){
    this.modalService.dismissAll();
    this.router.navigate(['/in-out']);
  }
}
