import { Component, OnInit, ChangeDetectorRef, AfterViewInit, AfterContentChecked } from '@angular/core';
import { Client } from 'src/app/masters/client.model';
import { FormGroup, FormControl, NgForm } from '@angular/forms';
import { ServerAPIService } from 'src/app/serverapi.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from 'src/app/spinner.service';
import { Firm } from 'src/app/masters/firm.model';
import { UserProfile } from 'src/app/user-profile/user-profile.model';
import Stepper from 'bs-stepper'
import { Work } from 'src/app/user-service/work.model';
import { Service } from 'src/app/user-service/service.model';
import { NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CustomDateAdapter, CustomDateParserFormatter } from 'src/app/custom-ngbDateParserFormatter';
import { FinancialYear } from 'src/app/fy.model';
declare let $: any;
import { Options } from 'select2';

@Component({
  selector: 'app-gst-mothly-details',
  templateUrl: './gst-mothly-details.component.html',
  styleUrls: ['./gst-mothly-details.component.css'],
  providers: [{provide: NgbDateAdapter, useClass: CustomDateAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}]
})
export class GstMothlyDetailsComponent implements OnInit,AfterViewInit,AfterContentChecked {
  clientId:number;
  serviceId:number;
  fy:string;
  client:Client;
  profileForm = new FormGroup({
    ddlFirm: new FormControl(''),
    txtFee:new FormControl(''),
    ddlAssistant:new FormControl(''),
    txtRec:new FormControl(''),
    ddlBillingTp:new FormControl(''),
    txtGSTIN:new FormControl(''),
    ddlGSTRegType:new FormControl(''),
    txtGSTUserName:new FormControl(''),
    txtGSTRegDate:new FormControl(''),
    Fynm:new FormControl('')
  });
  firms:object[];
  assitants:object[];
  billingtps:string[];
  private stepper: Stepper;
  serviceName:string;
  regTypeArray:string[]=['Regular','Composition'];
  fyYear:string;
  fydata:string[];
  fylist:FinancialYear[];
  public options: Options;
  
  constructor(private serverAPIService:ServerAPIService,private route : ActivatedRoute,
    private router:Router, private toaster:ToastrService, private spinnerService:SpinnerService
    ,private ref: ChangeDetectorRef) {
  }
  ngAfterViewInit(){
  }

   // convenience getter for easy access to form fields
   get f() { return this.profileForm.controls; }
  

  ngAfterContentChecked() {
    this.ref.detectChanges();
  }

  ngOnInit() {
    // $(".js-example-basic-single").select2({dropdownAutoWidth : true});
   // $('.js-example-basic-single').select2({width: 'resolve'});
    //this.data=['01','02','03','04','05','06','07','08','09','10','11','12'];
    this.options = {
      width: '300',
      multiple: true,
      tags: true
    };

    this.stepper = new Stepper(document.querySelector('#stepper1'), {
      linear: false,
      animation: true
    })

    this.billingtps=this.serverAPIService.BillingTypes;
    setTimeout(() => {
      this.spinnerService.show();
      this.route.params
      .subscribe(
        (params: Params) => {
          if(params["id"] != null){
            this.clientId=parseInt( params["id"]);
            this.serviceId=parseInt(params["srvid"]);
            this.fy=params["fy"];
            this.getFy(params["id"]);
            this.getClientById(this.clientId);
            this.getServiceById(this.serviceId);
          } else{
            this.spinnerService.hide();
          }
        }
      );
   });
  }

  getFy(clientId:number){
    this.spinnerService.show();
    this.serverAPIService.getUsersFy()
    .subscribe(
      (response : FinancialYear[]) => {
        //this.spinnerService.hide();
        this.fylist=response;
        this.fydata=[''];
        response.forEach(role => {
          this.fydata.push(role.FinancialYear);
        });
        
        this.fyYear=this.fy;
        // this.serverAPIService.getCurrentFy()
        // .subscribe(
        //   (response : any) => {
        //     this.fyYear=response;
        //   },
        //   (error) => {
        //     //this.spinnerService.hide();
        //     //this.toaster.error("Something went wrong!");
        //   }
        // )
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error("Something went wrong!");
      }
    )
  }

  getServiceById(id : number){
    this.spinnerService.show();
    this.serverAPIService.getServiceById(id)
    .subscribe(
      (data:Service) => {
        this.serviceName=data.ServiceName;
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error('Something went wrong!');
      }
    );
  }

  getClientById(id : number){
    this.spinnerService.show();
    this.serverAPIService.getClientById(id)
    .subscribe(
      (data:Client) => {
        this.client=data;
        this.f.txtGSTIN.setValue(this.client.GSTIN);
        this.f.ddlGSTRegType.setValue(this.client.GSTRegType);
        this.f.txtGSTUserName.setValue(this.client.GSTUserName);
        this.f.txtGSTRegDate.setValue(this.client.GSTRegDateString);
        this.getFirms();
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error('Something went wrong!');
      }
    );
  }

  getFirms(){
    //this.spinnerService.show();
    this.serverAPIService.getFirms()
    .subscribe(
      (response : Firm[]) => {
        this.firms = [{ id: 0, text: ''}];
        response.forEach(element => {
          this.firms.push({id:element.FirmID,text:element.FirmName});
         });
         this.firms.shift();
       // this.spinnerService.hide();
       this.getAssistant();
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error("Something went wrong!");
      }
    )
  }

  getAssistant(){
    //this.spinnerService.show();
    this.serverAPIService.getAssistants()
    .subscribe(
      (response : UserProfile[]) => {
        this.assitants = [{ id: 0, text: ''}];
        response.forEach(element => {
          this.assitants.push({id:element.AppUserID,text:element.AppUserName});
         });
         this.assitants.shift();
        this.spinnerService.hide();
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error("Something went wrong!");
      }
    )
  }

  onSubmit(form: NgForm){
    this.spinnerService.show();
    let t = new Work();

   if(this.f.ddlAssistant.value !=undefined && this.f.ddlAssistant.value!=''){
      //t.AppUserID= parseInt( this.f.ddlAssistant.value);
        t.AssistantMap=[];
      this.f.ddlAssistant.value.forEach(element => {
        t.AssistantMap.push({AssistantMapID:0, AssistantID:element,WorkID:0});
       });
    }
    t.BillingType=this.f.ddlBillingTp.value;
    t.ClientID=this.client.ClientID;
    t.Completed=false;
    t.Fee=this.f.txtFee.value;
    t.FileNo=this.f.txtRec.value;
    t.FinancialYear=this.f.Fynm.value;
    t.FirmID=this.f.ddlFirm.value;
    t.ServiceID=this.serviceId;
    //this.spinnerService.show();

    t.GSTIN=this.f.txtGSTIN.value;
    t.GSTRegDateString=this.f.txtGSTRegDate.value;
    t.GSTRegType=this.f.ddlGSTRegType.value;
    t.GSTUserName=this.f.txtGSTUserName.value;

  if(this.serviceId==2 || this.serviceId==18){
    this.serverAPIService.createGSTMonthlyTask(t)
    .subscribe(
      (data: any) => {
        this.spinnerService.hide();
        this.toaster.success("Service added successfully!");

        this.router.navigate(['/service/client-service-list/'+this.clientId]);
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error(error._body);
      });
    }
    else if(this.serviceId==3){
      this.serverAPIService.createGSTQuarterlyTask(t)
      .subscribe(
        (data: any) => {
          this.spinnerService.hide();
          this.toaster.success("Service added successfully!");

          this.router.navigate(['/service/client-service-list/'+this.clientId]);
        },
        (error) => {
          this.spinnerService.hide();
          this.toaster.error(error._body);
        });
    }
    else if(this.serviceId==14){
      this.serverAPIService.createGSTComposotionTask(t)
      .subscribe(
        (data: any) => {
          this.spinnerService.hide();
          this.toaster.success("Service added successfully!");

          this.router.navigate(['/service/client-service-list/'+this.clientId]);
        },
        (error) => {
          this.spinnerService.hide();
          this.toaster.error(error._body);
        });
    }
    else {
      this.serverAPIService.createGSTAnnualService(t)
      .subscribe(
        (data: any) => {
          this.spinnerService.hide();
          this.toaster.success("Service added successfully!");

          this.router.navigate(['/service/client-service-list/'+this.clientId]);
        },
        (error) => {
          this.spinnerService.hide();
          this.toaster.error(error._body);
        });
    }
  }

  next(no:number){
    if(no==1){
      if(!this.f.txtGSTIN.valid || !this.f.txtGSTRegDate.valid || !this.f.ddlGSTRegType.valid 
        || !this.f.txtGSTUserName.valid){
        this.toaster.warning("Enter valid inputs");
        return;
      }
    }
    else if(no==2){
      if(!this.f.ddlFirm.valid  ){
        this.toaster.warning("Enter valid inputs");
        return;
      }
    }
    this.stepper.next();
  }

}
