import { Component, OnInit, AfterViewInit, ViewChild, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { NgForm, FormBuilder, FormGroup, Validators, FormControl, MaxLengthValidator } from '@angular/forms';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { ServerAPIService } from 'src/app/serverapi.service';
import { SpinnerService } from 'src/app/spinner.service';
import { ToastrService } from 'ngx-toastr';
import { UserProfile } from 'src/app/user-profile/user-profile.model';
import { PlanDetails } from 'src/app/plan.model';

@Component({
  selector: 'app-assistant-update',
  templateUrl: './assistant-update.component.html',
  styleUrls: ['./assistant-update.component.css']
})
export class AssistantUpdateComponent implements OnInit,AfterViewInit,AfterContentChecked {

  @ViewChild('f',{static : true}) formRef : NgForm;
  isEditMode = false;
  accountID : number = 0;
  registerForm: FormGroup;
  submitted = false;
  acc:UserProfile;
  pl:PlanDetails;
  profileForm = new FormGroup({
    AppUserName: new FormControl(''),
    MobileNo: new FormControl({value:'',disabled:true}),
    Email:new FormControl(''),
    Address:new FormControl(''),
    UserRole:new FormControl(''),
    ddlUserRole:new FormControl(''),
    chkFirm:new FormControl(false),
    chkAssistant:new FormControl(false),
    chkClients:new FormControl(false),
    chkServices:new FormControl(false),
    chkInvoice:new FormControl(false),
    chkReceipts:new FormControl(false),
    chkReports:new FormControl(false),
    chkShowAllClients:new FormControl(false),
    SmsApiKey:new FormControl(''),
    SmsSenderId:new FormControl(''),
    WhatsAppSenderId:new FormControl(''),
    chkInActive:new FormControl(false)
  });
  roleArray:string[]=['Assistant','Admin'];
  remDay:number;
  showassigned:boolean=false;

  constructor(private serverAPIService:ServerAPIService,private route : ActivatedRoute,
    private router:Router, private toaster:ToastrService, private spinnerService:SpinnerService
    ,private formBuilder: FormBuilder ,private ref: ChangeDetectorRef) { }

    ngOnInit() {
      $(".form-check label,.form-radio label").append('<i class="input-helper"></i>');
    }

    ngAfterContentChecked() {
      this.ref.detectChanges();
    }

    // convenience getter for easy access to form fields
    get f() { return this.profileForm.controls; }

    ngAfterViewInit(): void{
      setTimeout(() => {
        this.spinnerService.show();
        this.route.params
        .subscribe(
          (params: Params) => {
            if(params["id"] != null){
              this.isEditMode = true;
              this.getAccountById(params["id"]);
            } else{
              this.spinnerService.hide();
            }
          }
        );
      });
    }

    getAccountById(id : number){

      this.serverAPIService.getAccountById(id)
      .subscribe(
        (data:UserProfile) => {
          this.spinnerService.hide();
          this.acc=data;
          this.accountID = data.AppUserID
          this.f.AppUserName.setValue(data.AppUserName);
          this.f. MobileNo.setValue(data.MobileNo);
          this.f.Email.setValue(data.Email);
          this.f.Address.setValue(data.Address);
          //this.f.UserRole.setValue(data.UserRole);
          this.f.ddlUserRole.setValue(data.UserRole);
          //Communication Settings
          this.f.SmsApiKey.setValue(data.SmsApiKey);
          this.f.SmsSenderId.setValue(data.SmsSenderId);
          this.f.WhatsAppSenderId.setValue(data.WhatsappSenderID);
          if(data.AppCustomerID==null){
            this.f.ddlUserRole.disable();
            this.getPlan(this.accountID);
          }
          else{
            this.f.chkFirm.setValue( data.ShowFirms);
            this.f.chkAssistant.setValue( data.ShowAssistants);
            this.f.chkClients.setValue( data.ShowClients);
            this.f.chkServices.setValue( data.ShowService);
            this.f.chkInvoice.setValue( data.ShowInvoice);
            this.f.chkReceipts.setValue( data.ShowReceipt);
            this.f.chkReports.setValue( data.ShowReports);
            if(data.ShowClients){
              this.showassigned=true;
              this.f.chkShowAllClients.setValue( data.ShowAllClientsToAssistant);
            }
            this.f.chkInActive.setValue(data.InActive);
          }
        },
        (error) => {
          this.spinnerService.hide();
          this.toaster.error('Something went wrong!');
        }
      );
    }

    checkValue(event: any){
      //console.log(event.target.checked);
      if(event.target.checked){
        this.showassigned=true;
      }
      else{
        this.showassigned=false;
      }
   }

    getPlan(id : number){

      this.serverAPIService.getPlanDetails(id)
      .subscribe(
        (data:PlanDetails) => {
          this.pl=data;
          this.remDay=parseInt(data.DaysRemaining.toString());
          this.spinnerService.hide();

        },
        (error) => {
          this.spinnerService.hide();
          this.toaster.error('Something went wrong!');
        }
      );


    }


    onSubmit(form: NgForm){

      if(this.isEditMode)
        this.update(form);
      else
        this.create(form);
    }

    create(form: NgForm){
      if(this.f.Password.value != this.f.ConfirmPassword1.value)
      {
        this.toaster.warning("Password and confirm password should be same");
        return;
      }

      this.spinnerService.show();
      this.serverAPIService.checkuserexists(this.f.UserName.value)
      .subscribe(
        (data :any) =>{
          let account = new UserProfile();

          account.AppUserName = this.f.AppUserName.value;
          account.MobileNo = this.f.MobileNo.value;
          account.Email=this.f.Email.value;
          account.Address=this.f.Address.value;
          account.UserName=this.f.MobileNo.value;
          account.UserRole=this.f.ddlUserRole.value;
          account.Password=this.f.Password.value;

          if(this.acc.AppCustomerID!=null){
            account.ShowAssistants=this.f.chkAssistant.value;
            account.ShowClients=this.f.chkClients.value;
            account.ShowFirms=this.f.chkFirm.value;
            account.ShowInvoice=this.f.chkInvoice.value;
            account.ShowReceipt=this.f.chkReceipts.value;
            account.ShowReports=this.f.chkReports.value;
            account.ShowService=this.f.chkServices.value;
            account.InActive=this.f.chkInActive.value;
          }

          //this.spinnerService.show();

          this.serverAPIService.createAssistant(account)
          .subscribe(
            (data: any) => {
              this.spinnerService.hide();
              this.toaster.success("Account created successfully!");

              this.router.navigate(['/master/assistant']);
            },
            (error) => {
              this.spinnerService.hide();
              this.toaster.error(error);
            });
        },
        (error) => {
          this.spinnerService.hide();
          if(error != undefined){
            this.toaster.error(JSON.parse(error._body).Message != undefined
              ?JSON.parse(error._body).Message:"Something went wrong!","Error");
              return;
          }
        }
      )


    }

    update(form: NgForm){

      let account = new UserProfile();

      account.AppUserID = this.accountID;
      account.AppUserName = this.f.AppUserName.value;
      account.MobileNo = this.f.MobileNo.value;
      account.Email=this.f.Email.value;
      account.Address=this.f.Address.value;
      account.UserName=this.f.MobileNo.value;
      account.UserRole=this.f.ddlUserRole.value;
      account.SmsApiKey = this.f.SmsApiKey.value;
      account.SmsSenderId = this.f.SmsSenderId.value;
      account.WhatsappSenderID = this.f.WhatsAppSenderId.value;

      if(this.acc.AppCustomerID!=null){
        account.ShowAssistants=this.f.chkAssistant.value;
        account.ShowClients=this.f.chkClients.value;
        account.ShowFirms=this.f.chkFirm.value;
        account.ShowInvoice=this.f.chkInvoice.value;
        account.ShowReceipt=this.f.chkReceipts.value;
        account.ShowReports=this.f.chkReports.value;
        account.ShowService=this.f.chkServices.value;
        if(this.showassigned){
          account.ShowAllClientsToAssistant=this.f.chkShowAllClients.value;
        }
        else{
          account.ShowAllClientsToAssistant=false;
        }
        account.InActive=this.f.chkInActive.value;
      }

      this.spinnerService.show();

      this.serverAPIService.updateAccount(account)
      .subscribe(
        (data: any) => {
          this.spinnerService.hide();
          this.toaster.success("Account updated successfully!");
          this.router.navigate(['/master/assistant']);
        },
        (error) => {
          this.spinnerService.hide();
          this.toaster.error(error);
        });
    }

}
