import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SignInComponent } from './auth/sign-in/sign-in.component';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { ServerAPIService } from './serverapi.service';
import { AuthGuardService } from './auth/auth-guard.service';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { ChangePasswordComponent } from './user-profile/change-password/change-password.component';
import { AssistantComponent } from './masters/assistant/assistant.component';
import { TooltipModule } from 'ng2-tooltip-directive';

import {DataTablesModule} from 'angular-datatables';
import { AssistantDetailsComponent } from './masters/assistant-details/assistant-details.component';
import { AssistantUpdateComponent } from './masters/assistant-update/assistant-update.component';
import { FirmListComponent } from './masters/firm-list/firm-list.component';
import { FirmDetailsComponent } from './masters/firm-details/firm-details.component';
import { ClientListComponent } from './masters/client-list/client-list.component';
import { ClientDetailsComponent } from './masters/client-details/client-details.component';
import { ServiceListComponent } from './user-service/service-list/service-list.component';
import { TaskListComponent } from './user-service/task-list/task-list.component';
import { TaskDetailsComponent } from './user-service/task-details/task-details.component';
import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CustomNgbDateParserFormatter } from './custom-ngbDateParserFormatter'
import { NgSelect2Module } from 'ng-select2';
import { ClientServiceListComponent } from './user-service/client-service-list/client-service-list.component';
import { GstregServiceDetailsComponent } from './user-service/gstreg-service-details/gstreg-service-details.component';
import { PendingTaskListComponent } from './task/pending-task-list/pending-task-list.component';
import { GsrRegDetailsComponent } from './task/gsr-reg-details/gsr-reg-details.component';
import { GstMothlyDetailsComponent } from './task/gst-mothly-details/gst-mothly-details.component';
import { AppLayoutComponent } from './_layout/app-layout/app-layout.component';
import { SigninLayoutComponent } from './_layout/signin-layout/signin-layout.component';
import { AccountingDetailsComponent } from './task/accounting-details/accounting-details.component';
import { ItDetailsComponent } from './task/it-details/it-details.component';
import { PtrcDetailsComponent } from './task/ptrc-details/ptrc-details.component';
import { TdsDetailsComponent } from './task/tds-details/tds-details.component';
import { TaskCountComponent } from './home/task-count/task-count.component';
import { UsersTaskDetailsComponent } from './home/users-task-details/users-task-details.component';
import { EditServiceComponent } from './user-service/edit-service/edit-service.component';
import { SaleBillComponent } from './sale-bill/sale-bill.component';
import { GenerateBillComponent } from './sale-bill/generate-bill/generate-bill.component';
import { BilldetailsComponent } from './sale-bill/billdetails/billdetails.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { PrintBillComponent } from './sale-bill/print-bill/print-bill.component';
import { ReceiptComponent } from './receipt/receipt.component';
import { ReceiptDetailsComponent } from './receipt/receipt-details/receipt-details.component';
import { PrintReceiptComponent } from './receipt/print-receipt/print-receipt.component';

import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { DueCalenderComponent } from './due-calender/due-calender.component';
import { InwardOutwardComponent } from './inward-outward/inward-outward.component';
import { InOutDetailsComponent } from './inward-outward/in-out-details/in-out-details.component';
import { PurchaseFormComponent } from './purchase-form/purchase-form.component';
import { PurchaseLayoutComponent } from './_layout/purchase-layout/purchase-layout.component';
import { PurchaseCheckoutComponent } from './purchase-form/purchase-checkout/purchase-checkout.component';
import { OrderFailedComponent } from './purchase-form/order-failed/order-failed.component';
import { CommunicationService } from './communation.service';
import { OrderCompletedComponent } from './purchase-form/order-completed/order-completed.component';
import { RenewLayoutComponent } from './_layout/renew-layout/renew-layout.component';
import { RenewFormComponent } from './renew-form/renew-form.component';
import { AccountLedgerComponent } from './home/account-ledger/account-ledger.component';
import { TodoLogComponent } from './home/todo-log/todo-log.component';
import { UnassignedTasksComponent } from './home/unassigned-tasks/unassigned-tasks.component';
import { CommunicationComponent } from './communication/communication.component';
import { ReceivablesComponent } from './home/receivables/receivables.component';
import { ImportClientsComponent } from './masters/import-clients/import-clients.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { PendingTasksReportComponent } from './home/pending-tasks-report/pending-tasks-report.component';
import { ClientListReportComponent } from './home/client-list-report/client-list-report.component';
import { RenewCheckoutComponent } from './renew-form/renew-checkout/renew-checkout.component';
import { OrderCompletedRenewComponent } from './renew-form/order-completed-renew/order-completed-renew.component';
import { OrderFailedRenewComponent } from './renew-form/order-failed-renew/order-failed-renew.component';

const appRoutes: Routes = [
  // { path: '', component: HomeComponent },
  //     { path: 'home', component: HomeComponent },
  //     { path: 'signin', component: SignInComponent },
  //     { path: 'account/change-password', component: ChangePasswordComponent },
  //     { path: 'master/assistant', component: AssistantComponent },
  //     { path: 'master/assistant-details', component: AssistantDetailsComponent },
  //     { path: 'master/assistant-details/:id', component: AssistantUpdateComponent },
  //     { path: 'master/firm', component: FirmListComponent },
  //     { path: 'master/firm-details', component: FirmDetailsComponent },
  //     { path: 'master/firm-details/:id', component: FirmDetailsComponent },
  //     { path: 'master/client', component: ClientListComponent },
  //     { path: 'master/client-details', component: ClientDetailsComponent },
  //     { path: 'master/client-details/:id', component: ClientDetailsComponent },
  //     { path: 'service', component: ServiceListComponent },
  //     { path: 'service/task-list/:id', component: TaskListComponent },
  //     { path: 'service/task-details/:id', component: TaskDetailsComponent },
  //     { path: 'service/client-service-list/:id', component: ClientServiceListComponent },
  //     { path: 'service/gstreg-service-details/:id/:srvid/:fy', component: GstregServiceDetailsComponent },
  //     { path: 'service/pending-task-list', component: PendingTaskListComponent },
  //     { path: 'gst-details/:id', component: GsrRegDetailsComponent },
  //     { path: 'service/gst-mothly-details/:id/:srvid/:fy', component: GstMothlyDetailsComponent },
   // App routes goes here here
   {
    path: '',
    component: AppLayoutComponent,
    children: [
      { path: '', component: HomeComponent },
      { path: 'home', component: HomeComponent },
      { path: 'privacypolicy', component: PrivacyPolicyComponent },
      { path: 'account/change-password', component: ChangePasswordComponent },
      { path: 'master/assistant', component: AssistantComponent },
      { path: 'master/assistant-details', component: AssistantDetailsComponent },
      { path: 'master/assistant-details/:id', component: AssistantUpdateComponent },
      { path: 'master/firm', component: FirmListComponent },
      { path: 'master/firm-details', component: FirmDetailsComponent },
      { path: 'master/firm-details/:id', component: FirmDetailsComponent },
      { path: 'master/client', component: ClientListComponent },
      { path: 'master/client-details', component: ClientDetailsComponent },
      { path: 'master/client-details/:id', component: ClientDetailsComponent },
      { path: 'service', component: ServiceListComponent },
      { path: 'service/task-list/:id', component: TaskListComponent },
      { path: 'service/task-details/:id', component: TaskDetailsComponent },
      { path: 'service/client-service-list/:id', component: ClientServiceListComponent },
      { path: 'service/gstreg-service-details/:id/:srvid/:fy', component: GstregServiceDetailsComponent },
      { path: 'service/pending-task-list', component: PendingTaskListComponent },
      { path: 'gst-details/:id/:taskid', component: GsrRegDetailsComponent },
      { path: 'service/gst-mothly-details/:id/:srvid/:fy', component: GstMothlyDetailsComponent },
      { path: 'service/accounting-details/:id/:srvid/:fy', component: AccountingDetailsComponent },
      { path: 'service/it-details/:id/:srvid/:fy', component: ItDetailsComponent },
      { path: 'service/ptrc-details/:id/:srvid/:fy', component: PtrcDetailsComponent },
      { path: 'service/tds-details/:id/:srvid/:fy', component: TdsDetailsComponent },
      { path: 'home/task-count/:tp', component: TaskCountComponent },
      { path: 'home/user-task-details/:id', component: UsersTaskDetailsComponent },
      { path: 'client/edit-client-service/:id', component: EditServiceComponent },
      { path: 'salebill', component: SaleBillComponent },
      { path: 'salebill/generate-bill/:id', component: GenerateBillComponent },
      { path: 'salebill/edit-bill/:id', component: BilldetailsComponent },
      { path: 'salebill/create-bill', component: BilldetailsComponent },
      { path: 'salebill/print-bill/:id', component: PrintBillComponent },
      { path: 'receipt', component: ReceiptComponent },
      { path: 'receipt/receipt-details', component: ReceiptDetailsComponent },
      { path: 'receipt/receipt-details/:id', component: ReceiptDetailsComponent },
      { path: 'receipt/print-receipt/:id', component: PrintReceiptComponent },
      { path: 'service/pending-task-list/:tp', component: PendingTaskListComponent },
      { path: 'due-calender', component: DueCalenderComponent },
      { path: 'in-out', component: InwardOutwardComponent },
      { path: 'in-out/in-out-details', component: InOutDetailsComponent },
      { path: 'in-out/in-out-details/:id', component: InOutDetailsComponent },
      { path: 'home/user-task-details', component: UsersTaskDetailsComponent },
      { path: 'home/client-ledger', component: AccountLedgerComponent },
      { path: 'home/todo-log', component: TodoLogComponent },
      { path: 'home/unassigned-tasks', component: UnassignedTasksComponent },
      { path: 'home/unassigned-tasks/:clientId', component: UnassignedTasksComponent },
      { path: 'home/user-task-details/:id/:tp', component: UsersTaskDetailsComponent },
      { path: 'communication', component: CommunicationComponent },
      { path: 'home/receivables', component: ReceivablesComponent },
      { path: 'master/import-clients', component: ImportClientsComponent },
      { path: 'home/pending-tasks-report', component: PendingTasksReportComponent },
      { path: 'home/client-list-report', component: ClientListReportComponent }
        ]
    },

   {
    path: '',
    component: SigninLayoutComponent,
    children: [
      { path: 'signin', component: SignInComponent },
      { path: 'forgot-password', component: ForgotPasswordComponent },
    ]
  },
  {
    path: '',
    component: PurchaseLayoutComponent,
    children: [
      { path: 'purchase', component: PurchaseFormComponent },
      { path: 'purchase-checkout/:pl', component: PurchaseCheckoutComponent },
      { path: 'order-failed', component: OrderFailedComponent },
      { path: 'order-completed', component: OrderCompletedComponent },
      ]
  },
  {
    path: '',
    component: RenewLayoutComponent,
    children: [
      { path: 'renew', component: RenewFormComponent },
      { path: 'renew-checkout/:pl', component: RenewCheckoutComponent },
      { path: 'order-failed-renew', component: OrderFailedRenewComponent },
      { path: 'order-completed-renew', component: OrderCompletedRenewComponent },
      ]
  },
]

@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    HomeComponent,
    UserProfileComponent,
    ChangePasswordComponent,
    AssistantComponent,
    AssistantDetailsComponent,
    AssistantUpdateComponent,
    FirmListComponent,
    FirmDetailsComponent,
    ClientListComponent,
    ClientDetailsComponent,
    ServiceListComponent,
    TaskListComponent,
    TaskDetailsComponent,
    ClientServiceListComponent,
    GstregServiceDetailsComponent,
    PendingTaskListComponent,
    GsrRegDetailsComponent,
    GstMothlyDetailsComponent,
    AppLayoutComponent,
    SigninLayoutComponent,
    AccountingDetailsComponent,
    ItDetailsComponent,
    PtrcDetailsComponent,
    TdsDetailsComponent,
    TaskCountComponent,
    UsersTaskDetailsComponent,
    EditServiceComponent,
    SaleBillComponent,
    GenerateBillComponent,
    BilldetailsComponent,
    PrivacyPolicyComponent,
    PrintBillComponent,
    ReceiptComponent,
    ReceiptDetailsComponent,
    PrintReceiptComponent,
    DueCalenderComponent,
    InwardOutwardComponent,
    InOutDetailsComponent,
    PurchaseFormComponent,
    PurchaseLayoutComponent,
    PurchaseCheckoutComponent,
    OrderFailedComponent,
    OrderCompletedComponent,
    RenewLayoutComponent,
    RenewFormComponent,
    AccountLedgerComponent,
    TodoLogComponent,
    UnassignedTasksComponent,
    CommunicationComponent,
    ReceivablesComponent,
    ImportClientsComponent,
    ForgotPasswordComponent,
    PendingTasksReportComponent,
    ClientListReportComponent,
    RenewCheckoutComponent,
    OrderCompletedRenewComponent,
    OrderFailedRenewComponent,
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpModule,
    HttpClientModule,
    DataTablesModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    NgbModule,
    NgSelect2Module,
    TooltipModule,
    RouterModule.forRoot(appRoutes),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-center',
      timeOut: 2000,
      maxOpened: 1
    }),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    // Ng4LoadingSpinnerModule.forRoot(),
  ],
  providers: [ServerAPIService,AuthGuardService,CommunicationService],
  bootstrap: [AppComponent]
})
export class AppModule { }
