import { PlanDetails } from '../plan.model';

export class UserProfile{
    public AppUserID: number;
    public UserName: string;
    public AppUserName: string;
    public MobileNo: string;
    public Address: string;
    public Email: string;
    public Branch: string;
    public AppCustomerID: number;
    public LastName: string;
    public UserRole: string;
    public SendDataReceivedAutoAlert: boolean;
    public SenderMailID: string;
    public MailServer: string;
    public PortNo:string;
    public MailPassword:string;
    public UniqueID:string;
    public Password:string;
    public AdminName:string;
    public expDays:number;
    public showPurchase:boolean;
    public SmsSenderId:string;
    public SmsApiKey:string;
    public WhatsappSenderID:string;

    public ShowFirms:boolean;
    public ShowAssistants:boolean;
    public ShowClients:boolean;
    public ShowService:boolean;
    public ShowInvoice:boolean;
    public ShowReceipt:boolean;
    public ShowReports:boolean;
    public ShowAllClientsToAssistant:boolean;
    public InActive:boolean;
    constructor(){}
}
