import { Component, OnInit } from '@angular/core';
import { SpinnerService } from 'src/app/spinner.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-signin-layout',
  templateUrl: './signin-layout.component.html',
  styleUrls: ['./signin-layout.component.css']
})
export class SigninLayoutComponent implements OnInit {
  spinnerTemplate: string = `<div class="mdl-spinner mdl-js-spinner is-active"></div>`;
  isSpinnerVisible = false;
  subSpinner : Subscription;

  constructor( private spinnerService : SpinnerService,) { }

  ngOnInit(): void {
    this.subSpinner = this.spinnerService.getSpinnerState
    .subscribe((display : boolean) => {
      if (display){
        this.isSpinnerVisible = true;
      }else{
        this.isSpinnerVisible = false;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subSpinner != null){
      this.subSpinner.unsubscribe();
    }
  }

}
