import { Component, OnInit, AfterViewInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ServerAPIService } from '../../serverapi.service';
import { ActivatedRoute,Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from 'src/app/spinner.service';
// import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
declare var componentHandler: any;

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit {

  constructor(private serviceAPIService:ServerAPIService, private route:ActivatedRoute,
    private router:Router,private toastr: ToastrService,private spinnerService : SpinnerService
    // ,private spinnerService:Ng4LoadingSpinnerService
    ) { }

  ngOnInit() {
    //componentHandler.upgradeAllRegistered();
  }

  ngAfterViewInit(){
    //componentHandler.upgradeAllRegistered();
  }

  onSubmit(form: NgForm){

    const email = form.value.username;
    const password = form.value.userpass;

    //this.spinnerService.show();


    const obj:any = "grant_type="+encodeURIComponent("password")
                    +"&username="+encodeURIComponent(email)
                    +"&password="+encodeURIComponent(password)
                    //+"&InActive="+encodeURIComponent(0)
                    // +"&userRole="+encodeURIComponent("Customer")
                    // +"&userFullName="+encodeURIComponent(email)
                    // +"&userId="+encodeURIComponent(email)
                    ;

    this.spinnerService.show();
    this.serviceAPIService.login(obj,email)
    .subscribe(
      (data: any) => {
       this.spinnerService.hide();

        if(data.status == 200){
          let redirect = this.route.snapshot.queryParams['redirect'];
          if(redirect !== undefined)
          {
              this.router.navigate([redirect]);
          }
          else
            this.router.navigate(['/home']);
        }else{
          this.toastr.error("Login failed!","Error");
        }
      },
      (error) => {
       this.spinnerService.hide();
        if(error._body != undefined){
            this.toastr.error(JSON.parse(error._body).error_description != undefined
              ?JSON.parse(error._body).error_description:"Something went wrong!","Error");
        }
        else
          this.toastr.error("Something went wrong!","Error");
      }
    );

  }

}
