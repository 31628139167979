import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ServerAPIService } from '../serverapi.service';
import { Router } from '@angular/router';
import { SpinnerService } from '../spinner.service';
import { Dashboard, UserwiseStatusModel } from './dashboard.model';
import { UserProfile } from '../user-profile/user-profile.model';
import { ToastrService } from 'ngx-toastr';
import { ToDo } from '../todo.model';
import { NgbDateAdapter, NgbDateParserFormatter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomDateAdapter, CustomDateParserFormatter } from '../custom-ngbDateParserFormatter';
import { DatePipe } from '@angular/common'


@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomDateAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    DatePipe,
  ],
})
export class HomeComponent implements OnInit, AfterViewInit {
  currentFyYear: string;
  ds: Dashboard;
  userProgress: UserwiseStatusModel[];
  users: object[];
  alltodo: ToDo[];
  mytodo: ToDo[];
  todoDate: string = "";
  todoDesc: string = "";
  todoAssistantId: number = 0;
  userId: number = 0;
  todoType: string = "My";
  addTask: number = 0;
  assid: number = 0;
  tempToDoDetails: string = "";
  commentText: string = "";
  temptodoid: number = 0;
  adminName: string = "";
  expDays: number = 0;
  ispurchaseNotice: boolean = false;
  usr: UserProfile;
  inActive: boolean;
  isRenewBool:boolean = false;
  ds1: UserProfile;
  any: Boolean;
  constructor(
    public serverAPIService: ServerAPIService,
    private router: Router,
    private spinnerService: SpinnerService,
    private toaster: ToastrService,
    private ref: ChangeDetectorRef,
    private modalService: NgbModal,
    public datepipe: DatePipe
  ) {}

  getUser() {
    this.spinnerService.show();
    this.serverAPIService.getAdminUser().subscribe(
      (response: UserProfile) => {
        this.usr = response;
        this.adminName = this.usr.AppUserName;
        this.expDays = parseInt(this.usr.expDays.toString());
        this.ispurchaseNotice = this.usr.showPurchase;
        this.spinnerService.hide();
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error("Something went wrong!");
      }
    );
  }

  switchData(id1: number) {
    if (id1 == 0) {
      this.todoType = "My";
      this.mytodo = [];
      this.mytodo = this.alltodo.filter((x) => x.AssignedUserID == this.userId);
      this.addTask = 0;
    } else if (id1 == 1) {
      this.todoType = "All";
      this.mytodo = [];
      this.mytodo = this.alltodo.filter(
        (x) => x.AppUserID == this.userId && x.AssignedUserID != this.userId
      );
      this.addTask = 0;
    } else {
      this.addTask = 1;
    }
    this.ref.detectChanges();
  }

  setAssistant(data: { value: string }): void {
    this.todoAssistantId = Number(data);
    // alert(this.todoAssistantId);
  }

  descChange(val: string) {
    this.todoDesc = val;
    // alert(this.todoAssistantId);
  }

  dateChange(val: string) {
    this.todoDate = val;
    // alert(this.todoAssistantId);
  }

  deleteTodo(id: number) {
    this.spinnerService.show();
    this.serverAPIService.deleteToDo(id).subscribe(
      (data: any) => {
        this.spinnerService.hide();
        this.toaster.success("Deleted successfully!");
        this.getToDos();
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error(error._body);
      }
    );
  }

  updateTodo(event, Id: number) {
    let t = new ToDo();
    t.ToDoID = Id;
    t.Completed = event.target.checked;
    this.spinnerService.show();
    this.serverAPIService.updateToDo(t).subscribe(
      (data: any) => {
        this.spinnerService.hide();
        this.toaster.success("Updated successfully!");
        this.getToDos();
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error(error._body);
      }
    );
  }

  addToDo() {
    if (this.todoAssistantId == 0) {
      this.toaster.warning("Select User");
      return;
    }
    if (this.todoDate == "" || this.todoDesc == "") {
      this.toaster.warning("Select Date and enter details");
      return;
    }

    let t = new ToDo();
    t.AssignedUserID = this.todoAssistantId;
    t.Description = this.todoDesc;
    t.ToDoDatestr = this.todoDate;

    this.spinnerService.show();
    this.serverAPIService.createToDo(t).subscribe(
      (data: any) => {
        this.spinnerService.hide();
        this.toaster.success("Added successfully!");
        this.todoAssistantId = this.assid;
        this.todoDesc = "";
        this.todoDate = "";
        this.addTask = 0;
        this.getToDos();
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error(error._body);
      }
    );
  }

  ngAfterViewInit() {}

  ngOnInit(): void {
    // $(".form-check label,.form-radio label").append('<i class="input-helper"></i>');
    this.chkIsRenew();
    var todoListItem = $(".todo-list");
    var todoListInput = $(".todo-list-input");
    this.userId = parseInt(this.serverAPIService.getLoggedInUserId());
    // this.inActive=Boolean(this.serverAPIService.isActive()).valueOf();
    // if(this.inActive==false)
    // {
    //   this.serverAPIService.logout();
    //   this.router.navigate(['/signin']);
    //   //this.toastr.info("Logged Out!");
    // }
    this.serverAPIService.getInActive().subscribe(
      (response: any) => {
        if (response._body == "true") {
          this.serverAPIService.logout();
          this.router.navigate(["/signin"]);
          //return response;
        }
      },
      (error) => {
        //this.spinnerService.hide();
        //this.toaster.error("Something went wrong!");
      }
    );

    $(".btn-group > .btn").click(function () {
      $(this).addClass("active").siblings().removeClass("active");
    });

    todoListItem.on("change", ".checkbox", function () {
      if ($(this).attr("checked")) {
        $(this).removeAttr("checked");
      } else {
        $(this).attr("checked", "checked");
      }

      $(this).closest("li").toggleClass("completed");
    });
    if (!this.serverAPIService.isAuthenticated()) {
      this.router.navigate(["/signin"]);
    } else {
      this.serverAPIService.getCurrentFy().subscribe(
        (response: any) => {
          this.currentFyYear = response;
        },
        (error) => {
          //this.spinnerService.hide();
          //this.toaster.error("Something went wrong!");
        }
      );

      this.serverAPIService.getDashboardCnt().subscribe(
        (response: Dashboard) => {
          this.ds = response;
        },
        (error) => {
          //this.spinnerService.hide();
          //this.toaster.error("Something went wrong!");
        }
      );
      this.getUserwiseProgress();
      this.drawLineChart();
      this.getAssistant();
      this.getToDos();
      this.getUser();
    }
  }

  getToDos() {
    //this.spinnerService.show();
    this.serverAPIService.getTodaysAssignedToDoes().subscribe(
      (response: ToDo[]) => {
        this.alltodo = response;
        if (this.todoType == "My")
          this.mytodo = this.alltodo.filter(
            (x) => x.AssignedUserID == this.userId
          );
        else
          this.mytodo = this.alltodo.filter(
            (x) => x.AppUserID == this.userId && x.AssignedUserID != this.userId
          );

        //this.spinnerService.hide();
        this.ref.detectChanges();
      },
      (error) => {
        //this.spinnerService.hide();
        this.toaster.error("Something went wrong!");
      }
    );
  }

  getAssistant() {
    //this.spinnerService.show();
    this.serverAPIService.getAllUsers().subscribe(
      (response: UserProfile[]) => {
        this.users = [{ id: 0, text: "" }];
        response.forEach((element) => {
          this.users.push({ id: element.AppUserID, text: element.AppUserName });
        });
        this.users.shift();
        this.todoAssistantId = response[0].AppUserID;
        this.assid = response[0].AppUserID;
        this.spinnerService.hide();
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error("Something went wrong!");
      }
    );
  }

  getUserwiseProgress() {
    //this.spinnerService.show();
    this.serverAPIService.getUserwiseProgress().subscribe(
      (response: UserwiseStatusModel[]) => {
        this.spinnerService.hide();
        this.userProgress = response;
      },
      (error) => {
        // this.spinnerService.hide();
        // this.toaster.error("Something went wrong!");
      }
    );
  }

  drawLineChart() {}

  addcomment(content, id: number) {
    //this.spinnerService.show();
    this.temptodoid = 0;
    this.tempToDoDetails = "";
    this.commentText = "";

    this.temptodoid = id;
    let t = this.alltodo.filter((o) => o.ToDoID == id)[0];
    let dt = this.datepipe.transform(t.ToDoDate, "dd/MM/yyyy");
    if (t.Comments != undefined && t.Comments != "")
      this.commentText = t.Comments;
    this.tempToDoDetails =
      t.Description +
      " (" +
      dt +
      (t.AssignedUserID == this.userId ? ", By-" : ", To- ") +
      t.AssignedUserName +
      ")";

    this.modalService.open(content, {
      ariaLabelledBy: "modal-basic-title",
      size: "sm",
    });
  }

  updateComment() {
    this.spinnerService.show();
    let t = new ToDo();
    t.ToDoID = this.temptodoid;
    t.Comments = this.commentText;
    this.serverAPIService.addToDoComment(t).subscribe(
      (data: any) => {
        this.spinnerService.hide();
        this.toaster.success("Comment added successfully!");
        this.temptodoid = 0;
        this.tempToDoDetails = "";
        this.commentText = "";
        this.modalService.dismissAll();

        this.getToDos();
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error(error._body);
      }
    );
  }

  chkIsRenew() {
    this.serverAPIService.isRenew().subscribe(
      (data: any) => {
        this.isRenewBool = data;
      },
      (error) => {
        this.isRenewBool = false;
        this.spinnerService.hide();
      }
    );
  }
}
