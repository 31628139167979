import { AssistantMapModel } from './assistantmap.model';

export class Work{
    public  WorkID :number;
    public  ClientID :number;
    public AssignmentDate ?:Date;
    public  AppUserID?: number;
    public ServiceID:number;
    public Fee:number;
    public Completed:boolean;
    public CompletionDate?:Date;
    public CustomerID:number;
    public AssociateID?:number;
    public FirmID?:number;
    public BillingType:string;
    public FinancialYear:string;
    public FileNo:string;
    public DueDate?:Date;
    public DueDateStr:string;
    public WorkPeriod:string;
    public  GSTIN :string;
    public  GSTRegType :string;
    public  GSTUserName :string;
    public GSTRegDateString:string;
    public EntityName:string;
    public CreationDate?:Date;
    public CreationDateStr:string;

    public  PAN :string;
    public  ITReturnUserName :string;
    public  ITReturnPassword :string;
    public  PTEC :string;
    public  TAN :string;
    public  PTRC :string;
    public  ServiceName :string;

    public IsDiscontinue:boolean;
    public  DiscontinueFromStr :string;
    public DiscontinueFrom:Date;
    public DiscontinueReason:string;

    public AssistantMap:AssistantMapModel[];
    public isSelected:boolean;

    constructor(){}
}
