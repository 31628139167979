import { Component, OnInit, AfterViewInit, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ServerAPIService } from 'src/app/serverapi.service';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from 'src/app/spinner.service';
import { Subject } from 'rxjs';
import { Task } from '../task.model';
declare let $: any;

@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.css']
})
export class TaskListComponent implements OnInit, AfterContentChecked {
  tasks:Task[];
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  serviceName:string="";

  constructor(private serverAPIService:ServerAPIService,private route : ActivatedRoute,
    private router:Router, private toaster:ToastrService, private spinnerService:SpinnerService
    ,private ref: ChangeDetectorRef) {
      this.dtOptions = {
        // pagingType: 'full_numbers',
        // pageLength: 10,
        processing: true,
        paging:false,
      }
  }
  ngAfterContentChecked() {
    this.ref.detectChanges();
}


  ngOnInit() {

    setTimeout(() => {
      this.spinnerService.show();
      this.route.params
      .subscribe(
        (params: Params) => {
          if(params["id"] != null){
            this.getTaskForUser(params["id"]);
          } else{
            this.spinnerService.hide();
          }
        }
      );
    });
  }

  getTaskForUser(id:number){
    this.spinnerService.show();
    this.serverAPIService.getServiceTaskForUser(id)
    .subscribe(
      (response : Task[]) => {
        this.spinnerService.hide();
        this.tasks=response;
        this.serviceName=this.tasks[0].ServiceName;
        this.dtTrigger.next();
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error("Something went wrong!");
      }
    )
  }

}
