import { Component, OnInit, AfterViewInit, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CustomDateAdapter, CustomDateParserFormatter } from 'src/app/custom-ngbDateParserFormatter';
import { Client } from 'src/app/masters/client.model';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ServerAPIService } from 'src/app/serverapi.service';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from 'src/app/spinner.service';
import { FormGroup, FormControl, NgForm } from '@angular/forms';
import { Firm } from 'src/app/masters/firm.model';
import { Receipt } from '../receipt.model';
declare let $: any;

@Component({
  selector: 'app-receipt-details',
  templateUrl: './receipt-details.component.html',
  styleUrls: ['./receipt-details.component.css'],
  providers: [{provide: NgbDateAdapter, useClass: CustomDateAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}]
})
export class ReceiptDetailsComponent implements OnInit,AfterViewInit,AfterContentChecked {
  clientId:number;
  receiptId:number;
  clients:Client[];
  firms:object[];
  cl:object[];
  actFirm:Firm[];
  receipt:Receipt;
  profileForm = new FormGroup({
    ddlFirm: new FormControl(''),
    txtReceiptDate:new FormControl(''),
    txtAmount:new FormControl(''),
    ddlClient:new FormControl(''),
    ddlReceiptType:new FormControl(''),
    txtInstruDt:new FormControl(''),
    txtBankName:new FormControl(''),
    txtInstrNo:new FormControl(''),
    txtDescription:new FormControl(''),
    txtClientName:new FormControl('')
  });
  isupdate:boolean=false;
  currentDate = new Date();
  billingtp:string[]=['Cash','NEFT','Cheque'];
  isCheque:boolean=false;
  balance:string='';

  constructor(private serverAPIService:ServerAPIService,private route : ActivatedRoute,
    private router:Router, private toaster:ToastrService, private spinnerService:SpinnerService
    ,private ref: ChangeDetectorRef) {
  }

  ngAfterViewInit(){
  }

  // convenience getter for easy access to form fields
  get f() { return this.profileForm.controls; }

  public onClientSelected(event) {
    if(event.target.value!=''){
      //this.f.ddlClient.setValue(event.target.value);
      this.serverAPIService.getBalance(this.f.ddlClient.value)
        .subscribe(
          (data:number) => {
            if(data<0)
              this.balance=((-1)*data).toString()+' Dr';
            else
              this.balance=(data).toString()+' Cr';
            this.spinnerService.hide();
          },
          (error) => {
            this.spinnerService.hide();
            this.toaster.error('Something went wrong!');
          }
        );
    }
  }

  ngAfterContentChecked() {
    this.ref.detectChanges();
  }

  public onTypeSelected(event) {
   // this.f.ddlReceiptType.setValue(event.target.value);
    if(event.target.value=='Cheque')
      this.isCheque=true;
    else
      this.isCheque=false;
  }

  ngOnInit() {
    $('.js-example-basic-single').select2({width: 'resolve'});
    let day:number=this.currentDate.getDate();
    let month:number=this.currentDate.getMonth();
    let year:number=this.currentDate.getFullYear();
    if(month==12){
      month=0;
      year=year+1;
    }
    this.f.txtReceiptDate.setValue(((day).toString().length==1 ?'0'+(day).toString() :(day).toString())+'/'+((month+1).toString().length==1 ?'0'+(month+1).toString() :(month+1).toString())
      +'/'+year.toString());
    this.f.txtInstruDt.setValue(((day).toString().length==1 ?'0'+(day).toString() :(day).toString())+'/'+((month+1).toString().length==1 ?'0'+(month+1).toString() :(month+1).toString())
      +'/'+year.toString());

    this.f.ddlReceiptType.setValue("Cash");
      setTimeout(() => {
        this.spinnerService.show();
        this.route.params
        .subscribe(
          (params: Params) => {
            if(params["id"] != null){
              this.receiptId=parseInt( params["id"]);
              this.getFirms();
              this.isupdate=true;
            } else{
              this.getClientById();
              this.getFirms();
              this.spinnerService.hide();
            }
          }
        );
      });
  }

  getBillById(id : number){
    this.spinnerService.show();
    this.serverAPIService.getreceiptById(id)
    .subscribe(
      (data:Receipt) => {
        this.receipt=data;
        this.spinnerService.hide();
        this.clientId=this.receipt.ClientID;
        this.f.ddlFirm.setValue(this.receipt.FirmID);
        this.f.ddlReceiptType.setValue(this.receipt.ReceiptType);
        this.receiptId=id;
        this.f.txtReceiptDate.setValue(this.receipt.ReceiptDateStr);
        this.f.ddlClient.setValue(this.clientId);
        this.f.txtDescription.setValue(this.receipt.Description);
        this.f.txtClientName.setValue(this.receipt.ClientName);
        this.f.txtAmount.setValue(this.receipt.Amount);
        if(this.receipt.ReceiptType=='Cheque'){
          this.isCheque=true;
          this.f.txtInstrNo.setValue(this.receipt.ChequeNo);
          this.f.txtInstruDt.setValue(this.receipt.ChequeDateStr);
          this.f.txtBankName.setValue(this.receipt.BankDetails);
        }

        this.serverAPIService.getBalance(this.f.ddlClient.value)
          .subscribe(
            (data:number) => {
              if(data<0)
                this.balance=((-1)*data).toString()+' Dr';
              else
                this.balance=(data).toString()+' Cr';
              this.spinnerService.hide();
            },
            (error) => {
              this.spinnerService.hide();
              this.toaster.error('Something went wrong!');
            }
          );
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error('Something went wrong!');
      }
    );
  }

  getClientById(){
    this.spinnerService.show();
    this.serverAPIService.getClients()
    .subscribe(
      (data:Client[]) => {
        this.clients=data;
        this.cl = [{ id: 0, text: ''}];
        data.forEach(element => {
          this.cl.push({id:element.ClientID,text:element.EntityName});
         });
         this.cl.shift();
         if(this.isupdate)
          this.getBillById(this.receiptId);
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error('Something went wrong!');
      }
    );
  }

  getFirms(){
   // this.spinnerService.show();
    this.serverAPIService.getFirms()
    .subscribe(
      (response:Firm[]) => {
        this.actFirm=response;
        this.firms = [{ id: 0, text: ''}];
        response.forEach(element => {
          this.firms.push({id:element.FirmID,text:element.FirmName});
         });
         this.firms.shift();
      //  this.spinnerService.hide();
         if(this.isupdate)
            this.getClientById();
          else
            this.spinnerService.hide();
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error('Something went wrong!');
      }
    );
  }

  onSubmit(form: NgForm){
    if(this.f.ddlReceiptType.value=='Cheque' && (this.f.txtInstruDt.value=='' ||
    this.f.txtInstrNo.value=='' || this.f.txtBankName.value=='')){
      this.toaster.warning("Enter instrument details");
      return;
    }

    this.spinnerService.show();

    let sb=new Receipt();
    sb.ClientID=this.f.ddlClient.value;
    sb.Description=this.f.txtDescription.value;
    sb.ClientName=this.f.txtClientName.value;
    sb.FirmID=this.f.ddlFirm.value;
    sb.ReceiptDateStr=this.f.txtReceiptDate.value;
    sb.ReceiptType=this.f.ddlReceiptType.value;
    sb.Amount=this.f.txtAmount.value;
    if(this.f.ddlReceiptType.value=='Cheque'){
      sb.BankDetails=this.f.txtBankName.value;
      sb.ChequeNo=this.f.txtInstrNo.value;
      sb.ChequeDateStr=this.f.txtInstruDt.value;
    }

    if(this.isupdate)
    {
      sb.ReceiptID=this.receipt.ReceiptID;
      this.spinnerService.show();
        this.serverAPIService.updateReceipt(sb)
      .subscribe(
        (data: any) => {
          this.spinnerService.hide();
          this.toaster.success("Receipt Updated successfully!");

          this.router.navigate(['/receipt']);
        },
        (error) => {
          this.spinnerService.hide();
          this.toaster.error(error._body);
        });
    }
    else{
      this.spinnerService.show();
        this.serverAPIService.createReceipt(sb)
      .subscribe(
        (data: any) => {
          this.spinnerService.hide();
          this.toaster.success("Receipt Created successfully!");
          window.open(this.serverAPIService.webUrl+ "receipt/print-receipt/"+data._body.toString(), "_blank");
          this.router.navigate(['/receipt']);
        },
        (error) => {
          this.spinnerService.hide();
          this.toaster.error(error._body);
        });
    }
  }

}
