import { AssistantMapModel } from './assistantmap.model';

export class WorkDetails{
    public  WorkDetailsID :number;
    public WorkID:number;
    public ClientID:number;
    public TaskID:number;
    public DueDate?:Date;
    public WorkStatus:string;
    public WorkPeriod:string;
    public ClientName:string;
    public OwnersMobile:string;
    public ContactPersonMobile:string;
    public TaskName:string;
    public StatusDetails:string;
    public FileNo:string;
    public ServiceType:string;
    public ClientFileNo:string;
    public Comment:string;

    public  VerificationType:string;
    public  VerificationDate:Date;
    public  VerificationDateStr:string;

    public AssistantMap:AssistantMapModel[];

    public EntityName: string;

    public ServiceName: string;

    public DueDateStr: string;

    public AssistantNameList: string;
    constructor(){}





}

export class TaskList{
    public TaskName:string;
    public ServiceType:string;
    constructor(){}
}
