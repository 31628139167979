import { Component, OnInit, AfterViewInit, ChangeDetectorRef, AfterContentChecked, ViewChild } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { ServerAPIService } from 'src/app/serverapi.service';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from 'src/app/spinner.service';
import { Subject } from 'rxjs';
import { NgbModal, NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl } from '@angular/forms';
import { CustomDateAdapter, CustomDateParserFormatter } from 'src/app/custom-ngbDateParserFormatter';
import { SaleBill } from './salebill.model';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-sale-bill',
  templateUrl: './sale-bill.component.html',
  styleUrls: ['./sale-bill.component.css'],
  providers: [{provide: NgbDateAdapter, useClass: CustomDateAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}]
})
export class SaleBillComponent implements OnInit,AfterViewInit,AfterContentChecked {
  salebill:SaleBill[];
  salebillact:SaleBill[];
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  profileForm = new FormGroup({
    txtFromDate: new FormControl(''),
    txtToDate:new FormControl('')
  });
  currentDate = new Date();
  status:string="Pending";
  tempBillId:number;
  tempServiceName:string='';
  tempEntityName:string='';

  get f() { return this.profileForm.controls; }

  constructor(public serverAPIService:ServerAPIService,
    private router:Router, private toaster:ToastrService, private spinnerService:SpinnerService
    ,private route : ActivatedRoute,private modalService: NgbModal,private ref: ChangeDetectorRef) {
      this.dtOptions = {
        processing: true,
        paging:false,
        order:[],
        destroy: true,
        responsive: true,
      }
  }

  deleteconfirm(content,name:string,entityName:string, saleBillId:number){
    this.tempBillId=0;
    this.tempEntityName='';
    this.tempServiceName='';

    this.tempBillId=saleBillId;
    this.tempServiceName=name;
    this.tempEntityName=entityName;
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'});
  }

  deleteBill(){
    this.serverAPIService.deleteBill(this.tempBillId)
        .subscribe(
          (response : any) => {
            this.toaster.success("bill deleted successfully!");
            this.modalService.dismissAll();
            $('#example').DataTable().destroy();
            this.salebill=[];
             this.salebillact=[];
            this.dtTrigger.next();
             this.getData();
          },
          (error) => {
            this.spinnerService.hide();
            this.toaster.error("Something went wrong!");
          }
        )
  }

  switchData(id1:number){
    $('#example').DataTable().destroy();
    this.salebill=[];
    this.dtTrigger.next();

    if(id1==1 )
      this.status="Pending";
    else if(id1==2 )
      this.status="Generated";
    setTimeout(() => {
         this.salebill=this.salebillact.filter(x=>x.BillStatus==this.status);
    this.dtTrigger.next();
    },0)


  }

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
   }

  ngAfterContentChecked() {
    this.ref.detectChanges();
}

  viewRecord(){
   $('#example').DataTable().destroy();
     this.salebill=[];
      this.salebillact=[];
     this.dtTrigger.next();
      this.getData();


   }

   getData(){
    this.spinnerService.show();
    this.serverAPIService.getbills(this.f.txtFromDate.value,this.f.txtToDate.value)
    .subscribe(
      (response : SaleBill[]) => {
        this.spinnerService.hide();
        //this.salebillact=[];
        this.salebillact=response;
        this.salebill=[];
        this.dtTrigger.next();
        this.salebill=this.salebillact.filter(x=>x.BillStatus==this.status);
        this.dtTrigger.next();
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error("Something went wrong!");
      }
    )
  }

  ngOnInit() {
    $(".btn-group > .btn").click(function(){
      $(this).addClass("active").siblings().removeClass("active");
    });
    let day:number=this.currentDate.getDate();
    let month:number=this.currentDate.getMonth();
    let year:number=this.currentDate.getFullYear();
    if(month==12){
      month=0;
      year=year+1;
    }
    this.f.txtFromDate.setValue('01/'+((month+1).toString().length==1 ?'0'+(month+1).toString() :(month+1).toString())
      +'/'+year.toString());
    this.f.txtToDate.setValue((this.daysInThisMonth(year,(month+1)))+'/'+((month+1).toString().length==1
      ?'0'+(month+1).toString()
      :(month+1).toString())
      +'/'+year.toString());
      this.salebill=[];
  }

  daysInThisMonth(yr:number,mo:number) {
    var now = new Date();
    return new Date(yr, mo, 0).getDate();
  }

  ngAfterViewInit(): void{
    setTimeout(() => {
      this.getData();
    });
  }

  email(id:number,entityEmail:string): void {
    if(entityEmail == '' || entityEmail == null){
      this.toaster.warning('Unable to send bill. Email address not found for selected client.');
      return;
    }

    this.spinnerService.show();
    this.serverAPIService.emailSaleBill(id).subscribe(
      (data: any) => {
        this.spinnerService.hide();
        this.toaster.success("Bill sent successfully!");
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error("Unable to send bill. Please try again.");
      }
    );
  }

}
