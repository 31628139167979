export class InwardOutward{
    public  InwardOutwardID:number;
    public AppUserID :number;
    public  InOutDate :Date;
    public  InOutDateStr:string;
    public InOutType:string;
    public FromTo:string;
    public Details:string;
    public PlacedAt:string;
    public CreatedBy:string
    public CreationDate:Date;
    public CreationDateStr:string;
    public ClientId:number;

    constructor(){}
}
