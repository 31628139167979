import { Component, Inject, OnInit } from '@angular/core';
import { ServerAPIService } from './serverapi.service';
import { Router, ActivatedRoute } from '@angular/router';
import{ToastrService}from 'ngx-toastr';
import { SpinnerService } from 'src/app/spinner.service'
import { Subscription } from 'rxjs';
import '@angular/compiler';
declare let $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent  {
  title = 'Aareasy';
  spinnerTemplate: string = `<div class="mdl-spinner mdl-js-spinner is-active"></div>`;
  isSpinnerVisible = false;
  subSpinner : Subscription;
  currentFyYear:string;
  // constructor(
  //   public serverAPIService:ServerAPIService,private router:Router,private toastr:ToastrService){
      
  //   }
  constructor( private spinnerService : SpinnerService, public serverAPIService:ServerAPIService, private toastr: ToastrService,
    private route:ActivatedRoute,  private router:Router)
  {
  }

  ngOnInit() : void {
       
    // document.querySelector('.sidebar').addEventListener('click', function () {
    //   document.querySelector('.sidebar').classList.remove('active');
    //   this.classList.remove('active');
    // }, false);

    // this.subSpinner = this.spinnerService.getSpinnerState
    // .subscribe((display : boolean) => {
    //   if (display){
    //     this.isSpinnerVisible = true;
    //   }else{
    //     this.isSpinnerVisible = false;
    //   }
    // });

    // this.serverAPIService.getCurrentFy()
    // .subscribe(
    //   (response : any) => {
    //     this.currentFyYear=response;
    //   },
    //   (error) => {
    //     //this.spinnerService.hide();
    //     //this.toaster.error("Something went wrong!");
    //   }
    // )
  }

    onLogout(){
      // this.serverAPIService.logout();
      // this.router.navigate(['/signin']);
      // this.toastr.info("Logged Out!");
    }

    ngOnDestroy(): void {
      // if (this.subSpinner != null){
      //   this.subSpinner.unsubscribe();
      // }
    }
}
