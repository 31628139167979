export class AdminDetails{
    public AdminDetailsID:number;
    public AppUserID:number;
    public PaymentMethod: string;
    public Amount:number;
    public PaymentDate: Date;
    public CreationDate :Date;
    public CreatedBy :string;
    public PaymentID :string;
    public PaymentStatus :string;
    public RefNo :string;
    public StatusInResponse :string;
    public BankCode :string;
    public ErrorMessage :string;
    public TransactionNo :string;
    public OrderNo :string;
    public PaymentType :string;
    public RegUpTo :Date;
    public TAPORDMemberID :string;
    public DiscPer :number;
    public DiscAmount :number;
    public PlanAmount :number;

    constructor(){}
}