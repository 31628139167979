import { Component, OnInit, AfterViewInit, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CustomDateAdapter, CustomDateParserFormatter } from 'src/app/custom-ngbDateParserFormatter';
import { Client } from 'src/app/masters/client.model';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ServerAPIService } from 'src/app/serverapi.service';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from 'src/app/spinner.service';
import { FormGroup, FormControl, NgForm } from '@angular/forms';
import { SaleBill } from '../salebill.model';
import { Firm } from 'src/app/masters/firm.model';
import { Service } from 'src/app/user-service/service.model';
import { SaleBillDetails } from '../salebillDetails.model';
declare let $: any;

@Component({
  selector: 'app-generate-bill',
  templateUrl: './generate-bill.component.html',
  styleUrls: ['./generate-bill.component.css'],
  providers: [{provide: NgbDateAdapter, useClass: CustomDateAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}]
})
export class GenerateBillComponent implements OnInit,AfterViewInit,AfterContentChecked {
  clientId:number;
  saleBillId:number;
  client:Client;
  firms:object[];
  actFirm:Firm[];
  saleBill:SaleBill;
  serviceName:string;
  gstPercent:number=18;
  gstAmount:number=0;
  roundoff:number=0;
  totalAmount:number=0;
  tempSubtotal:number=0;
  subtotal:number=0;
  profileForm = new FormGroup({
    ddlFirm: new FormControl(''),
    txtBillDate:new FormControl(''),
    txtClientName:new FormControl(''),
    txtDescription:new FormControl(''),
    txtTempDesc:new FormControl(''),
    txtTempAmount:new FormControl(''),
    txtRoundOff:new FormControl(''),
    txtDiscountAmount:new FormControl('')
  });

  tempDesc:string='';
  tempAmount:number=0;
  balance:string='';

  constructor(private serverAPIService:ServerAPIService,private route : ActivatedRoute,
    private router:Router, private toaster:ToastrService, private spinnerService:SpinnerService
    ,private ref: ChangeDetectorRef) {
  }

  ngAfterViewInit(){
  }

  // convenience getter for easy access to form fields
  get f() { return this.profileForm.controls; }


  ngAfterContentChecked() {
    this.ref.detectChanges();
  }

  addDetails(){
    if(this.f.txtTempDesc.value=='' || this.f.txtTempAmount.value==''){
      this.toaster.warning("Enter description and amount");
      return;
    }

    let sb=new SaleBillDetails();
    sb.Amount=parseFloat(this.f.txtTempAmount.value);
    sb.Description=this.f.txtTempDesc.value;
    if(this.saleBill.saleBillDetails.length>0){
      let cnt=this.saleBill.saleBillDetails.sort(x=>x.SaleBillDetailsID)[this.saleBill.saleBillDetails.length-1].SaleBillDetailsID;
      sb.SaleBillDetailsID=cnt+1;
    }
    else
      sb.SaleBillDetailsID=0;

    this.saleBill.saleBillDetails.push(sb);
    this.setTotal();
    this.f.txtTempDesc.setValue('');
    this.f.txtTempAmount.setValue('');

  }

  deleteDetails(sbd:SaleBillDetails){
    let ind=this.saleBill.saleBillDetails.indexOf(sbd);
    this.saleBill.saleBillDetails.splice(ind,1);
    this.setTotal();
  }

  public onFirmSelected(event) {
    this.f.ddlFirm.setValue(event);
    this.setTotal();
  }

  ngOnInit() {
    $('.js-example-basic-single').select2({width: 'resolve'});

    setTimeout(() => {
      this.spinnerService.show();
      this.route.params
      .subscribe(
        (params: Params) => {
          if(params["id"] != null){
            this.saleBillId=parseInt( params["id"]);
            this.getFirms();

          } else{
            this.spinnerService.hide();
          }
        }
      );
   });
  }

  getBillById(id : number){
    this.spinnerService.show();
    this.serverAPIService.getbillById(id)
    .subscribe(
      (data:SaleBill) => {
        this.saleBill=data;
        //this.spinnerService.hide();
        this.clientId=this.saleBill.ClientID;
        this.f.ddlFirm.setValue(this.saleBill.FirmID);
        this.f.txtBillDate.setValue(this.saleBill.BillDateStr);
        this.f.txtClientName.setValue(this.saleBill.ClientName);
        this.f.txtDescription.setValue(this.saleBill.Description);
        this.f.txtTempAmount.setValue( this.saleBill.saleBillDetails[0].Amount);
        this.f.txtTempDesc.setValue( this.saleBill.saleBillDetails[0].Description);
        this.saleBill.saleBillDetails.shift();
        this.setTotal();
        this.getClientById(this.saleBill.ClientID);
        if(this.saleBill.ServiceID!=null)
          this.getServiceById(this.saleBill.ServiceID);
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error('Something went wrong!');
      }
    );
  }

  roundChange(){
    let tempTotal=this.subtotal+this.gstAmount;
    this.roundoff=parseFloat( this.f.txtRoundOff.value.toString());
    this.totalAmount=tempTotal+this.roundoff;
  //  this.totalAmount=Math.round((this.totalAmount + Number.EPSILON) * 100) / 100
  }

  discountChange(){
    if(this.saleBill.saleBillDetails != undefined && this.saleBill.saleBillDetails.length>0)
      this.setTotal();
  }

  setTotal(){
    let fr=this.actFirm.filter(o=>o.FirmID==this.f.ddlFirm.value);
    if(fr[0].GSTRegType!='Regular')
      this.gstPercent=0;
    else
      this.gstPercent=18;

    this.subtotal=0;
    for (let sd of this.saleBill.saleBillDetails) {
      this.subtotal+=parseFloat(sd.Amount.toString());
    }

    this.tempSubtotal = 0;
    this.tempSubtotal = this.tempSubtotal + this.subtotal;
    if(this.f.txtDiscountAmount.value !="" && this.f.txtDiscountAmount.value != null)
      this.tempSubtotal = this.tempSubtotal - parseFloat( this.f.txtDiscountAmount.value.toString());
    else
      this.f.txtDiscountAmount.setValue(0);

    this.gstAmount=( ( (parseFloat(this.tempSubtotal.toString()))*(parseFloat(this.gstPercent.toString())))/100);
    this.gstAmount=Math.round((this.gstAmount + Number.EPSILON) * 100) / 100
    let tempTotal=this.tempSubtotal+this.gstAmount;
    this.totalAmount=Math.floor(tempTotal);
    this.roundoff=this.totalAmount-parseFloat( tempTotal.toString());
    this.roundoff= Math.round((this.roundoff + Number.EPSILON) * 100) / 100
    this.f.txtRoundOff.setValue(this.roundoff);
  }

  getServiceById(id : number){
    this.spinnerService.show();
    this.serverAPIService.getServiceById(id)
    .subscribe(
      (data:Service) => {
        this.serviceName=data.ServiceName;
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error('Something went wrong!');
      }
    );
  }

  getClientById(id : number){
    this.spinnerService.show();
    this.serverAPIService.getClientById(id)
    .subscribe(
      (data:Client) => {
        this.client=data;
        this.spinnerService.hide();
       this.getClientBalance(id);
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error('Something went wrong!');
      }
    );
  }

  getClientBalance(id : number){
    this.serverAPIService.getBalance(id)
    .subscribe(
      (data:number) => {
        if(data<0)
          this.balance=((-1)*data).toString()+' Dr';
        else
          this.balance=(data).toString()+' Cr';
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error('Something went wrong!');
      }
    );
  }

  getFirms(){
   // this.spinnerService.show();
    this.serverAPIService.getFirms()
    .subscribe(
      (response:Firm[]) => {
        this.actFirm=response;
        this.firms = [{ id: 0, text: ''}];
        response.forEach(element => {
          this.firms.push({id:element.FirmID,text:element.FirmName});
         });
         this.firms.shift();
      //  this.spinnerService.hide();
        this.getBillById(this.saleBillId);
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error('Something went wrong!');
      }
    );


  }

  onSubmit(form: NgForm){
    if(this.saleBill.saleBillDetails.length==0){
      this.toaster.warning("Enter at least one detail");
      return;
    }

    this.spinnerService.show();

    this.saleBill.BillDateStr=this.f.txtBillDate.value;
    this.saleBill.FirmID=this.f.ddlFirm.value;
    this.saleBill.ClientName = this.f.txtClientName.value;
    this.saleBill.Description=this.f.txtDescription.value;
    this.saleBill.GstAmount=this.gstAmount;
    this.saleBill.GstPercent=this.gstPercent;
    this.saleBill.RoundOff=this.f.txtRoundOff.value;
    this.saleBill.TotalAmount=this.totalAmount;
    this.saleBill.DiscountAmount = this.f.txtDiscountAmount.value;
    //this.spinnerService.show();
        this.serverAPIService.generateBill(this.saleBill)
      .subscribe(
        (data: any) => {
          this.spinnerService.hide();
          this.toaster.success("Bill Generated successfully!");
          window.open(this.serverAPIService.webUrl+ "salebill/print-bill/"+this.saleBill.SaleBillID, "_blank");
          this.router.navigate(['/salebill']);
        },
        (error) => {
          this.spinnerService.hide();
          this.toaster.error(error._body);
        });
  }

}
