import { Component, OnInit, AfterViewInit, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { ServerAPIService } from 'src/app/serverapi.service';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from 'src/app/spinner.service';
import { Subject } from 'rxjs';
import { TaskwiseCount, UserwiseWork } from '../dashboard.model';
import { NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CustomDateAdapter, CustomDateParserFormatter } from 'src/app/custom-ngbDateParserFormatter';
import { formatDate } from '@angular/common';
import { Client } from 'src/app/masters/client.model';
import { FormGroup, FormControl } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { UserProfile } from 'src/app/user-profile/user-profile.model';
declare let $: any;

@Component({
  selector: 'app-users-task-details',
  templateUrl: './users-task-details.component.html',
  styleUrls: ['./users-task-details.component.css'],
  providers: [{provide: NgbDateAdapter, useClass: CustomDateAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}]
})
export class UsersTaskDetailsComponent implements OnInit,AfterViewInit,AfterContentChecked {
  assitants:UserwiseWork[];
  astarray:object[];
  dtOptions:any = {};
  dtTrigger: Subject<any> = new Subject();
  uid:number=0;
  currentDate = new Date();
  clientArray:Client[];
  profileForm = new FormGroup({
    txtFromDate: new FormControl(''),
    txtToDate:new FormControl(''),
    ddlAssistant:new FormControl('')
  });
  public tableWidget: any;
  dtElement: DataTableDirective;

  ngAfterContentChecked() {
    this.ref.detectChanges();
  }
   // convenience getter for easy access to form fields
   get f() { return this.profileForm.controls; }

  getDaysInMonth(month,year) {
   return new Date(year, month, 0).getDate();
  }

  constructor(public serverAPIService:ServerAPIService,
    private router:Router, private toaster:ToastrService, private spinnerService:SpinnerService
    ,private route : ActivatedRoute,private ref: ChangeDetectorRef) {
      this.dtOptions = {
        processing: true,
        paging:false,
        destroy: true,
        order:[],
        dom: "Bftrpi",
        scrollY:"55vh",
        scrollX: true,
        buttons: {
          buttons: [{
             extend: 'excel',
             text: '<i class="mdi mdi-file-excel"></i> Export To Excel',
             titleAttr: 'Export To Excel',
             title:'WorkLogReport - Aareasy'
            }
          ],
          dom: {
            button: {
              tag: "button",
              className: "btn btn-gradient-success btn-fw text-light btn-sm"
            },
            buttonLiner: {
              tag: null
            }
          }
        }
      }
  }


  ngOnInit() {
    let day:number=this.currentDate.getDate();
    let month:number=this.currentDate.getMonth();
    let year:number=this.currentDate.getFullYear();
    if(month==12){
      month=0;
      //year=year+1;
    }

    this.f.txtFromDate.setValue('01'+ '/'+((month+1).toString().length==1
    ?'0'+(month+1).toString()
    :(month+1).toString())
    +'/'+year.toString());
    this.f.txtToDate.setValue((day.toString().length==1?'0'+day.toString():day.toString())+'/'+((month+1).toString().length==1
      ?'0'+(month+1).toString()
      :(month+1).toString())
      +'/'+year.toString());
  }

  getAssistant(){
    //this.spinnerService.show();
    this.serverAPIService.getAssistants()
    .subscribe(
      (response : UserProfile[]) => {
        this.astarray = [{ id: 0, text: 'ALL'}];
        response.forEach(element => {
          this.astarray.push({id:element.AppUserID,text:element.AppUserName});
         });
        // this.astarray.shift();
         if(this.uid!=0)
          this.f.ddlAssistant.setValue(this.uid);
         else
          this.f.ddlAssistant.setValue(this.astarray[0]["id"]);//response[0].AppUserID
        if(this.serverAPIService.getLoggedInUsersRole()=='Assistant')
          this.f.ddlAssistant.setValue(this.serverAPIService.getLoggedInUserId());
        this.getData();
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error("Something went wrong!");
      }
    )
  }

  ngAfterViewInit(): void {

    setTimeout(() => {
      this.spinnerService.show();
      this.route.params
      .subscribe(
        (params: Params) => {
          if(params["tp"] != null){
            let day:number=this.currentDate.getDate();
              let month:number=this.currentDate.getMonth();
              let year:number=this.currentDate.getFullYear();
              if(month==12){
                month=0;
                //year=year+1;
              }

              this.f.txtFromDate.setValue((day.toString().length==1?'0'+day.toString():day.toString())+'/'+((month+1).toString().length==1
              ?'0'+(month+1).toString()
              :(month+1).toString())
              +'/'+year.toString());
              this.f.txtToDate.setValue((day.toString().length==1?'0'+day.toString():day.toString())+'/'+((month+1).toString().length==1
                ?'0'+(month+1).toString()
                :(month+1).toString())
                +'/'+year.toString());
          }
          if(params["id"] != null){
              this.uid=params["id"];
            this.getAssistant();

          } else{
            this.getAssistant();
            this.spinnerService.hide();
          }
        }
      );
    });
  }


  viewRecord(){
   $('#example').DataTable().destroy();
    this.assitants=null;
    this.dtTrigger.next();
    setTimeout(() => this.getData(),0)

  }

  getData(){
    this.spinnerService.show();
    this.serverAPIService.getUserwiseTask(this.f.txtFromDate.value,this.f.txtToDate.value,this.f.ddlAssistant.value,null)
    .subscribe(
      (response : UserwiseWork[]) => {
        this.assitants=response;
        this.dtTrigger.next();this.ref.detectChanges();
        this.spinnerService.hide();
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error("Something went wrong!");
      }
    )
  }
}
