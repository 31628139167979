import { Component, OnInit, AfterViewInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ServerAPIService } from '../../serverapi.service';
import { ActivatedRoute,Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from 'src/app/spinner.service';
import { ForgotPassword } from './forgot-password.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewChild } from '@angular/core';
import { TemplateRef } from '@angular/core';
// import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
declare var componentHandler: any;


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  serverAPIService: any;
  toaster: any;
  constructor(private serviceAPIService:ServerAPIService, private route:ActivatedRoute,
    private router:Router,private toastr: ToastrService,private spinnerService : SpinnerService,
    private modalService: NgbModal
    // ,private spinnerService:Ng4LoadingSpinnerService
    ) { }

  @ViewChild('mymodal') editModal : TemplateRef<any>;

  ngOnInit() {
    //componentHandler.upgradeAllRegistered();
  }

  ngAfterViewInit(){
    //componentHandler.upgradeAllRegistered();
  }

  onSubmit(form: NgForm){
    const userName = form.value.username;

    let forgotPassword = new ForgotPassword();
    forgotPassword.UserName = userName;
    this.spinnerService.show();
    this.serviceAPIService.forgotPassword(forgotPassword)
    .subscribe(
      (data: any) => {
        this.spinnerService.hide();
        if(data.status == 200){
          this.modalService.open(this.editModal);
          this.router.navigate(['/signin']);
        }
        else{
          this.toastr.error("Password reset failed!","Error");
        }
      },
      (error) => {
        this.spinnerService.hide();
        if(error.status == 404){
          this.toastr.error("UserName does not exist, please try again!");
        }
        else if(error.status == 400){
          this.toastr.error("Please contact to aareasy support.");
        }
        else{
          this.toastr.error("Please contact to aareasy support.");
        }
      });
  }
  }


