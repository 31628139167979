import { Component, OnInit, ViewChild, AfterViewInit, AfterViewChecked } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ServerAPIService } from '../../serverapi.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ChangePassword } from './change-password.model';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  @ViewChild('f') formRef: NgForm;

  constructor(private serverAPIService:ServerAPIService,private toastr: ToastrService,
    private route:ActivatedRoute, private router:Router) { }

  ngOnInit() {
    if(!this.serverAPIService.isAuthenticated()){
      this.router.navigate(['/signin'],{queryParams:{redirect: '/account/profile'}});
    }
  }

  onSubmit(form: NgForm){
    
    if(form.value.NewPassword !== form.value.ConfirmPassword){
      this.toastr.warning("Password didn't match");
      return;
    }

    //this.spinnerService.show();

    let changePassword: ChangePassword = new ChangePassword();
    changePassword.OldPassword= form.value.OldPassword;
    changePassword.NewPassword= form.value.NewPassword;
    changePassword.ConfirmPassword= form.value.ConfirmPassword;

    this.serverAPIService.changePassword(changePassword)
    .subscribe(
      (data: any) => {
       // this.spinnerService.hide();
        this.toastr.success("Password changed!","Success");
        this.router.navigate(['/']);
      },
      (error: Response) => {
       // this.spinnerService.hide();
        /*if(error._body != undefined){
          this.toastr.error(JSON.parse(error._body)  != undefined
            ?JSON.parse(error._body)
            :"Something went wrong!","Error");
        }
        else*/
          this.toastr.error("Something went wrong!","Error");
      }
    );
  }

}
