import { Component, OnInit, ChangeDetectorRef, ViewChild, TemplateRef } from '@angular/core';
import { ServerAPIService } from 'src/app/serverapi.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from 'src/app/spinner.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Service } from 'src/app/user-service/service.model';
import { Firm } from 'src/app/masters/firm.model';
import { FinancialYear } from 'src/app/fy.model';
import { NgForm } from '@angular/forms';
import { th } from 'date-fns/locale';

@Component({
  selector: 'app-import-clients',
  templateUrl: './import-clients.component.html',
  styleUrls: ['./import-clients.component.css']
})
export class ImportClientsComponent implements OnInit {
  //@ViewChild('f', { static: true }) formRef: NgForm;
  serviceId:number=0;
  ServiceName:string="";
  firmId:number=0;
  srv:object[];
  frm:object[];
  fydata:string[];
  fylist:FinancialYear[];
  fyYear:string='';
  currentFyYear:string='';
  selectedFiles: File[] = [];
  ServiceArray:Service[];
  clientCount:number=0;
  constructor(public serverAPIService:ServerAPIService,
    private router:Router, private toaster:ToastrService, private spinnerService:SpinnerService
    ,private modalService: NgbModal,private ref: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  public onServiceSelected(event) {
    const value = event;
    if(value!=''){
        this.serviceId=value;
      }
 }

 public onFirmSelected(event) {
  const value = event;
  if(value!=''){
      this.firmId=value;
    }
}

 ngAfterViewInit(): void{
  setTimeout(() => {
    this.getServices();
    this.getFirms();
    this.getFy();
  });
}

 getServices(){
  this.serverAPIService.getServices()
   .subscribe(
     (response : Service[]) => {
      this.ServiceArray = response;
      this.srv=[];
       response.forEach(element => {
        this.srv.push({id:element.ServiceID,text:element.ServiceName});
       });
     },
     (error) => {
       this.spinnerService.hide();
       this.toaster.error("Something went wrong!");
     }
   )
}

getFirms(){
  this.serverAPIService.getFirms()
   .subscribe(
     (response : Firm[]) => {
       this.frm=[];
       response.forEach(element => {
        this.frm.push({id:element.FirmID,text:element.FirmName});
       });
     },
     (error) => {
       this.spinnerService.hide();
       this.toaster.error("Something went wrong!");
     }
   )
}

getFy(){
  this.spinnerService.show();
  this.serverAPIService.getUsersFy()
  .subscribe(
    (response : FinancialYear[]) => {
      this.fylist=response;
      this.fydata=[''];
      response.forEach(role => {
        this.fydata.push(role.FinancialYear);
      });
      this.fydata.shift;
      this.serverAPIService.getCurrentFy()
      .subscribe(
        (response : any) => {
          this.fyYear=response;
          this.currentFyYear = response;
        },
        (error) => {
        }
      )
      this.spinnerService.hide();
    },
    (error) => {
      this.spinnerService.hide();
      this.toaster.error("Something went wrong!");
    }
  )
}

changeFy(fy:string){
  this.fyYear=fy;
}

onSubmit(form: NgForm) {
  if (this.selectedFiles !== undefined && this.selectedFiles.length > 0) {
    this.uploadFile(form);
  } else {
  }
}

@ViewChild('mymodal') editModal : TemplateRef<any>;
@ViewChild('cmodal') cmodal : TemplateRef<any>;

uploadFile(form: NgForm) {
  this.spinnerService.show();
  const formData = new FormData();
  for (const file of this.selectedFiles) {
    formData.append('files', file);
    formData.append('fyYear',this.fyYear);
    formData.append('firmId',this.firmId.toString());
    formData.append('serviceId',this.serviceId.toString());
  }
  this.clientCount = 0;
  this.ServiceName = '';//this.ServiceArray.filter(o=> o.ServiceID==this.serviceId)[0].ServiceName;
  this.serverAPIService.postClientExcel(formData)
    .subscribe(
      (data) => {
        this.spinnerService.hide();
        this.clientCount = parseInt(data.text());
        // this.toaster.success("Upload successful!!!");
        this.ServiceName = this.clientCount+' Client(s) imported successfuly for '+ this.ServiceArray.filter(o=> o.ServiceID==this.serviceId)[0].ServiceName;
        //this.ServiceName = this.ServiceName.split('"').join('');
        this.modalService.open(this.editModal);
        this.resetForm();
      },
      (error) => {
        // this.toaster.error("Unable to upload file!");
        this.ServiceName = error.text();
        if(this.ServiceName !=''){
          this.ServiceName = this.ServiceName.split('"').join('');
          this.modalService.open(this.editModal);
          this.resetForm();
        }
        else{
          this.toaster.error("Unable to upload file!");
        }
        this.spinnerService.hide();
      }
    );
}

deleteClient(){
  this.modalService.open(this.cmodal);
}

CarryForwardConfirm(){
  this.modalService.dismissAll();
  this.spinnerService.show();
  const CarryForwardFormData = new FormData();
  CarryForwardFormData.append('fyYear',this.fyYear);
  CarryForwardFormData.append('firmId',this.firmId.toString());
  CarryForwardFormData.append('serviceId',this.serviceId.toString());
  CarryForwardFormData.append('currentFyYear',this.currentFyYear.toString());

  this.serverAPIService.CarryForwardClients(CarryForwardFormData)
  .subscribe(
    (data) => {
      this.spinnerService.hide();
        this.clientCount = parseInt(data.text());
        this.ServiceName = this.clientCount+' Client(s) imported successfuly for '+ this.ServiceArray.filter(o=> o.ServiceID==this.serviceId)[0].ServiceName;
        this.modalService.open(this.editModal);
        this.resetForm();
    },
    (error) => {
      this.spinnerService.hide();
      this.modalService.dismissAll();
      this.ServiceName = error.text();
        if(this.ServiceName !=''){
          this.ServiceName = this.ServiceName.split('"').join('');
          this.modalService.open(this.editModal);
          this.resetForm();
        }
        else{
          this.toaster.error("Unable to Carry Forward!");
        }
        this.spinnerService.hide();
    }
  )
}

resetForm(){
  var dirtyFormID = 'myFrm';
  var resetForm = <HTMLFormElement>document.getElementById(dirtyFormID);
  resetForm.reset();
  this.getFy();
}
}
