import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  display : boolean;

  constructor() { 
    this.display = false;
  }

  private showSpinner = new BehaviorSubject<boolean>(this.display);

  public show(){
    this.display = true;
    this.showSpinner.next(this.display);
  }

  public hide(){
    this.display = false;
    this.showSpinner.next(this.display);
  }

  get getSpinnerState(){
    return this.showSpinner.asObservable();
  }
}
