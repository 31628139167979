import { Component, OnInit } from '@angular/core';
import { AdminDetails } from '../admindetails.model';
import { ServerAPIService } from 'src/app/serverapi.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerService } from 'src/app/spinner.service';
import { CommunicationService } from 'src/app/communation.service';

@Component({
  selector: 'app-order-failed',
  templateUrl: './order-failed.component.html',
  styleUrls: ['./order-failed.component.css']
})
export class OrderFailedComponent implements OnInit {

  order: AdminDetails = new AdminDetails();

  constructor(public serverAPIService:ServerAPIService,private toastr: ToastrService,
    private route:ActivatedRoute, private router:Router,private commService:CommunicationService,
    private spinnerService: SpinnerService) { }

    ngOnInit() {
      if(!this.serverAPIService.isAuthenticated()){
        this.router.navigate(['/signin']);
      }else{
        this.loadOrder();
      }
    }

    loadOrder(){
      this.spinnerService.show();
  
      let orderId:number = this.route.snapshot.queryParams['id'];
      this.serverAPIService.getAdminDetailsById(orderId)
      .subscribe(
        (data: AdminDetails) =>{
          this.order = data;
         // console.log(data);
         this.commService.emitChange();
          this.spinnerService.hide();
        },
        (error: Response) =>{
          this.spinnerService.hide();
          this.commService.emitChange();
          this.toastr.error("Failed to place order please try again!")
        }
      )
    }

}
