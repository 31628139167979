import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras, Params } from '@angular/router';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AdminDetails } from 'src/app/purchase-form/admindetails.model';
import { ServerAPIService } from 'src/app/serverapi.service';
import { SpinnerService } from 'src/app/spinner.service';
import { UserProfile } from 'src/app/user-profile/user-profile.model';
import { WindowRefService } from 'src/app/window-ref.service';
import { TAPORDMemberID } from '../tapordmemberid.model';

@Component({
  selector: 'app-renew-checkout',
  templateUrl: './renew-checkout.component.html',
  styleUrls: ['./renew-checkout.component.css'],
  providers: [WindowRefService]
})
export class RenewCheckoutComponent implements OnInit,AfterViewInit,AfterContentChecked {
  [x: string]: any;
  plan:string='';
  planAmount:number=0;
  gstClients:string='';
  itClients:string='';
  otherClients:string='';
  discPer:number=0;
  discAmount:number=0;
  total:number=0;
  usr:UserProfile;
  ad:AdminDetails;
  taportTextView:boolean=false;
  membershipId:string='';
  chkMembershipId:boolean = false;
  gatewayCharges:number=0;
  isgooglepay:boolean=false;
  constructor(private serverAPIService:ServerAPIService,
    private router:Router, private toaster:ToastrService, private spinnerService:SpinnerService
    ,private route : ActivatedRoute,private ref: ChangeDetectorRef,private winRef: WindowRefService
    ,config: NgbModalConfig, private modalService: NgbModal,private ngZone: NgZone) {
      config.backdrop = 'static';
      config.keyboard = false;
  }

  ngAfterContentChecked() {
    this.ref.detectChanges();
  }

  ngOnInit(): void {
    $(".form-check label,.form-radio label").append('<i class="input-helper"></i>');
  }

  public navigate(commands: any[],extras?:NavigationExtras): void {
    this.ngZone.run(() => this.router.navigate(commands,extras)).then();
}

ngAfterViewInit(): void {

  setTimeout(() => {
    this.spinnerService.show();
    this.route.params
    .subscribe(
      (params: Params) => {
        if(params["pl"] != null){
          if(params["pl"]=='S'){
            this.plan='Silver';
            this.gstClients="50";
            this.itClients="150";
            this.otherClients="150";
            this.planAmount=2499;
            this.gatewayCharges = 60;
          }
          else if(params["pl"]=='G'){
            this.plan='Gold';
            this.gstClients="75";
            this.itClients="250";
            this.otherClients="250";
            this.planAmount=3999;
            this.gatewayCharges = 96;
          }
          else{
            this.plan='Diamond';
            this.gstClients="Unlimited";
            this.itClients="Unlimited";
            this.otherClients="Unlimited";
            this.planAmount=5999;
            this.gatewayCharges = 144;
          }
          this.total = this.planAmount + this.gatewayCharges;
          this.getUser();
          this.spinnerService.hide();
        } else{
          this.spinnerService.hide();
        }
      }
    );
  });
}

paymentDone(){
  this.toaster.success("We will validate your payment and update your account !!!");
  this.router.navigate(['/home'])
}

setTotal() {
  this.discAmount=Math.floor( ((this.planAmount*this.discPer)/100));
  this.total=this.planAmount-this.discAmount+this.gatewayCharges;
}

getUser(){
  this.spinnerService.show();
  this.serverAPIService.getAdminUser()
  .subscribe(
    (response : UserProfile) => {
      this.usr=response;
      this.spinnerService.hide();
    },
    (error) => {
      this.spinnerService.hide();
      this.toaster.error("Something went wrong!");
    }
  )
}

payClick(){
  if(this.taportTextView && this.membershipId ==''){
    this.toaster.warning("Enter membership ID");
    return;
  }

  let ad=new AdminDetails();
  ad.DiscAmount=this.discAmount;
  ad.DiscPer=this.discPer;
  ad.PaymentStatus="Processing";
  ad.PaymentType=this.plan;
  ad.PlanAmount=this.planAmount;
  ad.Amount=this.total;
  ad.PaymentMethod="Purchase";
  ad.TAPORDMemberID=this.membershipId;

  this.serverAPIService.postOrder(ad)
  .subscribe(
    (data: any) =>{
      this.spinnerService.hide();

        this.createRzpayOrder(data,ad)
    },
    (error: any) =>{
      this.spinnerService.hide();

    }
  )
}

gatewaySelect(googlePay:boolean){
  this.isgooglepay=googlePay;
  if(!this.isgooglepay &&  this.discPer==0){
    if(this.plan=="Silver")
      this.gatewayCharges=96;//78;
    else if(this.plan=="Gold")
      this.gatewayCharges=120;//97;
    else
      this.gatewayCharges=169;//135;
  }
  else if(!this.isgooglepay &&  this.discPer>0){
    if(this.plan=="Silver")
      this.gatewayCharges=82;//78;
    else if(this.plan=="Gold")
      this.gatewayCharges=102;//97;
    else
      this.gatewayCharges=143;//135;
  }
  else
    this.gatewayCharges=0;
  this.setTotal();
}

createRzpayOrder(data,_order1) {
  //console.log(data);
  this.payWithRazor(data._body,_order1);
}

payWithRazor(val,order1) {
  this.serverAPIService.getAdminDetailsById(val)
  .subscribe(
    (data: AdminDetails) =>{
      this.ad = data;
      const options: any = {
        key: 'rzp_live_nSDCpx4O3r5Qeh', //rzp_test_H3rFhpje1hwTlm
        amount: this.ad.Amount*100,//order1.Total, // amount should be in paise format to display Rs 1255 without decimal point
        currency: 'INR',
        name: 'Aareasy', // company name or product name
        description: 'Purchase at Aareasy',  // product description
      // image: 'assets/images/a-logo.png', // company logo or product image
        order_id: this.ad.TransactionNo, // order_id created by you in backend
        payment_capture: '1',
        callback_url:this.serverAPIService.domainUrl+ 'RazorpayCallback.aspx',
        redirect: true,

        prefill:{
          email:this.usr.Email ==''? 'aareasy@gmail.com':this.usr.Email,
          contact:parseInt(this.usr.MobileNo)
        },
        modal: {
          // We should prevent closing of the form when esc key is pressed.
          escape: false,
        },
        notes: {
          // include notes if any
        },
        theme: {
          color: '#0c238a'
        }
      };
      options.handler = ((response, error) => {
        options.response = response;
        console.log(response);
        if(response!=null){
            this.spinnerService.show();
            this.ad.PaymentID=response.razorpay_payment_id;
            this.ad.RefNo=response.razorpay_order_id;
            this.ad.StatusInResponse=response.razorpay_signature;
            console.log(this.ad);
            this.serverAPIService.updatePayment(this.ad)
            .subscribe(
              (data: any) =>{
                this.spinnerService.hide();
                console.log(data);
          // this.toastr.success('Order cancelled');
                if(data._body== '"Successful"'){
                      this.navigate(['/order-completed-renew'],{queryParams:{id: this.ad.AdminDetailsID }});
                }
                else{
                  this.navigate(['/order-failed-renew'],{queryParams:{id: this.ad.AdminDetailsID }});
                }
              },
              (error:Response) => {
                this.spinnerService.hide();
                //this.order.OrderStatus = 'Order Placed';
                this.toaster.error('Request failed');
                this.navigate(['/order-failed-renew'],{queryParams:{id: this.ad.AdminDetailsID }});
              });
          }
        else {
          this.spinnerService.show();
          //this.order.OrderStatus = 'Order Placed';
          this.ad.PaymentID='';
          this.ad.RefNo='';
          this.ad.StatusInResponse='';
          this.serverAPIService.updatePayment(this.ad)
          .subscribe(
            (data: any) =>{
              this.spinnerService.hide();
        // this.toastr.success('Order cancelled');
              if(data._body=="Successful"){
                    this.navigate(['/order-completed-renew'],{queryParams:{id: this.ad.AdminDetailsID }});
              }
              else{
                this.navigate(['/order-failed-renew'],{queryParams:{id: this.ad.AdminDetailsID }});
              }
            },
            (error:Response) => {
              this.spinnerService.hide();
              //this.order.OrderStatus = 'Order Placed';
              this.toaster.error('Payment failed');

              this.navigate(['/order-failed-renew'],{queryParams:{id: this.ad.AdminDetailsID }});
            });
        };

      });
  options.modal.ondismiss = (() => {
    // handle the case when user closes the form while transaction is in progress
   // console.log('Transaction cancelled.');
    //return;
    var self = this;
    this.spinnerService.show();
    //this.order.OrderStatus = 'Order Placed';
    this.ad.PaymentID='';
    this.ad.RefNo='';
    this.ad.StatusInResponse='';
    this.serverAPIService.updatePayment(this.ad)
    .subscribe(
      (data: any) =>{
        this.spinnerService.hide();
  // this.toastr.success('Order cancelled');
        if(data._body=="Successful"){
              this.navigate(['/order-completed-renew'],{queryParams:{id: this.ad.AdminDetailsID }});
        }
        else{
          this.navigate(['../order-failed-renew'],{queryParams:{id: this.ad.AdminDetailsID }});
        }
      },
      (error:Response) => {
        this.spinnerService.hide();
        //this.order.OrderStatus = 'Order Placed';
        this.toaster.error('Request failed');
        this.navigate(['/order-failed-renew'],{queryParams:{id: this.ad.AdminDetailsID }});
      });
  });
  const rzp = new this.winRef.nativeWindow.Razorpay(options);
  rzp.open();
},
(error: Response) =>{
  this.spinnerService.hide();
  this.toaster.error("Failed to place order please try again!")
}
)
}
}
