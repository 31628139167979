import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

export class CustomNgbDateParserFormatter extends NgbDateParserFormatter {
	datePipe = new DatePipe('de-AT');
	constructor(
		private dateFormatString: string) {
		super();
	}
	format(date: NgbDateStruct): string {
		if (date === null) {
			return '';
		}
		try {
			return this.datePipe.transform(new Date(date.year, date.month - 1, date.day), this.dateFormatString);
		} catch (e) {
			return '';
		}
	}
	parse(value: string): NgbDateStruct {
		let returnVal: NgbDateStruct;
		if (!value) {
			returnVal = null;
		} else {
			try {
				let dateParts = this.datePipe.transform(value, 'M-d-y').split('-');
				returnVal = { year: parseInt(dateParts[2]), month: parseInt(dateParts[0]), day: parseInt(dateParts[1]) };
			} catch (e) {
				returnVal = null;
			}
		}
		return returnVal;
	}
}

@Injectable()
export class CustomDateAdapter {
  fromModel(value: string): NgbDateStruct
  {
     if (!value)
      return null
     let parts=value.split('/');
     return {day:+parts[0],month:+parts[1],year:+parts[2]}
  }

  toModel(date: NgbDateStruct): string // from internal model -> your mode
  {
    return date?('0'+date.day).slice(-2)+"/"+('0'+date.month).slice(-2)+"/"+date.year :null
  }
}

@Injectable()
export class CustomDateParserFormatter {
  parse(value: string): NgbDateStruct
  {
    if (!value)
      return null
     let parts=value.split('/');
     return {day:+parts[0],month:+parts[1],year:+parts[2]} as NgbDateStruct

  }
  format(date: NgbDateStruct): string
  {
	return date?('0'+date.day).slice(-2)+"/"+('0'+date.month).slice(-2)+"/"+date.year :null
	//date?date.year+"/"+('0'+date.month).slice(-2)+"/"+('0'+date.day).slice(-2):null
  }
}