import { Component, OnInit, ChangeDetectorRef, ElementRef, AfterViewInit, AfterContentChecked } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServerAPIService } from '../serverapi.service';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from '../spinner.service';
import { FinancialYear } from '../fy.model';
import { DatePipe } from '@angular/common';
import { Service } from '../user-service/service.model';
import { Task } from '../user-service/task.model';
import { UserProfile } from '../user-profile/user-profile.model';
import { AlertTaskCountData } from './alert-task-count-data.model';
import { Subject } from 'rxjs';
import { CAInfo } from '../cainfo.model';

@Component({
  selector: 'app-communication',
  templateUrl: './communication.component.html',
  styleUrls: ['./communication.component.css'],
  providers: [DatePipe ]
})
export class CommunicationComponent implements OnInit,AfterViewInit,AfterContentChecked {

  fylist:FinancialYear[];
  fydata:string[];
  fyYear:string='';
  period:string[]=['Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec','Jan','Feb','Mar','Q-1','Q-2','Q-3','Q-4','One Time','Yearly']
  prd:string='';
  smsBalance:string='';
  srv:object[];
  servicearray:Service[];
  tsd:object[];
  taskarray:Task[];
  st:string[]=[];
  serviceID:number;
  selectedStatus:string='';
  clientCount:number=0;
  taskId:number=0;
  smsFixedText:string='';
  smVariableText:string;
  adminUser:UserProfile;
  sendsms:boolean=true;
  alertTaskCountData:AlertTaskCountData[];
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  arrWorkDetailsID:number[]=[];
  smPrefixVariableText:string;
  isChecked:boolean=true;
  openStatusText = 'Pending';

  constructor(public serverAPIService:ServerAPIService,private route : ActivatedRoute,
    private router:Router, private toaster:ToastrService, private spinnerService:SpinnerService
    ,private ref: ChangeDetectorRef,private el: ElementRef,private datePipe: DatePipe ) {
      this.dtOptions = {
        processing: true,
        paging:false,
        destroy: true,
        responsive: true,
        order:[]
      }
  }

  ngAfterContentChecked() {
    this.ref.detectChanges();
}

typeSelect(tp:boolean){
  this.sendsms=tp;
  if(this.sendsms){
    this.smsFixedText=this.serverAPIService.smsTemplate;
    if(this.taskId!=0){
      let taskName=this.taskarray.filter(o=>o.TaskID==this.taskId)[0].TaskName;
      this.smsFixedText=this.smsFixedText.replace("{TaskName}",taskName);
    }
    if(this.selectedStatus !=''){
      if(this.selectedStatus.toLowerCase() == 'open')
      {
        this.smsFixedText=this.smsFixedText.replace("{TaskStatus}",this.openStatusText);
      }
      else
      {
        this.smsFixedText=this.smsFixedText.replace("{TaskStatus}",this.selectedStatus);
      }
    }
    if (this.fyYear != "" && this.alertTaskCountData.length > 0) {
      this.smsFixedText = this.smsFixedText.replace(
        "{Period}",
        this.alertTaskCountData[0].WorkPeriod
      );
    }
  }
  else{
    this.smsFixedText=this.serverAPIService.whatsappTemplate;
    if(this.taskId!=0){
      let taskName=this.taskarray.filter(o=>o.TaskID==this.taskId)[0].TaskName;
      this.smsFixedText=this.smsFixedText.replace("{TaskName}",taskName);
    }
    if(this.selectedStatus !='')
    {
      if(this.selectedStatus.toLowerCase() == 'open')
      {
        this.smsFixedText=this.smsFixedText.replace("{TaskStatus}",this.openStatusText);
      }
      else
      {
        this.smsFixedText=this.smsFixedText.replace("{TaskStatus}",this.selectedStatus);
      }
    }

    if (this.fyYear != "" && this.alertTaskCountData.length > 0)
      this.smsFixedText = this.smsFixedText.replace(
        "{Period}",
        this.alertTaskCountData[0].WorkPeriod
      );
  }

}


  ngOnInit(): void {
    this.st=this.serverAPIService.AllStatus;
    this.smsFixedText=this.serverAPIService.smsTemplate;
    if (
      this.serverAPIService.getCAMobile() == null ||
      this.serverAPIService.getCAMobile() == "undefined"
    ) {
      setTimeout(() => {
        this.serverAPIService.getCAInfo().subscribe(
          (response: CAInfo) => {
            localStorage.setItem("mobileNo", response[0].mobileNo);
          },
          (error) => {}
        );
      });
    }
  }

  ngAfterViewInit(){
    setTimeout(() => {
      this.prd = this.datePipe.transform(
        new Date(new Date().setMonth(new Date().getMonth() - 1)),
        "MMM"
      );
      this.getFy();
      this.getUnaddedServices();

      this.serverAPIService.getAdminUser().subscribe(
        (response: UserProfile) => {
          this.adminUser = response;
          if (
            this.adminUser?.SmsApiKey != null &&
            this.adminUser?.SmsApiKey != ""
          ) {
            this.serverAPIService.getSmsBalance().subscribe(
              (response: any) => {
                this.smsBalance = "Balance: " + response;
              },
              (error) => {
                this.smsBalance = "";
              }
            );
          }
        },
        (error) => {
          //this.spinnerService.hide();
          //this.toaster.error("Something went wrong!");
        }
      );
    });

   this.alertTaskCountData=[];
   this.dtTrigger.next();
  }

  public onServiceSelected(event) {
    this.tsd=[];
    //this.selectedStatus='';
    this.clientCount=0;
    this.alertTaskCountData = [];
    this.arrWorkDetailsID = [];
    if(event!=undefined && event!=''){
      this.serviceID=event;
      this.serverAPIService.getServiceTaskForUser(this.serviceID)
     .subscribe(
       (response:Task[]) => {
         this.taskarray=response;
         this.tsd = [{ id: 0, text: ''}];
         response.forEach(element => {
           this.tsd.push({id:element.TaskID,text:element.TaskName});
          });
        this.tsd.shift();
       },
       (error) => {
         this.spinnerService.hide();
         this.toaster.error('Something went wrong!');
       }
     );
    }
  }

  public onTaskSelected(event) {
    this.taskId=0;
    if(event!=undefined && event!=''){
      this.taskId=event;
      if(this.selectedStatus !=''){
        this.onStatusSelected(this.selectedStatus);
      }
    }
  }

  public onStatusSelected(event) {
    if(this.taskId==0){
      this.toaster.warning("Select task");
      return;
    }
    this.clientCount=0;
    this.alertTaskCountData=[];
    this.arrWorkDetailsID = [];
    if(this.sendsms){
      this.smsFixedText=this.serverAPIService.smsTemplate;
      if(this.smVariableText!=null && this.smVariableText!=''){
        this.smPrefixVariableText = "Remark- ";
      }
      else{
        this.smPrefixVariableText = "Please contact for any help.";
      }
    }
    else{
      this.smsFixedText=this.serverAPIService.whatsappTemplate;
      if(this.smVariableText!=null && this.smVariableText!=''){
        this.smPrefixVariableText = "";
      }
      else{
        this.smPrefixVariableText = "कृपया अधिक माहितीसाठी संपर्क साधा.";
      }
    }

    this.smVariableText='';
    if(event!=undefined && event!=''){
      this.selectedStatus=event;
      let taskName=this.taskarray.filter(o=>o.TaskID==this.taskId)[0].TaskName;
      this.smsFixedText=this.smsFixedText.replace("{TaskName}",taskName);

      if(this.selectedStatus.toLowerCase() == 'open')
      {
        this.smsFixedText=this.smsFixedText.replace("{TaskStatus}",this.openStatusText);
      }
      else
      {
        this.smsFixedText=this.smsFixedText.replace("{TaskStatus}",this.selectedStatus);
      }

    $('#example').DataTable().destroy();
    this.alertTaskCountData=[];
    this.dtTrigger.next();
     this.spinnerService.show();
     this.serverAPIService.getAlertClientCountData(this.fyYear,this.prd,this.taskId,this.selectedStatus)
     .subscribe(
       (response:any) => {
         this.alertTaskCountData=[];
         this.alertTaskCountData=response;
         this.clientCount=this.alertTaskCountData.length;
         this.dtTrigger.next();
         this.arrWorkDetailsID = [];
         this.arrWorkDetailsID = response.map(a => a.WorkDetailsID);
         if(this.alertTaskCountData.length>0)
          this.smsFixedText=this.smsFixedText.replace("{Period}",this.alertTaskCountData[0].WorkPeriod);
         this.spinnerService.hide();
       },
       (error) => {
         this.spinnerService.hide();
         this.toaster.error('Something went wrong!');
       }
     );
    }
  }

  selectAllClients(isSelectAll:boolean){
    if(isSelectAll){
      //alert("Select All");
      for (let row of this.alertTaskCountData) {
        row.isSelected = true;
      }
      this.arrWorkDetailsID = this.alertTaskCountData.map(a => a.WorkDetailsID);
    }
    else{
      //alert("Unselect All");
      for (let row of this.alertTaskCountData) {
        row.isSelected = false;
      }
      this.arrWorkDetailsID = [];
    }
  }

  getFy(){
    this.spinnerService.show();
    this.serverAPIService.getUsersFy()
    .subscribe(
      (response : FinancialYear[]) => {
        //this.spinnerService.hide();
        this.fylist=response;
        this.fydata=[''];
        response.forEach(role => {
          this.fydata.push(role.FinancialYear);
        });
        this.fydata.shift;
        this.serverAPIService.getCurrentFy()
        .subscribe(
          (response : any) => {
            this.fyYear=response;
            this.spinnerService.hide();
          },
          (error) => {
          }
        )
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error("Something went wrong!");
      }
    )
  }

  changeFy(fy:string){
    this.fyYear=fy;
    if(this.selectedStatus !=''){
      this.onStatusSelected(this.selectedStatus);
    }
  }

  changePrd(prd:string){
    this.prd=prd;
    if(this.selectedStatus !=''){
      this.onStatusSelected(this.selectedStatus);
    }
  }

  getUnaddedServices(){
    this.serverAPIService.getServices()
     .subscribe(
       (response : Service[]) => {
         this.servicearray=response;
         this.srv=[];
         this.srv = [{ id: 0, text: ''}];
         response.forEach(element => {
          this.srv.push({id:element.ServiceID,text:element.ServiceName});
         });
         this.srv.shift();
         this.spinnerService.hide();
       },
       (error) => {
         this.spinnerService.hide();
         this.toaster.error("Something went wrong!");
       }
     )
  }

  sendSMS(){
    if(this.prd=="" || this.taskId==0 || this.selectedStatus==""){
      this.toaster.warning('Select Preiod, Task and status');
      return;
    }
    if(this.clientCount==0){
      this.toaster.warning("Client count is 0");
      return;
    }
    if(this.arrWorkDetailsID?.length<1){
      this.toaster.warning("Selected client count is 0");
      return;
    }
    if(this.smVariableText!=null && this.smVariableText!=''){
        this.smPrefixVariableText = "Remark- ";
    }
    else{
        this.smPrefixVariableText = "Please contact for any help.";
    }
    this.serverAPIService.sendBulkAlert(this.smsFixedText+this.smPrefixVariableText+this.smVariableText+' --'+this.serverAPIService.getCustomerName()+','+this.serverAPIService.getCAMobile()+'.', this.fyYear,this.prd,
      this.taskId,this.selectedStatus,this.arrWorkDetailsID)
     .subscribe(
       (response:any) => {
        this.toaster.success('SMS(s) will be sent shortly!');
       },
       (error) => {
         this.spinnerService.hide();
         this.toaster.error('Something went wrong!');
       }
     );
  }

  sendWhatsApp(){
    if(this.prd=="" || this.taskId==0 || this.selectedStatus==""){
      this.toaster.warning('Select Preiod, Task and status');
      return;
    }
    if(this.clientCount==0){
      this.toaster.warning("Client count is 0");
      return;
    }
    if(this.arrWorkDetailsID?.length<1){
      this.toaster.warning("Selected client count is 0");
      return;
    }
    if(this.smVariableText!=null && this.smVariableText!=''){
        this.smPrefixVariableText = "";
    }
    else{
        this.smPrefixVariableText = "कृपया अधिक माहितीसाठी संपर्क साधा.";
    }

    this.serverAPIService.sendBulkWhatsApp(this.smsFixedText+this.smPrefixVariableText+this.smVariableText+' --'+this.serverAPIService.getCustomerName()+','+this.serverAPIService.getCAMobile()+'.', this.fyYear,this.prd,
      this.taskId,this.selectedStatus,this.arrWorkDetailsID)
     .subscribe(
       (response:any) => {
        this.toaster.success('Message(s) will be sent shortly!');
       },
       (error) => {
         this.spinnerService.hide();
         this.toaster.error('Something went wrong!');
       }
     );
  }

  goToWhatsApp(EntityName:string,SendToMobile:string,WorkPeriod:string){
      if (this.smVariableText != null && this.smVariableText != "") {
        this.smPrefixVariableText =
          "त्याबद्दलची अधिकची माहिती खालील प्रमाणे, " + this.smVariableText;
      } else {
        this.smPrefixVariableText = "कृपया अधिक माहितीसाठी संपर्क साधा.";
      }

    let text = '';
    let tempFixedText=this.serverAPIService.whatsappTemplate;
    tempFixedText=tempFixedText.replace("{OwnerName}",EntityName);
    let taskName=this.taskarray.filter(o=>o.TaskID==this.taskId)[0].TaskName;
    tempFixedText=tempFixedText.replace("{TaskName}",taskName);
    if(this.selectedStatus.toLowerCase() == 'open')
    {
      tempFixedText=tempFixedText.replace("{TaskStatus}",this.openStatusText);
    }
    else
    {
      tempFixedText=tempFixedText.replace("{TaskStatus}",this.selectedStatus);
    }
    tempFixedText=tempFixedText.replace("{Period}",WorkPeriod);
    tempFixedText=tempFixedText.replace("<br>","");

    text = tempFixedText+this.smPrefixVariableText+' --'+this.serverAPIService.getCustomerName()+','+this.serverAPIService.getCAMobile()+'.';
    window.open('https://wa.me/91'+SendToMobile+'?text='+text,'blank');
  }

  getProoduct(isSelected, product){
    const index: number = this.arrWorkDetailsID.indexOf(product);
    if(isSelected){
      if (index == -1) {
        this.arrWorkDetailsID.push(product);
      }
    }
    else{
      if (index !== -1) {
        this.arrWorkDetailsID.splice(index, 1);
        this.isChecked = false;
      }
    }
    if(this.arrWorkDetailsID.length == this.alertTaskCountData.length)
      this.isChecked = true;
  }
}
