import { Component, OnInit, AfterViewInit, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ServerAPIService } from 'src/app/serverapi.service';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from 'src/app/spinner.service';
import { Receipt } from '../receipt.model';
import { Firm } from 'src/app/masters/firm.model';
declare let $: any;

@Component({
  selector: 'app-print-receipt',
  templateUrl: './print-receipt.component.html',
  styleUrls: ['./print-receipt.component.css']
})
export class PrintReceiptComponent implements OnInit,AfterViewInit,AfterContentChecked {
  receiptId:number;
  receipt:Receipt;
  firm:Firm;

  constructor(private serverAPIService:ServerAPIService,private route : ActivatedRoute,
    private router:Router, private toaster:ToastrService, private spinnerService:SpinnerService
    ,private ref: ChangeDetectorRef) {
  }

  ngAfterViewInit(){
  }
  

  ngAfterContentChecked() {
    this.ref.detectChanges();
  }

  ngOnInit() {
    this.spinnerService.show();
    this.route.params
    .subscribe(
      (params: Params) => {
        if(params["id"] != null){
          this.receiptId=parseInt( params["id"]);
          this.getBillById(this.receiptId);
        } 
      }
    );
     
  }

  getBillById(id : number){
   
    this.serverAPIService.getreceiptById(id)
    .subscribe(
      (data:Receipt) => {
        this.receipt=data;
        this.getFirmById(this.receipt.FirmID);
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error('Something went wrong!');
      }
    );
  }

  getFirmById(id : number){
    this.spinnerService.show();
    this.serverAPIService.getFirmById(id)
    .subscribe(
      (data:Firm) => {
        this.firm=data;
        this.spinnerService.hide();
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error('Something went wrong!');
      }
    );
  }

  print(): void {
    let printContents, popupWin;
    printContents = document.getElementById('print-section').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
        <link rel="stylesheet" href="/assets/vendors/mdi/css/materialdesignicons.min.css">
        <link rel="stylesheet" href="/assets/vendors/css/vendor.bundle.base.css">
        <link rel="stylesheet" href="/assets/css/style.css">
          <title>Print tab</title>
          <style>
          .right{text-align: right;}
          .h3, h3 {
            font-size: 1.75rem;
          }
          .h2, h2 {
            font-size: 2rem;
          }
          p,body {
            font-size: 1.3rem;
            line-height: 1.5;
          }
          .table th, .table td{
            font-size:1.3rem;
          }
          .h4, h4 {
            font-size: 1.5rem;
          }
          .h5, h5 {
            font-size: 1.25rem;
          }
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
}
}
