import { Component, OnInit, AfterViewInit, HostListener } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { ServerAPIService } from 'src/app/serverapi.service';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from 'src/app/spinner.service';
import { Subject } from 'rxjs';
import { TaskwiseCount, CustomerWiseGstCount, CustomerWiseITCount, CustomerWiseOtherCount, TaskPopup } from '../dashboard.model';
import { NgbModal, NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl } from '@angular/forms';
import { CustomDateAdapter, CustomDateParserFormatter } from 'src/app/custom-ngbDateParserFormatter';
import { DataTableDirective } from 'angular-datatables';
declare let $: any;

@Component({
  selector: 'app-task-count',
  templateUrl: './task-count.component.html',
  styleUrls: ['./task-count.component.css'],
  providers: [{provide: NgbDateAdapter, useClass: CustomDateAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}]
})
export class TaskCountComponent implements OnInit,AfterViewInit {
  assitants:TaskwiseCount[];
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  dtTrigger1: Subject<any> = new Subject();
  dtTrigger2: Subject<any> = new Subject();
  dtTrigger3: Subject<any> = new Subject();
  servicetp:string="";
  gstTasks:CustomerWiseGstCount[];
  gstTasksact:CustomerWiseGstCount[];
  itTasks:CustomerWiseITCount[];
  itTasksact:CustomerWiseITCount[];
  otherTasks:CustomerWiseOtherCount[];
  otherTasksact:CustomerWiseOtherCount[];
  id:number=1;
  isCstAn:number=1;
  taskDetails:TaskPopup[];
  taskNm:string='';
  profileForm = new FormGroup({
    txtFromDate: new FormControl(''),
    txtToDate:new FormControl('')
  });
  currentDate = new Date();
  dtElement: DataTableDirective;

  get f() { return this.profileForm.controls; }

  constructor(private serverAPIService:ServerAPIService,
    private router:Router, private toaster:ToastrService, private spinnerService:SpinnerService
    ,private route : ActivatedRoute,private modalService: NgbModal) {
      this.dtOptions = {
        processing: true,
        pageLength:12,
        order:[],
         destroy: true,
        responsive: true,
      }
  }

  viewRecord(){
  // $('#example').DataTable().destroy();
     this.assitants=[];
     this.gstTasks=[];
     this.itTasks=[];
     this.otherTasks=[];
     this.dtTrigger=new Subject();
    this.dtTrigger.next();
    this.dtTrigger1=new Subject();
    this.dtTrigger1.next();
    this.dtTrigger2=new Subject();
    this.dtTrigger2.next();
    this.dtTrigger3=new Subject();
    this.dtTrigger3.next();
     setTimeout(() => {
      if(this.servicetp=="GST"){
        this.getGstData();
      }
      else if(this.servicetp=="INCOME TAX"){
        this.getItData();
      }
      else{
        this.getOtherData();
      }
      this.getData(this.servicetp);
    },0)

   }

  open(content,ServiceID:number,TaskID:number,WorkStatus:string,taskName:string) {
    this.taskDetails=[];
    this.taskNm=taskName;
    this.spinnerService.show();
    this.serverAPIService.getTaskPopupData(ServiceID,TaskID,WorkStatus,this.servicetp,this.f.txtFromDate.value,this.f.txtToDate.value)
    .subscribe(
      (response : TaskPopup[]) => {
        this.spinnerService.hide();
        this.taskDetails=response;
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error("Something went wrong!");
      }
    )
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',size:'lg'});
  }


  ngOnInit() {
    let day:number=this.currentDate.getDate();
    let month:number=this.currentDate.getMonth();
    let year:number=this.currentDate.getFullYear();
    if(month==12){
      month=0;
      year=year+1;
    }
   // alert(this.currentDate);
    this.f.txtFromDate.setValue('01/'+((month+1).toString().length==1 ?'0'+(month+1).toString() :(month+1).toString())
      +'/'+year.toString());
    this.f.txtToDate.setValue((this.daysInThisMonth(year,(month+1)))+'/'+((month+1).toString().length==1
      ?'0'+(month+1).toString()
      :(month+1).toString())
      +'/'+year.toString());
  }

  daysInThisMonth(yr:number,mo:number) {
    var now = new Date();
    return new Date(yr, mo, 0).getDate();
  }


  switchData(id1:number){
    this.isCstAn = id1;
    this.id=id1;
    if(id1==2 && this.servicetp=="INCOME TAX")
      this.id=3;
    else if(id1==2 && this.servicetp=="OTHER"){
      this.id=4;
    }

    if(id1==2){
      let month:number=this.currentDate.getMonth();
      let year:number=this.currentDate.getFullYear();
      if(month==12){
        month=0;
        year=year+1;
      }

      if(this.servicetp=="INCOME TAX"){
        let mon=this.currentDate.getMonth();
        let year=this.currentDate.getFullYear();
       // alert(mon+' - '+year);
        mon=mon+1;
        if(mon<3)
          year=year-1;
        let nextyear=year+1;
        this.f.txtFromDate.setValue('01/'+'04/'+year);
        this.f.txtToDate.setValue('31/03/'+nextyear.toString());
      }
      else{
        // this.f.txtFromDate.setValue('01/'+((month+1).toString().length==1 ?'0'+(month+1).toString() :(month+1).toString())
        //   +'/'+year.toString());
        // this.f.txtToDate.setValue((this.daysInThisMonth(year,(month+1)))+'/'+((month+1).toString().length==1
        //   ?'0'+(month+1).toString()
        //   :(month+1).toString())
        //   +'/'+year.toString());
        let txtDateValue:String = this.f.txtFromDate.value;
        let m:number = parseInt(txtDateValue.split("/")[1]);
        let y:number = parseInt(txtDateValue.split("/")[2]);

        this.f.txtFromDate.setValue('01/'+((m).toString().length==1 ?'0'+(m).toString() :(m).toString())
           +'/'+y.toString());
           this.f.txtToDate.setValue((this.daysInThisMonth(y,(m)))+'/'+((m).toString().length==1
          ?'0'+(m).toString()
          :(m).toString())
          +'/'+y.toString());
      }
      // $('#example').DataTable().destroy();
    }
    if(id1!=1 && this.servicetp=='GST'){
     $('#example1').DataTable().destroy();
     this.gstTasks=[];
      this.dtTrigger1=new Subject();
      this.dtTrigger1.next();
      setTimeout(() => {
        this.gstTasks=this.gstTasksact;
        this.dtTrigger1.next();

      },0)
    }
   else if(id1!=1 && this.servicetp=='INCOME TAX'){
      $('#example2').DataTable().destroy();
      this.itTasks=[];
       this.dtTrigger2=new Subject();
       this.dtTrigger2.next();
       setTimeout(() => {
         this.itTasks=this.itTasksact;
         this.dtTrigger2.next();

       },0)
     }
     else if(id1!=1 && this.servicetp=='OTHER'){
      $('#example3').DataTable().destroy();
      this.otherTasks=[];
       this.dtTrigger3=new Subject();
       this.dtTrigger3.next();
       setTimeout(() => {
         this.otherTasks=this.otherTasksact;
         this.dtTrigger3.next();

       },0)
     }

    if(id1==1){
        this.assitants=[];
        // $('#example').DataTable().destroy();
        // this.dtTrigger.closed=false;
        // this.dtTrigger.is
        this.dtTrigger=new Subject();
        this.dtTrigger.next();

        setTimeout(() => {
         this.getData(this.servicetp);
       },0)
    }

  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
      this.dtTrigger1.next();
      this.dtTrigger2.next();
      this.dtTrigger3.next();
    });
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    this.dtTrigger1.unsubscribe();
    this.dtTrigger2.unsubscribe();
    this.dtTrigger3.unsubscribe();
  }

  ngAfterViewInit(): void{
     $(".btn-group > .btn").click(function(){
      $(this).addClass("active").siblings().removeClass("active");
    });
    setTimeout(() => {
      this.spinnerService.show();
      this.route.params
      .subscribe(
        (params: Params) => {
          if(params["tp"] != null){
            if(params["tp"]=="GST"){
              this.servicetp=params["tp"];
              //this.id=2;
              this.getGstData();
            }
            else if(params["tp"]=="IT"){
              this.servicetp="INCOME TAX";
              //this.id=3;
              let mon=this.currentDate.getMonth();
              let year=this.currentDate.getFullYear();
             // alert(mon+' - '+year);
              mon=mon+1;
              if(mon<3)
                year=year-1;
              let nextyear=year+1;
              this.f.txtFromDate.setValue('01/'+'04/'+year);
              this.f.txtToDate.setValue('31/03/'+nextyear.toString());
              this.getItData();
            }
            else{
              this.servicetp=params["tp"];
              //this.id=4;
              let mon=this.currentDate.getMonth();
              let year=this.currentDate.getFullYear();
             // alert(mon+' - '+year);
              mon=mon+1;
              if(mon<3)
                year=year-1;
              let nextyear=year+1;
              this.f.txtFromDate.setValue('01/'+'04/'+year);
              this.f.txtToDate.setValue('31/03/'+nextyear.toString());
              this.getOtherData();
            }
            this.getData(this.servicetp);
          } else{
            this.spinnerService.hide();
          }
        }
      );
    });
  }

  getData(tp:string){
    this.spinnerService.show();
    this.serverAPIService.getTaskwiseCount(tp,this.f.txtFromDate.value,this.f.txtToDate.value)
    .subscribe(
      (response : TaskwiseCount[]) => {
        this.spinnerService.hide();
        this.assitants=response;
       this.dtTrigger.next();
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error("Something went wrong!");
      }
    )
  }

  getGstData(){
    this.spinnerService.show();
    this.serverAPIService.getGSTCustomerWiseTasks(this.f.txtFromDate.value)//pass from date
    .subscribe(
      (response : CustomerWiseGstCount[]) => {
        this.spinnerService.hide();
        this.gstTasks=response;
        this.gstTasksact=response;
        this.dtTrigger1.next();
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error("Something went wrong!");
      }
    )
  }

  getItData(){
    this.spinnerService.show();
    this.serverAPIService.getITCustomerWiseTasks()
    .subscribe(
      (response : CustomerWiseITCount[]) => {
        this.spinnerService.hide();
        this.itTasks=response;
        this.itTasksact=response;
        this.dtTrigger2.next();
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error("Something went wrong!");
      }
    )
  }

  getOtherData(){
    this.spinnerService.show();
    this.serverAPIService.getOtherCustomerWiseTasks(this.f.txtFromDate.value)//pass from date
    .subscribe(
      (response : CustomerWiseOtherCount[]) => {
        this.spinnerService.hide();
        this.otherTasks=response;
        this.otherTasksact=response;
        this.dtTrigger3.next();
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error("Something went wrong!");
      }
    )
  }

  SetToDate(event){
    console.log();
  }
//   @HostListener('window:scroll', ['$event']) getScrollHeight(event) {
//     //console.log(window.pageYOffset, event);
//     if(window.innerWidth>=1024){
//     if (window.pageYOffset > 135) {
//       $('.statusdiv').addClass('fixed');
//     } else {
//         $('.statusdiv').removeClass('fixed');
//     }
//   }
//  }

}
