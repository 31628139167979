import { Component, OnInit, AfterViewInit, ChangeDetectorRef, AfterContentChecked, ViewChild } from '@angular/core';
import { Router,  ActivatedRoute } from '@angular/router';
import { ServerAPIService } from 'src/app/serverapi.service';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from 'src/app/spinner.service';
import { Subject } from 'rxjs';
import { NgbModal, NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl } from '@angular/forms';
import { CustomDateAdapter, CustomDateParserFormatter } from 'src/app/custom-ngbDateParserFormatter';
import { DataTableDirective } from 'angular-datatables';
import { InwardOutward } from './inward-outward.model';

@Component({
  selector: 'app-inward-outward',
  templateUrl: './inward-outward.component.html',
  styleUrls: ['./inward-outward.component.css'],
  providers: [{provide: NgbDateAdapter, useClass: CustomDateAdapter},
  {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}]
})
export class InwardOutwardComponent implements OnInit,AfterViewInit,AfterContentChecked {
  inout:InwardOutward[];
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  profileForm = new FormGroup({
    txtFromDate: new FormControl(''),
    txtToDate:new FormControl('')
  });
  currentDate = new Date();
  tempInId:number=0;
  tempDate:string='';
  tempType:string='';
  
  get f() { return this.profileForm.controls; }

  constructor(public serverAPIService:ServerAPIService,
    private router:Router, private toaster:ToastrService, private spinnerService:SpinnerService
    ,private route : ActivatedRoute,private modalService: NgbModal,private ref: ChangeDetectorRef) {
      this.dtOptions = {
        processing: true,
        paging:false,
        order:[],
        destroy: true,
        responsive: true,
      }
  }

  deleteconfirm(content,dateinout:string, typeinout:string,id:number){
    this.tempInId=0;
    this.tempDate='';
    this.tempType='';

    this.tempInId=id;
    this.tempDate=dateinout;
    this.tempType=typeinout;
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'});
  }

  deleteInOut(){
    this.serverAPIService.deleteInOut(this.tempInId)
        .subscribe(
          (response : any) => {
            this.toaster.success("Record deleted successfully!");
            this.modalService.dismissAll();
            $('#example').DataTable().destroy();
            this.inout=[];
            this.dtTrigger.next();
             this.getData();
          },
          (error) => {
            this.spinnerService.hide();
            this.toaster.error("Something went wrong!");
          }
        )
  }

 
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
   }

  ngAfterContentChecked() {
    this.ref.detectChanges();
  }

  viewRecord(){
   $('#example').DataTable().destroy();
     this.inout=[];
     this.dtTrigger.next();
      this.getData();
   }

   getData(){
    this.spinnerService.show();
    this.serverAPIService.getInOut(this.f.txtFromDate.value,this.f.txtToDate.value)
    .subscribe(
      (response : InwardOutward[]) => {
        this.spinnerService.hide();
        this.inout=response;
        this.dtTrigger.next();
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error("Something went wrong!");
      }
    )
  }

  ngOnInit() {
    let day:number=this.currentDate.getDate();
    let month:number=this.currentDate.getMonth();
    let year:number=this.currentDate.getFullYear();
    if(month==12){
      month=0;
     // year=year+1;
    }
    this.f.txtFromDate.setValue('01'+'/'+'04'+'/'+(month<4?(year-1).toString(): year.toString()));
    this.f.txtToDate.setValue(((day).toString().length==1 ?'0'+(day).toString() :(day).toString())+'/'+((month+1).toString().length==1 ?'0'+(month+1).toString() :(month+1).toString())
    +'/'+year.toString());
      this.inout=[];
  }

  daysInThisMonth(yr:number,mo:number) {
    var now = new Date();
    return new Date(yr, mo, 0).getDate();
  }

  ngAfterViewInit(): void{
    setTimeout(() => {
      this.getData();
    });
  }



}
