export class PendingTasksReport {
  public WorkDetailsID: number;
  public EntityName: string;
  public TaskName: string;
  public ServiceName: string;
  public DueDate?: Date;
  public DueDateStr: string;
  public WorkStatus: string;
  public WorkPeriod: string;
  public AssistantNameList: string;

  public WorkID:number;
  public ClientID:number;
  public TaskID:number;

  public ClientName:string;
  public OwnersMobile:string;
  public ContactPersonMobile:string;

  public StatusDetails:string;
  public FileNo:string;
  public ServiceType:string;
  public ClientFileNo:string;
  public Comment:string;

  public  VerificationType:string;
  public  VerificationDate:Date;
  public  VerificationDateStr:string;

  public isSelected:boolean;

  constructor() {}
}
