import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from 'src/app/spinner.service';
import { ServerAPIService } from 'src/app/serverapi.service';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { Work } from 'src/app/user-service/work.model';
import { FinancialYear } from 'src/app/fy.model';
import { WorkDetails } from 'src/app/user-service/work-details.model';
import { DataTableDirective } from 'angular-datatables';
import { UserProfile } from 'src/app/user-profile/user-profile.model';

@Component({
  selector: "app-unassigned-tasks",
  templateUrl: "./unassigned-tasks.component.html",
  styleUrls: ["./unassigned-tasks.component.css"],
})
export class UnassignedTasksComponent implements OnInit {
  fylist: FinancialYear[];
  work: Work[];
  workassigned: Work[];
  workAct: Work[];
  fydata: string[];
  fyYear: string = "";
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  dtTrigger1: Subject<any> = new Subject();
  selectedTasks: boolean = false;
  dtElement: DataTableDirective;
  inOutCid: number = 0;
  isChecked: boolean = false;
  arrWorkID: number[] = [];
  astarray:object[];
  selectedAssistant: number = 0;

  isCheckedFromAssignedTasks: boolean = false;
  arrWorkIDFromAssignedTasks: number[] = [];

  constructor(
    private serverAPIService: ServerAPIService,
    private router: Router,
    private toaster: ToastrService,
    private spinnerService: SpinnerService,
    private route: ActivatedRoute,
    private ref: ChangeDetectorRef
  ) {
    this.dtOptions = {
      paging: false,
      processing: true,
      destroy: true,
      responsive: true,
      order: [],
    };
  }

  ngOnInit(): void {
    $(".btn-group > .btn").click(function () {
      $(this).addClass("active").siblings().removeClass("active");
    });
    this.route.params.subscribe((params: Params) => {
      if (params["clientId"] != null) {
        this.inOutCid = parseInt(params["clientId"]);
      }
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.getFy();
      this.getAssistant();
    });
  }

  getFy() {
    this.spinnerService.show();
    this.serverAPIService.getUsersFy().subscribe(
      (response: FinancialYear[]) => {
        this.fylist = response;
        this.fydata = [""];
        response.forEach((role) => {
          this.fydata.push(role.FinancialYear);
        });
        this.fydata.shift;
        this.serverAPIService.getCurrentFy().subscribe(
          (response: any) => {
            this.fyYear = response;
            if (this.route.snapshot.queryParams["fyYear"] != null) {
              this.fyYear = this.route.snapshot.queryParams["fyYear"];
            }
            this.getUnassignedTasks();
            this.spinnerService.hide();
          },
          (error) => {}
        );
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error("Something went wrong!");
      }
    );
  }

  changeFy(fy: string) {
    this.fyYear = fy;
    if (this.selectedTasks == false) {
      $("#example").DataTable().destroy();
      this.work = [];
      this.dtTrigger.next();
      setTimeout(() => {
        //Call getUnassignedTask
        this.getUnassignedTasks();
      }, 0);
      //this.dtTrigger.next();
    } else if (this.selectedTasks == true) {
      $("#example1").DataTable().destroy();
      this.workassigned = [];
      this.dtTrigger1.next();
      setTimeout(() => {
        //Call getUnassignedTask
        this.getUnassignedTasks();
      }, 0);
      //this.dtTrigger1.next();
    }
  }

  getUnassignedTasks() {
    this.spinnerService.show();

    this.serverAPIService.getUnassignedTasks(this.fyYear).subscribe(
      (response: Work[]) => {
        this.workAct = response;
        if (this.selectedTasks == true) {
          $("#example1").DataTable().destroy();
          this.workassigned = [];
          this.dtTrigger1.next();
          if (this.inOutCid > 0)
            this.workassigned = this.workAct
              .filter((x) => x.AssistantMap?.length > 0)
              .filter((x) => x.ClientID == this.inOutCid);
          else
            this.workassigned = this.workAct.filter(
              (x) => x.AssistantMap?.length > 0
            );
          this.dtTrigger1.next();
        }
        if (this.selectedTasks == false) {
          $("#example").DataTable().destroy();
          this.work = [];
          this.dtTrigger.next();
          if (this.inOutCid > 0)
            this.work = this.workAct
              .filter((x) => x.AssistantMap?.length <= 0)
              .filter((x) => x.ClientID == this.inOutCid);
          else
            this.work = this.workAct.filter((x) => x.AssistantMap?.length <= 0);
          this.dtTrigger.next();
        }
        this.spinnerService.hide();
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error("Something went wrong!");
      }
    );
  }

  switchTasks(isAssigned: boolean) {
    this.inOutCid = 0;
    this.route.params.subscribe((params: Params) => {
      if (params["clientId"] != null) {
        this.inOutCid = parseInt(params["clientId"]);
      }
    });
    this.spinnerService.show();
    setTimeout(() => {
      if (isAssigned) {
        $("#example1").DataTable().destroy();
        this.workassigned = [];
        this.dtTrigger1 = new Subject();
        this.dtTrigger1.next();
        this.selectedTasks = isAssigned;
        setTimeout(() => {
          //Call getUnassignedTask
          if (this.inOutCid > 0)
            this.workassigned = this.workAct
              .filter((x) => x.AssistantMap?.length > 0)
              .filter((x) => x.ClientID == this.inOutCid);
          else
            this.workassigned = this.workAct.filter(
              (x) => x.AssistantMap?.length > 0
            );
          this.dtTrigger1.next();
        }, 0);

        this.spinnerService.hide();
      }
      if (isAssigned == false) {
        $("#example").DataTable().destroy();
        this.work = [];
        this.dtTrigger = new Subject();
        this.dtTrigger.next();
        this.selectedTasks = isAssigned;
        setTimeout(() => {
          //Call getUnassignedTask
          if (this.inOutCid > 0)
            this.work = this.workAct
              .filter((x) => x.AssistantMap?.length <= 0)
              .filter((x) => x.ClientID == this.inOutCid);
          else
            this.work = this.workAct.filter((x) => x.AssistantMap?.length <= 0);
          this.dtTrigger.next();
        }, 0);

        this.spinnerService.hide();
      }
    }, 0);
  }

  setFyYear() {
    this.route.params.subscribe((params: Params) => {
      if (params["fyYear"] != null) {
      }
    });
  }

  //////////////////////////////////////////////////////////////////////////
  //Unassigned Tasks - Bulk Assign Start
  //////////////////////////////////////////////////////////////////////////
  selectAllClients(isSelectAll: boolean) {
    if (isSelectAll) {
      //alert("Select All");
      for (let row of this.work) {
        row.isSelected = true;
      }
      this.arrWorkID = this.work.map((a) => a.WorkID);
    } else {
      //alert("Unselect All");
      for (let row of this.work) {
        row.isSelected = false;
      }
      this.arrWorkID = [];
    }
  }

  getProoduct(isSelected, product) {
    const index: number = this.arrWorkID.indexOf(product);
    if (isSelected) {
      if (index == -1) {
        this.arrWorkID.push(product);
      }
    } else {
      if (index !== -1) {
        this.arrWorkID.splice(index, 1);
        this.isChecked = false;
      }
    }
    if (this.arrWorkID.length == this.work.length) this.isChecked = true;

  }

  assignBulkTasks(){
    if(this.arrWorkID.length <= 0){
      this.toaster.warning('Please select at least one task to assign!');
      return;
    }

    this.serverAPIService.assignBulkTasks(this.selectedAssistant,this.arrWorkID)
     .subscribe(
       (response:any) => {
        this.selectAllClients(false);
        this.isChecked = false;
        this.changeFy(this.fyYear);
        this.selectedAssistant = 0;
        this.toaster.success('Task Assigned Successfully!');
       },
       (error) => {
         this.spinnerService.hide();
         this.toaster.error('Something went wrong!');
       }
     );
  }
  //////////////////////////////////////////////////////////////////////////
  //Unassigned Tasks - Bulk Assign End
  //////////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////////
  //Assigned Tasks - Bulk Assign Start
  //////////////////////////////////////////////////////////////////////////
  selectAllAssignedClients(isSelectAll: boolean) {
    if (isSelectAll) {
      //alert("Select All");
      for (let row of this.workassigned) {
        row.isSelected = true;
      }
      this.arrWorkIDFromAssignedTasks = this.workassigned.map((a) => a.WorkID);
    } else {
      //alert("Unselect All");
      for (let row of this.workassigned) {
        row.isSelected = false;
      }
      this.arrWorkIDFromAssignedTasks = [];
    }
  }

  getProoductAssigned(isSelected, product) {
    const index: number = this.arrWorkIDFromAssignedTasks.indexOf(product);
    if (isSelected) {
      if (index == -1) {
        this.arrWorkIDFromAssignedTasks.push(product);
      }
    } else {
      if (index !== -1) {
        this.arrWorkIDFromAssignedTasks.splice(index, 1);
        this.isCheckedFromAssignedTasks = false;
      }
    }
    if (this.arrWorkIDFromAssignedTasks.length == this.workassigned.length) this.isCheckedFromAssignedTasks = true;

  }

  assignBulkTasksFromAssignedTasks(){
    if(this.arrWorkIDFromAssignedTasks.length <= 0){
      this.toaster.warning('Please select at least one task to assign!');
      return;
    }

    this.serverAPIService.assignBulkTasks(this.selectedAssistant,this.arrWorkIDFromAssignedTasks)
     .subscribe(
       (response:any) => {
        this.selectAllAssignedClients(false);
        this.isCheckedFromAssignedTasks = false;
        this.changeFy(this.fyYear);
        this.selectedAssistant = 0;
        this.toaster.success('Task Assigned Successfully!');
       },
       (error) => {
         this.spinnerService.hide();
         this.toaster.error('Something went wrong!');
       }
     );
  }
  //////////////////////////////////////////////////////////////////////////
  //Assigned Tasks - Bulk Assign End
  //////////////////////////////////////////////////////////////////////////

  getAssistant(){
    this.serverAPIService.getAssistants()
    .subscribe(
      (response : UserProfile[]) => {
        this.astarray = [{ id: 0, text: 'Select Assistant'}];
        response.forEach(element => {
          this.astarray.push({id:element.AppUserID,text:element.AppUserName});
         });
      },
      () => {
        this.spinnerService.hide();
        this.toaster.error("Something went wrong!");
      }
    )
  }
}
