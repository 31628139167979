import { Component, OnInit, AfterViewInit, AfterContentChecked, ChangeDetectorRef, HostListener, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ServerAPIService } from 'src/app/serverapi.service';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from 'src/app/spinner.service';
import { Subject } from 'rxjs';
import { WorkDetails, TaskList } from 'src/app/user-service/work-details.model';
import { NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { MmyyDateAdapter, MmyyDateParserFormatter } from 'src/app/dateformat';
import { UserProfile } from 'src/app/user-profile/user-profile.model';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { StatusChange } from 'src/app/user-service/statuschange.model';
import { Client } from 'src/app/masters/client.model';
import { SmsLog } from '../smsLog.model';
import { FinancialYear } from 'src/app/fy.model';
import { DatePipe } from '@angular/common';
import { CustomDateParserFormatter, CustomDateAdapter } from 'src/app/custom-ngbDateParserFormatter';
import { CAInfo } from 'src/app/cainfo.model';
import {myFunction} from 'src/assets/js/copy1.js';
declare let $: any;
declare const selectNode: any;
@Component({
  selector: 'app-pending-task-list',
  templateUrl: './pending-task-list.component.html',
  styleUrls: ['./pending-task-list.component.css'],
  providers: [{provide: NgbDateAdapter, useClass: MmyyDateAdapter},
    {provide: NgbDateParserFormatter, useClass: MmyyDateParserFormatter},DatePipe
  ]
})
export class PendingTaskListComponent implements OnInit,AfterViewInit,AfterContentChecked {
  workDetails:WorkDetails[];
  workDetailsAct:WorkDetails[];
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  monthstr:string;
  appuserId:number;
  assistantId:number;
  public tableWidget: any;
  tStatus:string='Selected Month';
  tstArray:string[];
  closeResult: string;
  wStatus:string;
  wStatusArray:string[];
  workDetailsText:string;
  commentsText:string='';

  tempClientId:number=0;
  tempWorkDetailsId:number=0;
  tempWorkId:number=0;
  tempTaskId:number=0;
  tempEntity:string='';
  tempTaskNm:string='';
  tempPeriod:string='';
  tempDueDate:string='';
  tempCurrentStatus:string='';
  tempSendToMobile:string='';

  openCnt:number=0;
  partialDataCnt:number=0;
  dataRecCnt:number=0;
  wipCnt:number=0;
  pendingForTaxCnt:number=0;
  appliedCnt:number=0;
  holdCnt:number=0;
  pendingCnt:number=0;
  completedCnt:number=0;
  accCnt:number=0;

  stserv:StatusChange[];
  dateHeading:string='Month';
  detailsHead:string='Details';

  cliEntity:string='';
  cliEmail:string='';
  cliOwner:string='';
  cliOwnerMob:string='';
  cliConPerson:string='';
  cliConMob:string='';
  cliGstin:string='';
  cliGSTRegType:string='';
  cliGSTUserName:string='';
  cliGSTRegDateString:string='';
  cliPAN:string='';
  cliITReturnUserName :string='';
  cliPTEC:string='';
  cliPTECAmount:string='';
  cliTAN :string='';
  cliContactDetails :string='';
  cliPhyRec:string='';
  cliFileNo:string='';
  serviceType='GST';
  taskNm:string='All';
  taskListorg:TaskList[];
  taskList:TaskList[];
  senderId:string='';
  whatsAppSenderId='';
  smslg:SmsLog[];
  cliPTRC:string='';

  isPeriodWise:boolean=false;
  fylist:FinancialYear[];
  fydata:string[];
  fyYear:string='';
  period:string[]=['All','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec','Jan','Feb','Mar','Q-1','Q-2','Q-3','Q-4']
  prd:string='';
  workSt:string="All Pending";

  vefificationType:string[]=['E Verified','By Post'];
  vType:string='';
  vdate:string='';
  vVis:boolean=false;

  smsFixedText:string='';
  smVariableText:string='';
  smPrefixVariableText:string='';
  tempRemark:string='';
  sendsms:boolean=true;

  openStatusText:string='Pending';


  constructor(public serverAPIService:ServerAPIService,private route : ActivatedRoute,
    private router:Router, private toaster:ToastrService, private spinnerService:SpinnerService
    ,private ref: ChangeDetectorRef,private modalService: NgbModal,private datePipe: DatePipe,private el: ElementRef, ) {
      this.dtOptions = {
        paging:false,
        processing: true,
        destroy: true,
        responsive: true,
        order:[],
      }
  }

  sendAlt(WorkDetailsID:number,Remark:string){
    this.modalService.dismissAll();
    this.spinnerService.show();
    this.serverAPIService.sendAlert(WorkDetailsID,Remark,this.serverAPIService.getCustomerName()+','+this.serverAPIService.getCAMobile()+'.')
    .subscribe(
      (response : any) => {
       this.spinnerService.hide();
       this.toaster.success("Alert sent successfully!");
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error(error._body);
      }
    )
  }
  test() {
    myFunction();
    this.toaster.success("Copied successfully!");
  }
  sendAltWhatsApp(WorkDetailsID:number,Remark:string){
    this.modalService.dismissAll();
    if (this.whatsAppSenderId != null && this.whatsAppSenderId != "") {
      this.spinnerService.show();
      this.serverAPIService
        .sendAlertWhatsApp(
          WorkDetailsID,
          Remark,
          this.serverAPIService.getCustomerName()+','+this.serverAPIService.getCAMobile()+'.'
        )
        .subscribe(
          (response: any) => {
            this.spinnerService.hide();
            this.toaster.success("Alert sent successfully!");
          },
          (error) => {
            this.spinnerService.hide();
            this.toaster.error(error._body);
          }
        );
    }
    else{
      let text = '';
      if (this.tempRemark != null && this.tempRemark != "") {
        this.smPrefixVariableText = "त्याबद्दलची अधिकची माहिती खालील प्रमाणे, "+this.tempRemark;
      } else {
        this.smPrefixVariableText = "कृपया अधिक माहितीसाठी संपर्क साधा.";
      }
      text = this.smsFixedText+this.smPrefixVariableText+' --'+this.serverAPIService.getCustomerName()+','+this.serverAPIService.getCAMobile()+'.';
      text=text.replace("<br>","");
      window.open('https://wa.me/91'+this.tempSendToMobile+'?text='+text,'blank');
    }
  }

  public onStatusSelected(event) {
    const value = event.target.value;
    if(value!=''){
        this.wStatus=value;
        if(value=="Pending for Tax")
          this.detailsHead="Tax Amount";
        else
          this.detailsHead="Details";
        if(value=="Completed" && this.serviceType=="INCOME TAX")
          this.vVis=true;
        else this.vVis=false;
      }
 }

 public onVtypeSelected(event) {
  const value = event.target.value;
  if(value!=''){
      this.vType=value;
    }
}

  open(content,WorkDetailsID:number) {
    this.tempClientId=0;
    this.tempCurrentStatus='';
    this.tempDueDate='';
    this.tempEntity='';
    this.tempPeriod='';
    this.tempTaskId=0;
    this.tempTaskNm='';
    this.tempWorkDetailsId=0;
    this.tempWorkId=0;
    this.wStatus='';
    this.workDetailsText='';
    this.commentsText='';
    this.vType='';
    this.vdate='';
    this.vVis=false;

    let cl: WorkDetails = this.workDetails.filter(x => x.WorkDetailsID === WorkDetailsID)[0];

    this.tempClientId=cl.ClientID;
    this.tempCurrentStatus=cl.WorkStatus;
    var start = new Date(cl.DueDate);
    this.tempDueDate=cl.DueDate!=undefined?(start.getDate().toString() +'/'+
    (start.getMonth()+1).toString()+'/'+
    start.getFullYear().toString()):'';// new Date(Date.parse(cl.DueDate)).toString();;
    this.tempEntity=cl.ClientName;
    this.tempPeriod=cl.WorkPeriod;
    this.tempTaskId=cl.TaskID;
    this.tempTaskNm=cl.TaskName.trimRight();
    this.tempWorkDetailsId=WorkDetailsID;
    this.tempWorkId=cl.WorkID;
    this.commentsText=cl.Comment;
    if(this.tempTaskNm=="GST Registration"){
      this.wStatusArray=this.serverAPIService.GstRegServiceStatus;
    }
    else if(this.tempTaskNm=="GSTR 1"){
      this.wStatusArray=this.serverAPIService.GstMonthlyServiceStatus;
    }
    else if(this.tempTaskNm=="GSTR 3B"){
      this.wStatusArray=this.serverAPIService.GstMonthlyServiceStatus;
    }
    else if(this.tempTaskNm=="Accounting Yearly"){
      this.wStatusArray=this.serverAPIService.AccountingServiceStatus;
    }
    else if(this.tempTaskNm=="Income Tax Audit"){
      this.wStatusArray=this.serverAPIService.ITAuditServiceStatus;
    }
    else if(this.tempTaskNm=="Income Tax Return"){
      this.wStatusArray=this.serverAPIService.ITReturnServiceStatus;
    }
    else if(this.tempTaskNm=="PTEC"){
      this.wStatusArray=this.serverAPIService.PTECServiceStatus;
    }
    else if(this.tempTaskNm=="Assessment"){
      this.wStatusArray=this.serverAPIService.AssesmentServiceStatus;
    }
    else{
      this.wStatusArray=this.serverAPIService.GstMonthlyServiceStatus;
    }

    //this.el.nativeElement.ViewChild('#ddlStatus').focus();


    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'},).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      content.find('#ddlStatus').focus();
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  typeSelect(tp:boolean){
    this.sendsms=tp;
    if(this.sendsms){
      this.smsFixedText=this.serverAPIService.smsTemplate;
      this.smsFixedText=this.smsFixedText.replace("{OwnerName}",this.tempEntity);
      this.smsFixedText=this.smsFixedText.replace("{TaskName}",this.tempTaskNm);
      this.smsFixedText=this.smsFixedText.replace("{Period}",this.tempPeriod);
      if (this.tempCurrentStatus.toLowerCase() == "open") {
        this.smsFixedText = this.smsFixedText.replace(
          "{TaskStatus}",
          this.openStatusText
        );
      } else {
        this.smsFixedText = this.smsFixedText.replace(
          "{TaskStatus}",
          this.tempCurrentStatus
        );
      }
      //this.smsFixedText=this.smsFixedText.replace("{TaskStatus}",this.tempCurrentStatus);
    }
    else{
      this.smsFixedText=this.serverAPIService.whatsappTemplate;
      this.smsFixedText=this.smsFixedText.replace("{OwnerName}",this.tempEntity);
      this.smsFixedText=this.smsFixedText.replace("{TaskName}",this.tempTaskNm);
      this.smsFixedText=this.smsFixedText.replace("{Period}",this.tempPeriod);
      if (this.tempCurrentStatus.toLowerCase() == "open") {
        this.smsFixedText = this.smsFixedText.replace(
          "{TaskStatus}",
          this.openStatusText
        );
      } else {
        this.smsFixedText = this.smsFixedText.replace(
          "{TaskStatus}",
          this.tempCurrentStatus
        );
      }
      //this.smsFixedText=this.smsFixedText.replace("{TaskStatus}",this.tempCurrentStatus);
    }
  }

  openSendAlert(content,WorkDetailsID:number){
    this.sendsms = true;
    this.tempClientId=0;
    this.tempCurrentStatus='';
    this.tempDueDate='';
    this.tempEntity='';
    this.tempPeriod='';
    this.tempTaskId=0;
    this.tempTaskNm='';
    this.tempWorkDetailsId=0;
    this.tempWorkId=0;
    this.wStatus='';
    this.workDetailsText='';
    this.commentsText='';
    this.vType='';
    this.vdate='';
    this.vVis=false;
    this.tempRemark = '';
    this.tempSendToMobile = '';

    let cl: WorkDetails = this.workDetails.filter(x => x.WorkDetailsID === WorkDetailsID)[0];
    if((cl.OwnersMobile == null || cl.OwnersMobile == '') && (cl.ContactPersonMobile == null || cl.ContactPersonMobile == '')){
      this.toaster.error("Unable to send alert! Contact information not found.");
      return;
    }
    this.tempSendToMobile = (cl.OwnersMobile == null || cl.OwnersMobile == '') ? cl.ContactPersonMobile : cl.OwnersMobile;
    this.tempClientId=cl.ClientID;
    this.tempCurrentStatus=cl.WorkStatus;
    var start = new Date(cl.DueDate);
    this.tempDueDate=cl.DueDate!=undefined?(start.getDate().toString() +'/'+
    (start.getMonth()+1).toString()+'/'+
    start.getFullYear().toString()):'';// new Date(Date.parse(cl.DueDate)).toString();;
    this.tempEntity=cl.ClientName;
    this.tempPeriod=cl.WorkPeriod;
    this.tempTaskId=cl.TaskID;
    this.tempTaskNm=cl.TaskName.trimRight();
    this.tempWorkDetailsId=WorkDetailsID;
    this.tempWorkId=cl.WorkID;
    this.commentsText=cl.Comment;
    this.smsFixedText=this.serverAPIService.smsTemplate;
    this.smsFixedText=this.smsFixedText.replace("{OwnerName}",cl.ClientName);
    this.smsFixedText=this.smsFixedText.replace("{TaskName}",cl.TaskName.trimRight());
    this.smsFixedText=this.smsFixedText.replace("{Period}",cl.WorkPeriod);
    if (cl.WorkStatus.toLowerCase() == "open") {
      this.smsFixedText = this.smsFixedText.replace(
        "{TaskStatus}",
        this.openStatusText
      );
    } else {
      this.smsFixedText = this.smsFixedText.replace(
        "{TaskStatus}",
        cl.WorkStatus
      );
    }
    //this.smsFixedText=this.smsFixedText.replace("{TaskStatus}",cl.WorkStatus);
    this.smsFixedText=this.smsFixedText.replace("<br>","");
    this.tempRemark = cl.StatusDetails;


    if(this.tempTaskNm=="GST Registration"){
      this.wStatusArray=this.serverAPIService.GstRegServiceStatus;
    }
    else if(this.tempTaskNm=="GSTR 1"){
      this.wStatusArray=this.serverAPIService.GstMonthlyServiceStatus;
    }
    else if(this.tempTaskNm=="GSTR 3B"){
      this.wStatusArray=this.serverAPIService.GstMonthlyServiceStatus;
    }
    else if(this.tempTaskNm=="Accounting Yearly"){
      this.wStatusArray=this.serverAPIService.AccountingServiceStatus;
    }
    else if(this.tempTaskNm=="Income Tax Audit"){
      this.wStatusArray=this.serverAPIService.ITAuditServiceStatus;
    }
    else if(this.tempTaskNm=="Income Tax Return"){
      this.wStatusArray=this.serverAPIService.ITReturnServiceStatus;
    }
    else if(this.tempTaskNm=="PTEC"){
      this.wStatusArray=this.serverAPIService.PTECServiceStatus;
    }
    else if(this.tempTaskNm=="Assessment"){
      this.wStatusArray=this.serverAPIService.AssesmentServiceStatus;
    }
    else{
      this.wStatusArray=this.serverAPIService.GstMonthlyServiceStatus;
    }

    //this.el.nativeElement.ViewChild('#ddlStatus').focus();


    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'},).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      content.find('#ddlStatus').focus();
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  viewLog(content, WorkDetailsID:number){
    this.spinnerService.show();
    this.stserv= [];
    this.serverAPIService.getStatusChange(WorkDetailsID)
    .subscribe(
      (response : StatusChange[]) => {
       this.stserv=response;
       this.spinnerService.hide();
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error("Something went wrong!");
      }
    )

    if(this.senderId!=null && this.senderId !=''){
      this.spinnerService.show();
      this.serverAPIService.getSmsLog(WorkDetailsID)
      .subscribe(
        (response : SmsLog[]) => {
         this.smslg=response;
         this.spinnerService.hide();
        },
        (error) => {
          this.spinnerService.hide();
          this.toaster.error("Something went wrong!");
        }
      )
    }

    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',size:'lg'});
  }

  viewClientDetails(content, clid:number,filedetail:string){
    this.spinnerService.show();
    this.cliEntity='';
    this.cliEmail='';
    this.cliOwner='';
    this.cliOwnerMob='';
    this.cliConPerson='';
    this.cliConMob='';
    this.cliGstin='';
    this.cliGSTRegType='';
    this.cliGSTUserName='';
    this.cliGSTRegDateString='';
    this.cliPAN='';
    this.cliITReturnUserName='';
    this.cliPTEC='';
    this.cliPTECAmount='';
    this.cliPTRC='';
    this.cliTAN ='';
    this.cliContactDetails ='';
    this.cliPhyRec='';
    this.cliFileNo='';
    this.cliPhyRec=filedetail;

    this.serverAPIService.getClientById(clid)
    .subscribe(
      (response : Client) => {
        this.cliEntity=response.EntityName;
        this.cliEmail=response.Email;
        this.cliOwner=response.OwnersName;
        this.cliOwnerMob=response.OwnersMobile;
        this.cliConPerson=response.ContactPersonName;
        this.cliConMob=response.ContactPersonMobile;
        this.cliGstin=response.GSTIN;
        this.cliGSTRegType=response.GSTRegType;
        this.cliGSTUserName=response.GSTUserName;
        this.cliGSTRegDateString=response.GSTRegDateString;
        this.cliPAN=response.PAN;
        this.cliITReturnUserName=response.ITReturnUserName;
        this.cliPTEC=response.PTEC;
        this.cliPTECAmount=response.PTECAmount?.toString();
        this.cliPTRC=response.PTRC;
        this.cliTAN =response.TAN;
        this.cliContactDetails =response.ContactDetails;
        this.cliFileNo=response.FileNo;
       this.spinnerService.hide();
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error("Something went wrong!");
      }
    )

    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',size:'lg'});
  }

  updateStatus(){
    if(this.wStatus==''){
      this.toaster.warning('Select Status');
      return;
    }
    // if(this.wStatus !='Completed' && this.workDetailsText==''){
    //   this.toaster.warning('Enter work details');
    //   return;
    // }
    // if(this.wStatus=="Pending for Tax" &&  isNaN(parseFloat( this.workDetailsText))){
    //   this.toaster.warning('Enter tax amount');
    //   return;
    // }

    this.modalService.dismissAll();
    let wd1=new WorkDetails();
    wd1.ClientID=this.tempClientId;
    wd1.StatusDetails=this.workDetailsText;
    wd1.TaskID=this.tempTaskId;
    wd1.WorkDetailsID=this.tempWorkDetailsId;
    wd1.WorkID=this.tempWorkId;
    wd1.WorkStatus=this.wStatus;
    let cmt='';
    if(this.vType!=''){
      wd1.VerificationType=this.vType;
      cmt=cmt+this.vType;
      wd1.Comment=cmt;
    }
    if(this.vdate!=''){
      wd1.VerificationDateStr=this.vdate;
      cmt=cmt+' ,Date: '+this.vdate;
      wd1.Comment=cmt;
    }

    this.spinnerService.show();
    if (this.tableWidget) {
      this.tableWidget.destroy()
     this.tableWidget=null
    }
    this.serverAPIService.updateWorkDetails(wd1)
      .subscribe(
        (response : any) => {
          this.toaster.show("Task status updated successfully!!!");
          //this.spinnerService.hide();
          $('#example').DataTable().destroy();
          this.workDetails=null;
          this.dtTrigger.next();
          this.openCnt=0;
          this.partialDataCnt=0;
          this.dataRecCnt=0;
          this.wipCnt=0;
          this.pendingForTaxCnt=0;
          this.appliedCnt=0;
          this.holdCnt=0;
          this.completedCnt=0;
          this.accCnt=0;

          if(this.isPeriodWise){
            this.serverAPIService.getPeriodWiseTasks(this.fyYear,this.appuserId,this.assistantId,this.prd)
            .subscribe(
              (response : WorkDetails[]) => {
            //  this.tableWidget.destroy();
                //this.workDetails=response;
                this.workDetailsAct=response;
            // this.ref.detectChanges();
                this.dtTrigger.next();

                //---------------if workstatus is completed and task type = 'GST Registration' then get registration details
                if(this.wStatus.toLocaleLowerCase()=='completed' && this.tempTaskNm==this.serverAPIService.TaskGSTReg){
                  this.router.navigate(["/gst-details/"+this.tempClientId]);
                }


                if(this.workDetailsAct.length>0){
                  if(this.taskNm=="All"){
                    this.pendingCnt=this.workDetailsAct.filter(x => x.ServiceType==this.serviceType ).length;
                    let o: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Open" &&  x.ServiceType==this.serviceType );
                    this.openCnt=o.length;
                    let pdr: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Partial Data Received" &&  x.ServiceType==this.serviceType);
                    this.partialDataCnt=pdr.length;
                    let dr: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Data Received" &&  x.ServiceType==this.serviceType);
                    this.dataRecCnt=dr.length;
                    let wip: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Work in Progess" &&  x.ServiceType==this.serviceType);
                    this.wipCnt=wip.length;
                    let pft: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Pending for Tax" &&  x.ServiceType==this.serviceType);
                    this.pendingForTaxCnt=pft.length;
                    let app: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Applied" &&  x.ServiceType==this.serviceType);
                    this.appliedCnt=app.length;
                    let hld: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "On Hold" &&  x.ServiceType==this.serviceType);
                    this.holdCnt=hld.length;
                    let cmp: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Completed" &&  x.ServiceType==this.serviceType);
                    this.completedCnt=cmp.length;
                    let acc: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Accounting Completed" &&  x.ServiceType==this.serviceType);
                    this.accCnt=acc.length;
                    if(this.workSt=='All Pending')
                    {
                      this.workDetails=this.workDetailsAct.filter(x =>  x.WorkStatus != "Completed" &&  x.ServiceType==this.serviceType) ;
                    }
                    else{
                      this.workDetails=this.workDetailsAct.filter(x => x.WorkStatus == this.workSt &&  x.ServiceType==this.serviceType) ;
                    }
                  }
                  else{
                    this.pendingCnt=this.workDetailsAct.filter(x => x.ServiceType==this.serviceType && x.TaskName==this.taskNm ).length;
                    let o: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Open" &&  x.ServiceType==this.serviceType && x.TaskName==this.taskNm);
                    this.openCnt=o.length;
                    let pdr: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Partial Data Received" &&  x.ServiceType==this.serviceType && x.TaskName==this.taskNm);
                    this.partialDataCnt=pdr.length;
                    let dr: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Data Received" &&  x.ServiceType==this.serviceType && x.TaskName==this.taskNm);
                    this.dataRecCnt=dr.length;
                    let wip: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Work in Progess" &&  x.ServiceType==this.serviceType && x.TaskName==this.taskNm);
                    this.wipCnt=wip.length;
                    let pft: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Pending for Tax" &&  x.ServiceType==this.serviceType && x.TaskName==this.taskNm);
                    this.pendingForTaxCnt=pft.length;
                    let app: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Applied" &&  x.ServiceType==this.serviceType && x.TaskName==this.taskNm);
                    this.appliedCnt=app.length;
                    let hld: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "On Hold" &&  x.ServiceType==this.serviceType && x.TaskName==this.taskNm);
                    this.holdCnt=hld.length;
                    let cmp: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Completed" &&  x.ServiceType==this.serviceType && x.TaskName==this.taskNm);
                    this.completedCnt=cmp.length;
                    let acc: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Accounting Completed" &&  x.ServiceType==this.serviceType && x.TaskName==this.taskNm);
                    this.accCnt=acc.length;
                   // this.workDetails=this.workDetailsAct.filter(x => x.WorkStatus != "Completed" &&  x.ServiceType==this.serviceType && x.TaskName==this.taskNm) ;
                    if(this.workSt=='All Pending')
                    {
                      this.workDetails=this.workDetailsAct.filter(x =>  x.WorkStatus != "Completed" &&  x.ServiceType==this.serviceType && x.TaskName==this.taskNm) ;
                    }
                    else{
                      this.workDetails=this.workDetailsAct.filter(x => x.WorkStatus == this.workSt &&  x.ServiceType==this.serviceType && x.TaskName==this.taskNm) ;
                    }
                  }
                }
                else{
                  this.openCnt=0;
                  this.partialDataCnt=0;
                  this.dataRecCnt=0;
                  this.wipCnt=0;
                  this.pendingForTaxCnt=0;
                  this.appliedCnt=0;
                  this.holdCnt=0;
                  this.completedCnt=0;
                  this.accCnt=0;
                }
                //$("#btnall").addClass("active").siblings().removeClass("active");
                this.spinnerService.hide();
              },
              (error) => {
                this.spinnerService.hide();
                this.toaster.error("Something went wrong!");
              }
            )
          }
          else{
            this.serverAPIService.getTasks(this.monthstr,this.appuserId,this.assistantId,this.tStatus)
          .subscribe(
            (response : WorkDetails[]) => {
          //  this.tableWidget.destroy();
              //this.workDetails=response;
              this.workDetailsAct=response;
           // this.ref.detectChanges();
              this.dtTrigger.next();

              //---------------if workstatus is completed and task type = 'GST Registration' then get registration details
              if(this.wStatus.toLocaleLowerCase()=='completed' && this.tempTaskNm==this.serverAPIService.TaskGSTReg){
                this.router.navigate(["/gst-details/"+this.tempClientId]);
              }


              if(this.workDetailsAct.length>0){
                if(this.taskNm=="All"){
                  this.pendingCnt=this.workDetailsAct.filter(x => x.ServiceType==this.serviceType ).length;
                  let o: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Open" &&  x.ServiceType==this.serviceType );
                  this.openCnt=o.length;
                  let pdr: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Partial Data Received" &&  x.ServiceType==this.serviceType);
                  this.partialDataCnt=pdr.length;
                  let dr: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Data Received" &&  x.ServiceType==this.serviceType);
                  this.dataRecCnt=dr.length;
                  let wip: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Work in Progess" &&  x.ServiceType==this.serviceType);
                  this.wipCnt=wip.length;
                  let pft: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Pending for Tax" &&  x.ServiceType==this.serviceType);
                  this.pendingForTaxCnt=pft.length;
                  let app: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Applied" &&  x.ServiceType==this.serviceType);
                  this.appliedCnt=app.length;
                  let hld: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "On Hold" &&  x.ServiceType==this.serviceType);
                  this.holdCnt=hld.length;
                  let cmp: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Completed" &&  x.ServiceType==this.serviceType);
                  this.completedCnt=cmp.length;
                  let acc: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Accounting Completed" &&  x.ServiceType==this.serviceType);
                    this.accCnt=acc.length;
                  //this.workDetails=this.workDetailsAct.filter(x => x.WorkStatus != "Completed" &&  x.ServiceType==this.serviceType) ;
                  if(this.workSt=='All Pending')
                    {
                      this.workDetails=this.workDetailsAct.filter(x =>  x.WorkStatus != "Completed" &&  x.ServiceType==this.serviceType) ;
                    }
                    else{
                      this.workDetails=this.workDetailsAct.filter(x => x.WorkStatus == this.workSt &&  x.ServiceType==this.serviceType) ;
                    }
                }
                else{
                  this.pendingCnt=this.workDetailsAct.filter(x => x.ServiceType==this.serviceType && x.TaskName==this.taskNm ).length;
                  let o: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Open" &&  x.ServiceType==this.serviceType && x.TaskName==this.taskNm);
                  this.openCnt=o.length;
                  let pdr: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Partial Data Received" &&  x.ServiceType==this.serviceType && x.TaskName==this.taskNm);
                  this.partialDataCnt=pdr.length;
                  let dr: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Data Received" &&  x.ServiceType==this.serviceType && x.TaskName==this.taskNm);
                  this.dataRecCnt=dr.length;
                  let wip: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Work in Progess" &&  x.ServiceType==this.serviceType && x.TaskName==this.taskNm);
                  this.wipCnt=wip.length;
                  let pft: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Pending for Tax" &&  x.ServiceType==this.serviceType && x.TaskName==this.taskNm);
                  this.pendingForTaxCnt=pft.length;
                  let app: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Applied" &&  x.ServiceType==this.serviceType && x.TaskName==this.taskNm);
                  this.appliedCnt=app.length;
                  let hld: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "On Hold" &&  x.ServiceType==this.serviceType && x.TaskName==this.taskNm);
                  this.holdCnt=hld.length;
                  let cmp: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Completed" &&  x.ServiceType==this.serviceType && x.TaskName==this.taskNm);
                  this.completedCnt=cmp.length;
                  let acc: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Accounting Completed" &&  x.ServiceType==this.serviceType && x.TaskName==this.taskNm);
                    this.accCnt=acc.length;
                  //this.workDetails=this.workDetailsAct.filter(x => x.WorkStatus != "Completed" &&  x.ServiceType==this.serviceType && x.TaskName==this.taskNm) ;
                  if(this.workSt=='All Pending')
                    {
                      this.workDetails=this.workDetailsAct.filter(x =>  x.WorkStatus != "Completed" &&  x.ServiceType==this.serviceType && x.TaskName==this.taskNm) ;
                    }
                    else{
                      this.workDetails=this.workDetailsAct.filter(x => x.WorkStatus == this.workSt &&  x.ServiceType==this.serviceType && x.TaskName==this.taskNm) ;
                    }
                }
              }
              else{
                this.openCnt=0;
                this.partialDataCnt=0;
                this.dataRecCnt=0;
                this.wipCnt=0;
                this.pendingForTaxCnt=0;
                this.appliedCnt=0;
                this.holdCnt=0;
                this.completedCnt=0;
                this.accCnt=0;
              }
             // $("#btnall").addClass("active").siblings().removeClass("active");
              this.spinnerService.hide();
            },
            (error) => {
              this.spinnerService.hide();
              this.toaster.error("Something went wrong!");
            }
          )
          }
        },
        (error) => {
          this.spinnerService.hide();
          this.toaster.error("Something went wrong!");
        }
      )
  }

  addComment(){
    if(this.commentsText ==null || this.commentsText==''){
      this.toaster.warning('Enter Comment');
      return;
    }

    let wd1=new WorkDetails();
    wd1.ClientID=this.tempClientId;
    wd1.StatusDetails=this.workDetailsText;
    wd1.TaskID=this.tempTaskId;
    wd1.WorkDetailsID=this.tempWorkDetailsId;
    wd1.WorkID=this.tempWorkId;
    wd1.WorkStatus=this.wStatus;
    wd1.Comment=this.commentsText;

    this.spinnerService.show();
    if (this.tableWidget) {
      this.tableWidget.destroy()
     this.tableWidget=null
    }
    this.serverAPIService.addCommentWorkDetails(wd1)
      .subscribe(
        (response : any) => {
          this.toaster.show("Cooment added successfully!!!");
          this.spinnerService.hide();
        },
        (error) => {
          this.spinnerService.hide();
          this.toaster.error("Something went wrong!");
        }
      )
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  ngAfterContentChecked() {
    this.ref.detectChanges();
}

public onTaskStatusSelected(event) {
  const value = event.target.value;
  if(value!=''){
    this.spinnerService.show();
    $('#example').DataTable().destroy();
    this.workDetails=null;
    this.dtTrigger.next();

      this.taskNm=value;
      if(this.taskNm!='All')
        this.pendingCnt=this.workDetailsAct.filter(x =>  x.ServiceType==this.serviceType && x.TaskName==this.taskNm).length;
      else
        this.pendingCnt=this.workDetailsAct.filter(x =>  x.ServiceType==this.serviceType ).length;
      setTimeout(() => {
        if(this.workDetailsAct.length>0){
          if(this.taskNm!='All') {
            let o: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Open" && x.ServiceType==this.serviceType && x.TaskName==this.taskNm);
            this.openCnt=o.length;
            let pdr: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Partial Data Received" && x.ServiceType==this.serviceType && x.TaskName==this.taskNm);
            this.partialDataCnt=pdr.length;
            let dr: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Data Received" && x.ServiceType==this.serviceType && x.TaskName==this.taskNm);
            this.dataRecCnt=dr.length;
            let wip: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Work in Progess" && x.ServiceType==this.serviceType && x.TaskName==this.taskNm);
            this.wipCnt=wip.length;
            let pft: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Pending for Tax" && x.ServiceType==this.serviceType && x.TaskName==this.taskNm);
            this.pendingForTaxCnt=pft.length;
            let app: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Applied" && x.ServiceType==this.serviceType && x.TaskName==this.taskNm);
            this.appliedCnt=app.length;
            let hld: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "On Hold" && x.ServiceType==this.serviceType && x.TaskName==this.taskNm);
            this.holdCnt=hld.length;
            let cmp: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Completed" && x.ServiceType==this.serviceType && x.TaskName==this.taskNm);
            this.completedCnt=cmp.length;
            let acc: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Accounting Completed" &&  x.ServiceType==this.serviceType && x.TaskName==this.taskNm);
            this.accCnt=acc.length;
           // this.workDetails=this.workDetailsAct.filter(x => x.WorkStatus != "Completed" && x.ServiceType==this.serviceType && x.TaskName==this.taskNm);
           if(this.workSt=='All Pending')
            {
              this.workDetails=this.workDetailsAct.filter(x =>  x.WorkStatus != "Completed" &&  x.ServiceType==this.serviceType && x.TaskName==this.taskNm) ;
            }
            else{
              this.workDetails=this.workDetailsAct.filter(x => x.WorkStatus == this.workSt &&  x.ServiceType==this.serviceType && x.TaskName==this.taskNm) ;
            }
          }
          else{
            let o: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Open" && x.ServiceType==this.serviceType );
            this.openCnt=o.length;
            let pdr: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Partial Data Received" && x.ServiceType==this.serviceType);
            this.partialDataCnt=pdr.length;
            let dr: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Data Received" && x.ServiceType==this.serviceType );
            this.dataRecCnt=dr.length;
            let wip: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Work in Progess" && x.ServiceType==this.serviceType );
            this.wipCnt=wip.length;
            let pft: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Pending for Tax" && x.ServiceType==this.serviceType );
            this.pendingForTaxCnt=pft.length;
            let app: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Applied" && x.ServiceType==this.serviceType );
            this.appliedCnt=app.length;
            let hld: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "On Hold" && x.ServiceType==this.serviceType );
            this.holdCnt=hld.length;
            let cmp: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Completed" && x.ServiceType==this.serviceType );
            this.completedCnt=cmp.length;
            let acc: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Accounting Completed" &&  x.ServiceType==this.serviceType);
            this.accCnt=acc.length;
            //this.workDetails=this.workDetailsAct.filter(x => x.WorkStatus != "Completed" && x.ServiceType==this.serviceType );
            if(this.workSt=='All Pending')
            {
              this.workDetails=this.workDetailsAct.filter(x =>  x.WorkStatus != "Completed" &&  x.ServiceType==this.serviceType) ;
            }
            else{
              this.workDetails=this.workDetailsAct.filter(x => x.WorkStatus == this.workSt &&  x.ServiceType==this.serviceType) ;
            }
          }
         this.dtTrigger.next();
        }
       // $("#btnall").addClass("active").siblings().removeClass("active");
        this.spinnerService.hide();
      },0)

  }
}


ngAfterViewInit(){
  setTimeout(() => {
    this.route.params
        .subscribe(
          (params: Params) => {
            if(params["tp"] != null){
              this.isPeriodWise=true;
              this.prd= this.datePipe.transform((new Date(new Date().setMonth(new Date().getMonth() -1))), 'MMM');//(new Date().getMonth() -1).toString();
              this.getFy();
              this.getSednderID();
              this.getWhatsAppSednderID();
              this.smsFixedText=this.serverAPIService.smsTemplate;
            }
            else{
                this.spinnerService.show();
                this.monthstr='0' + (new Date().getMonth() + 1).toString().slice(-2) + '/' + new Date().getFullYear().toString();
                this.getTasknm();
                if(localStorage.getItem('userRole').toString()=="Admin"){
                  this.appuserId=parseInt( localStorage.getItem('userId').toString());
                  this.assistantId=0;
                  this.getTasks();
                }
                else{
                  this.assistantId=parseInt( localStorage.getItem('userId').toString());
                  this.getuserDetails(this.assistantId);
                }
                this.getSednderID();
                this.getWhatsAppSednderID();
                this.smsFixedText=this.serverAPIService.smsTemplate;
            }
          }
        );


 });

}

getFy(){
  this.spinnerService.show();
  this.serverAPIService.getUsersFy()
  .subscribe(
    (response : FinancialYear[]) => {
      //this.spinnerService.hide();
      this.fylist=response;
      this.fydata=[''];
      response.forEach(role => {
        this.fydata.push(role.FinancialYear);
      });
      this.fydata.shift;
      this.serverAPIService.getCurrentFy()
      .subscribe(
        (response : any) => {
          this.fyYear=response;
          this.getTasknm();
          if(localStorage.getItem('userRole').toString()=="Admin"){
            this.appuserId=parseInt( localStorage.getItem('userId').toString());
            this.assistantId=0;
            this.getPeriodwiseTasks();
          }
          else{
            this.assistantId=parseInt( localStorage.getItem('userId').toString());
            this.getuserDetails(this.assistantId);
          }
        },
        (error) => {
        }
      )
    },
    (error) => {
      this.spinnerService.hide();
      this.toaster.error("Something went wrong!");
    }
  )
}

changeFy(fy:string){
  this.fyYear=fy;

  $('#example').DataTable().destroy();
  this.workDetails=null;
  this.dtTrigger.next();
 //this.taskNm='All';
 // this.serviceType='GST';
  this.taskList=this.taskListorg.filter(x =>  x.ServiceType==this.serviceType || x.ServiceType=='All');
  setTimeout(() => this.getPeriodwiseTasks(),0);
}

changePrd(prd:string){
  this.prd=prd;

  $('#example').DataTable().destroy();
  this.workDetails=null;
  this.dtTrigger.next();
 //this.taskNm='All';
 // this.serviceType='GST';
  this.taskList=this.taskListorg.filter(x =>  x.ServiceType==this.serviceType || x.ServiceType=='All');
  setTimeout(() => this.getPeriodwiseTasks(),0);

  //$("#btngst").addClass("active").siblings().removeClass("active");
 // $("#btnall").addClass("active").siblings().removeClass("active");
}

getSednderID(){
  this.serverAPIService.getSenderId()
  .subscribe(
    (response : string) => {
     this.senderId=response;
    },
    (error) => {
      this.toaster.error(error._body);
    }
  )
}

getWhatsAppSednderID(){
  this.serverAPIService.getWhatsAppSenderId()
  .subscribe(
    (response : string) => {
     this.whatsAppSenderId=response;
    },
    (error) => {
      this.toaster.error(error._body);
    }
  )
}

getTasknm(){
  this.spinnerService.show();
  this.serverAPIService.getTaskList()
  .subscribe(
    (response : TaskList[]) => {
     // this.spinnerService.hide();
     this.taskListorg=response;
     this.taskList=this.taskListorg.filter(x =>  x.ServiceType==this.serviceType || x.ServiceType=='All');
    },
    (error) => {
      this.spinnerService.hide();
      this.toaster.error("Something went wrong!");
    }
  )
}

viewPending(){
  $('#example').DataTable().destroy();
  this.workDetails=null;
  this.dtTrigger.next();
 this.taskNm='All';
 // this.serviceType='GST';
  this.taskList=this.taskListorg.filter(x =>  x.ServiceType==this.serviceType || x.ServiceType=='All');
  setTimeout(() => this.getTasks(),0);

  //$("#btngst").addClass("active").siblings().removeClass("active");
 // $("#btnall").addClass("active").siblings().removeClass("active");
}

  ngOnInit() {
    this.tStatus="All";
    this.tstArray=[''];
    this.tstArray.push("All");
    this.tstArray.push("Selected Month");
    this.tstArray.shift();
    let exampleId: any = $('#example');
    this.tableWidget = exampleId.DataTable({
      select: true
    });

    $(".btn-group > .btn").click(function(){
      $(this).addClass("active").siblings().removeClass("active");
    });
   // window.addEventListener('scroll', this.scrollEvent, true);
   if (
    this.serverAPIService.getCAMobile() == null ||
    this.serverAPIService.getCAMobile() == "undefined"
  ) {
    setTimeout(() => {
      this.serverAPIService.getCAInfo().subscribe(
        (response: CAInfo) => {
          localStorage.setItem("mobileNo", response[0].mobileNo);
        },
        (error) => {}
      );
    });
  }
  }

  ngOnDestroy() {
   // window.removeEventListener('scroll', this.scrollEvent, true);
  }

  @HostListener('window:scroll', ['$event']) getScrollHeight(event) {
    //console.log(window.pageYOffset, event);
    if(window.innerWidth>=1024){
    if (window.pageYOffset > 135) {
      $('#statusdiv').addClass('fixed');
    } else {
        $('#statusdiv').removeClass('fixed');
    }
  }
 }

  getuserDetails(userIdapp:number){
    this.spinnerService.show();
    this.serverAPIService.getAccountById(userIdapp)
    .subscribe(
      (response : UserProfile) => {
       // this.spinnerService.hide();
       this.appuserId=response.AppCustomerID;
       if(!this.isPeriodWise)
          this.getTasks();
        else
          this.getPeriodwiseTasks();
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error("Something went wrong!");
      }
    )
  }

  public onOptionsSelected(event) {
    const value = event.target.value;
    if(value!=''){
        this.tStatus=value;
        if(value=="All")
          this.dateHeading="Show tasks up to";
        else
        this.dateHeading="Month";
    }
 }

  getTasks(){
    this.spinnerService.show();
    this.openCnt=0;
    this.partialDataCnt=0;
    this.dataRecCnt=0;
    this.wipCnt=0;
    this.pendingForTaxCnt=0;
    this.appliedCnt=0;
    this.holdCnt=0;
    this.completedCnt=0;
    this.accCnt=0;
    this.serverAPIService.getTasks(this.monthstr,this.appuserId,this.assistantId,this.tStatus)
    .subscribe(
      (response : WorkDetails[]) => {
      // this.tableWidget.destroy();
        this.dtTrigger.next();
      //  this.workDetails=response;
        this.workDetailsAct=response;
       //this.ref.detectChanges();

        this.dtTrigger.next();
        this.pendingCnt=this.workDetailsAct.filter(x =>  x.ServiceType==this.serviceType).length;
        if(this.workDetailsAct.length>0){
          let o: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Open" && x.ServiceType==this.serviceType);
          this.openCnt=o.length;
          let pdr: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Partial Data Received" && x.ServiceType==this.serviceType);
          this.partialDataCnt=pdr.length;
          let dr: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Data Received" && x.ServiceType==this.serviceType);
          this.dataRecCnt=dr.length;
          let wip: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Work in Progess" && x.ServiceType==this.serviceType);
          this.wipCnt=wip.length;
          let pft: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Pending for Tax" && x.ServiceType==this.serviceType);
          this.pendingForTaxCnt=pft.length;
          let app: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Applied" && x.ServiceType==this.serviceType);
          this.appliedCnt=app.length;
          let hld: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "On Hold" && x.ServiceType==this.serviceType);
          this.holdCnt=hld.length;
          let cmp: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Completed" && x.ServiceType==this.serviceType);
          this.completedCnt=cmp.length;
          let acc: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Accounting Completed" &&  x.ServiceType==this.serviceType);
          this.accCnt=acc.length;
          //this.workDetails=this.workDetailsAct.filter(x => x.WorkStatus != "Completed" && x.ServiceType==this.serviceType);
          if(this.workSt=='All Pending')
          {
            this.workDetails=this.workDetailsAct.filter(x =>  x.WorkStatus != "Completed" &&  x.ServiceType==this.serviceType) ;
          }
          else{
            this.workDetails=this.workDetailsAct.filter(x => x.WorkStatus == this.workSt &&  x.ServiceType==this.serviceType) ;
          }
        }
        else{
          this.openCnt=0;
          this.partialDataCnt=0;
          this.dataRecCnt=0;
          this.wipCnt=0;
          this.pendingForTaxCnt=0;
          this.appliedCnt=0;
          this.holdCnt=0;
          this.completedCnt=0;
          this.accCnt=0;
        }
        this.spinnerService.hide();
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error("Something went wrong!");
      }
    )
  }

  getPeriodwiseTasks(){
    this.spinnerService.show();
    this.openCnt=0;
    this.partialDataCnt=0;
    this.dataRecCnt=0;
    this.wipCnt=0;
    this.pendingForTaxCnt=0;
    this.appliedCnt=0;
    this.holdCnt=0;
    this.completedCnt=0;
    this.accCnt=0;
    this.serverAPIService.getPeriodWiseTasks(this.fyYear,this.appuserId,this.assistantId,this.prd)
    .subscribe(
      (response : WorkDetails[]) => {
      // this.tableWidget.destroy();
        this.dtTrigger.next();
      //  this.workDetails=response;
        this.workDetailsAct=response;
       //this.ref.detectChanges();

        this.dtTrigger.next();
        if(this.taskNm=="All")
          this.pendingCnt=this.workDetailsAct.filter(x =>  x.ServiceType==this.serviceType).length;
        else
          this.pendingCnt=this.workDetailsAct.filter(x =>  x.ServiceType==this.serviceType && x.TaskName==this.taskNm).length;
        if(this.workDetailsAct.length>0){
          if(this.taskNm=="All"){
            let o: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Open" && x.ServiceType==this.serviceType);
            this.openCnt=o.length;
            let pdr: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Partial Data Received" && x.ServiceType==this.serviceType);
            this.partialDataCnt=pdr.length;
            let dr: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Data Received" && x.ServiceType==this.serviceType);
            this.dataRecCnt=dr.length;
            let wip: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Work in Progess" && x.ServiceType==this.serviceType);
            this.wipCnt=wip.length;
            let pft: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Pending for Tax" && x.ServiceType==this.serviceType);
            this.pendingForTaxCnt=pft.length;
            let app: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Applied" && x.ServiceType==this.serviceType);
            this.appliedCnt=app.length;
            let hld: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "On Hold" && x.ServiceType==this.serviceType);
            this.holdCnt=hld.length;
            let cmp: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Completed" && x.ServiceType==this.serviceType);
            this.completedCnt=cmp.length;
            let acc: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Accounting Completed" &&  x.ServiceType==this.serviceType);
            this.accCnt=acc.length;
          }
          else{
            let o: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Open" && x.ServiceType==this.serviceType && x.TaskName==this.taskNm);
            this.openCnt=o.length;
            let pdr: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Partial Data Received" && x.ServiceType==this.serviceType && x.TaskName==this.taskNm);
            this.partialDataCnt=pdr.length;
            let dr: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Data Received" && x.ServiceType==this.serviceType && x.TaskName==this.taskNm);
            this.dataRecCnt=dr.length;
            let wip: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Work in Progess" && x.ServiceType==this.serviceType && x.TaskName==this.taskNm);
            this.wipCnt=wip.length;
            let pft: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Pending for Tax" && x.ServiceType==this.serviceType && x.TaskName==this.taskNm);
            this.pendingForTaxCnt=pft.length;
            let app: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Applied" && x.ServiceType==this.serviceType && x.TaskName==this.taskNm);
            this.appliedCnt=app.length;
            let hld: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "On Hold" && x.ServiceType==this.serviceType && x.TaskName==this.taskNm);
            this.holdCnt=hld.length;
            let cmp: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Completed" && x.ServiceType==this.serviceType && x.TaskName==this.taskNm);
            this.completedCnt=cmp.length;
            let acc: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Accounting Completed" &&  x.ServiceType==this.serviceType && x.TaskName==this.taskNm);
            this.accCnt=acc.length;
          }
          //this.workDetails=this.workDetailsAct.filter(x => x.WorkStatus != "Completed" && x.ServiceType==this.serviceType);
          if(this.workSt=='All Pending')
          {
            if(this.taskNm=="All")
              this.workDetails=this.workDetailsAct.filter(x =>  x.WorkStatus != "Completed" &&  x.ServiceType==this.serviceType ) ;
            else
            this.workDetails=this.workDetailsAct.filter(x =>  x.WorkStatus != "Completed" &&  x.ServiceType==this.serviceType && x.TaskName==this.taskNm ) ;

          }
          else{
            if(this.taskNm=="All")
              this.workDetails=this.workDetailsAct.filter(x => x.WorkStatus == this.workSt &&  x.ServiceType==this.serviceType) ;
            else
            this.workDetails=this.workDetailsAct.filter(x => x.WorkStatus == this.workSt &&  x.ServiceType==this.serviceType && x.TaskName==this.taskNm) ;
          }
        }
        else{
          this.openCnt=0;
          this.partialDataCnt=0;
          this.dataRecCnt=0;
          this.wipCnt=0;
          this.pendingForTaxCnt=0;
          this.appliedCnt=0;
          this.holdCnt=0;
          this.completedCnt=0;
          this.accCnt=0;
        }
        this.spinnerService.hide();
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error("Something went wrong!");
      }
    )
  }

  showStatus(status:string){
    this.workSt=status;
    $('#example').DataTable().destroy();
    this.workDetails=[];
    this.dtTrigger.next();
    setTimeout(() => {
      if(status=="All Pending"){
        // this.dtTrigger.next();
        if(this.taskNm=='All')
          this.workDetails=this.workDetailsAct.filter(x =>  x.ServiceType==this.serviceType );
        else
          this.workDetails=this.workDetailsAct.filter(x =>  x.ServiceType==this.serviceType && x.TaskName==this.taskNm);
         this.dtTrigger.next();
       }
       else{
        if(this.taskNm=='All')
          this.workDetails=this.workDetailsAct.filter(x => x.WorkStatus === status &&  x.ServiceType==this.serviceType);
        else
          this.workDetails=this.workDetailsAct.filter(x => x.WorkStatus === status &&   x.ServiceType==this.serviceType && x.TaskName==this.taskNm);

        this.dtTrigger.next()
       }
    },0)
  }

  getService(srvtp:number){
    this.spinnerService.show();
    $('#example').DataTable().destroy();
    this.workDetails=[];
    this.dtTrigger.next();
    setTimeout(() => {

      if(srvtp==1){
        // this.dtTrigger.next();
        this.serviceType="GST";
        this.pendingCnt=this.workDetailsAct.filter(x =>  x.ServiceType==this.serviceType).length;
        if(this.workDetailsAct.length>0){
          let o: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Open" && x.ServiceType==this.serviceType);
          this.openCnt=o.length;
          let pdr: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Partial Data Received" && x.ServiceType==this.serviceType);
          this.partialDataCnt=pdr.length;
          let dr: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Data Received" && x.ServiceType==this.serviceType);
          this.dataRecCnt=dr.length;
          let wip: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Work in Progess" && x.ServiceType==this.serviceType);
          this.wipCnt=wip.length;
          let pft: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Pending for Tax" && x.ServiceType==this.serviceType);
          this.pendingForTaxCnt=pft.length;
          let app: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Applied" && x.ServiceType==this.serviceType);
          this.appliedCnt=app.length;
          let hld: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "On Hold" && x.ServiceType==this.serviceType);
          this.holdCnt=hld.length;
          let cmp: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Completed" && x.ServiceType==this.serviceType);
          this.completedCnt=cmp.length;
          let acc: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Accounting Completed" &&  x.ServiceType==this.serviceType);
          this.accCnt=acc.length;
          //this.workDetails=this.workDetailsAct.filter(x => x.WorkStatus != "Completed" && x.ServiceType==this.serviceType);
          if(this.workSt=='All Pending')
          {
            this.workDetails=this.workDetailsAct.filter(x =>  x.WorkStatus != "Completed" &&  x.ServiceType==this.serviceType) ;
          }
          else{
            this.workDetails=this.workDetailsAct.filter(x => x.WorkStatus == this.workSt &&  x.ServiceType==this.serviceType) ;
          }
         this.dtTrigger.next();
        }
       }
       else  if(srvtp==2){
        // this.dtTrigger.next();
        this.serviceType="INCOME TAX";
          this.pendingCnt=this.workDetailsAct.filter(x =>  x.ServiceType==this.serviceType).length;
        if(this.workDetailsAct.length>0){
          let o: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Open" && x.ServiceType==this.serviceType);
          this.openCnt=o.length;
          let pdr: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Partial Data Received" && x.ServiceType==this.serviceType);
          this.partialDataCnt=pdr.length;
          let dr: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Data Received" && x.ServiceType==this.serviceType);
          this.dataRecCnt=dr.length;
          let wip: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Work in Progess" && x.ServiceType==this.serviceType);
          this.wipCnt=wip.length;
          let pft: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Pending for Tax" && x.ServiceType==this.serviceType);
          this.pendingForTaxCnt=pft.length;
          let app: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Applied" && x.ServiceType==this.serviceType);
          this.appliedCnt=app.length;
          let hld: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "On Hold" && x.ServiceType==this.serviceType);
          this.holdCnt=hld.length;
          let cmp: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Completed" && x.ServiceType==this.serviceType);
          this.completedCnt=cmp.length;
          let acc: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Accounting Completed" &&  x.ServiceType==this.serviceType);
          this.accCnt=acc.length;
          //this.workDetails=this.workDetailsAct.filter(x => x.WorkStatus != "Completed" && x.ServiceType==this.serviceType);
          if(this.workSt=='All Pending')
          {
            this.workDetails=this.workDetailsAct.filter(x =>  x.WorkStatus != "Completed" &&  x.ServiceType==this.serviceType) ;
          }
          else{
            this.workDetails=this.workDetailsAct.filter(x => x.WorkStatus == this.workSt &&  x.ServiceType==this.serviceType) ;
          }
         this.dtTrigger.next();
        }
       }
       else{
          this.serviceType="OTHER";
            this.pendingCnt=this.workDetailsAct.filter(x =>  x.ServiceType==this.serviceType).length;
          if(this.workDetailsAct.length>0){
            let o: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Open" && x.ServiceType==this.serviceType);
            this.openCnt=o.length;
            let pdr: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Partial Data Received" && x.ServiceType==this.serviceType);
            this.partialDataCnt=pdr.length;
            let dr: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Data Received" && x.ServiceType==this.serviceType);
            this.dataRecCnt=dr.length;
            let wip: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Work in Progess" && x.ServiceType==this.serviceType);
            this.wipCnt=wip.length;
            let pft: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Pending for Tax" && x.ServiceType==this.serviceType);
            this.pendingForTaxCnt=pft.length;
            let app: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Applied" && x.ServiceType==this.serviceType);
            this.appliedCnt=app.length;
            let hld: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "On Hold" && x.ServiceType==this.serviceType);
            this.holdCnt=hld.length;
            let cmp: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Completed" && x.ServiceType==this.serviceType);
            this.completedCnt=cmp.length;
            let acc: WorkDetails[] = this.workDetailsAct.filter(x => x.WorkStatus === "Accounting Completed" &&  x.ServiceType==this.serviceType);
            this.accCnt=acc.length;
            //this.workDetails=this.workDetailsAct.filter(x => x.WorkStatus != "Completed" && x.ServiceType==this.serviceType);
            if(this.workSt=='All Pending')
            {
              this.workDetails=this.workDetailsAct.filter(x =>  x.WorkStatus != "Completed" &&  x.ServiceType==this.serviceType) ;
            }
            else{
              this.workDetails=this.workDetailsAct.filter(x => x.WorkStatus == this.workSt &&  x.ServiceType==this.serviceType) ;
            }
           this.dtTrigger.next();
         }
       }
     this.taskNm='All';
      this.taskList=this.taskListorg.filter(x =>  x.ServiceType==this.serviceType || x.ServiceType=='All');
      // $("#btnall").addClass("active").siblings().removeClass("active");
       this.spinnerService.hide();
    },0)

  }
  copyInputMessage(cliPTEC){
    cliPTEC.select();
    document.execCommand('copy');
    cliPTEC.setSelectionRange(0, 0);
  }
}
