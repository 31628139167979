import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { NgForm, FormBuilder, FormGroup, Validators, FormControl, MaxLengthValidator } from '@angular/forms';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { ServerAPIService } from 'src/app/serverapi.service';
import { SpinnerService } from 'src/app/spinner.service';
import { ToastrService } from 'ngx-toastr';
import { UserProfile } from 'src/app/user-profile/user-profile.model';

@Component({
  selector: 'app-assistant-details',
  templateUrl: './assistant-details.component.html',
  styleUrls: ['./assistant-details.component.css']
})
export class AssistantDetailsComponent implements  OnInit, AfterViewInit {
  @ViewChild('f',{static : true}) formRef : NgForm;
  isEditMode = false;
  accountID : number = 0;
  registerForm: FormGroup;
  submitted = false;
  roleArray:string[]=['Assistant','Admin'];
  profileForm = new FormGroup({
    AppUserName: new FormControl(''),
    MobileNo: new FormControl(''),
    Email:new FormControl(''),
    Address:new FormControl(''),
    //UserName:new FormControl(''),
    Password:new FormControl(''),
    ConfirmPassword1:new FormControl(''),
    ddlUserRole:new FormControl(''),
    chkFirm:new FormControl(false),
    chkAssistant:new FormControl(false),
    chkClients:new FormControl(false),
    chkServices:new FormControl(false),
    chkInvoice:new FormControl(false),
    chkReceipts:new FormControl(false),
    chkReports:new FormControl(false),
    chkShowAllClients:new FormControl(false),
    chkInActive:new FormControl(false)
  });
  showassigned:boolean=false;

  constructor(private serverAPIService:ServerAPIService,private route : ActivatedRoute,
    private router:Router, private toaster:ToastrService, private spinnerService:SpinnerService
    ,private formBuilder: FormBuilder) { }

    ngOnInit() {
    }

    // convenience getter for easy access to form fields
    get f() { return this.profileForm.controls; }


    ngAfterViewInit(): void{
      setTimeout(() => {
        this.spinnerService.show();
        this.route.params
        .subscribe(
          (params: Params) => {
            if(params["id"] != null){
              this.isEditMode = true;
              this.getAccountById(params["id"]);
            } else{
              this.spinnerService.hide();
            }
          }
        );
      });
    }

    getAccountById(id : number){

      this.serverAPIService.getAccountById(id)
      .subscribe(
        (data:UserProfile) => {
          this.spinnerService.hide();
          this.accountID = data.AppUserID
          this.f.AppUserName.setValue(data.AppUserName);
          this.f. MobileNo.setValue(data.MobileNo);
          this.f.Email.setValue(data.Email);
          this.f.Address.setValue(data.Address);
         // this.f.UserName.setValue(data.UserName);
        },
        (error) => {
          this.spinnerService.hide();
          this.toaster.error('Something went wrong!');
        }
      );


    }


    onSubmit(form: NgForm){

      if(this.isEditMode)
        this.update(form);
      else
        this.create(form);
    }

    create(form: NgForm){
      if(this.f.Password.value != this.f.ConfirmPassword1.value)
      {
        this.toaster.warning("Password and confirm password should be same");
        return;
      }

      this.spinnerService.show();
      this.serverAPIService.checkuserexists(this.f.MobileNo.value)
      .subscribe(
        (data :any) =>{
          let account = new UserProfile();

          account.AppUserName = this.f.AppUserName.value;
          account.MobileNo = this.f.MobileNo.value;
          account.Email=this.f.Email.value;
          account.Address=this.f.Address.value;
          account.UserName=this.f.MobileNo.value;
          account.UserRole="Assistant";
          account.Password=this.f.Password.value;
          account.UserRole=this.f.ddlUserRole.value;

          account.ShowAssistants=this.f.chkAssistant.value;
          account.ShowClients=this.f.chkClients.value;
          account.ShowFirms=this.f.chkFirm.value;
          account.ShowInvoice=this.f.chkInvoice.value;
          account.ShowReceipt=this.f.chkReceipts.value;
          account.ShowReports=this.f.chkReports.value;
          account.ShowService=this.f.chkServices.value;
          if(this.showassigned){
            account.ShowAllClientsToAssistant=this.f.chkShowAllClients.value;
          }
          else{
            account.ShowAllClientsToAssistant=false;
          }
          account.InActive=this.f.chkInActive.value;

          //this.spinnerService.show();

          this.serverAPIService.createAssistant(account)
          .subscribe(
            (data: any) => {
              this.spinnerService.hide();
              this.toaster.success("Account created successfully!");

              this.router.navigate(['/master/assistant']);
            },
            (error) => {
              this.spinnerService.hide();
              this.toaster.error(error);
            });
        },
        (error) => {
          this.spinnerService.hide();
          if(error != undefined){
            this.toaster.error(JSON.parse(error._body).Message != undefined
              ?JSON.parse(error._body).Message:"Something went wrong!","Error");
              return;
          }
        }
      )


    }

    checkValue(event: any){
      //console.log(event.target.checked);
      if(event.target.checked){
        this.showassigned=true;
      }
      else{
        this.showassigned=false;
      }
   }

    update(form: NgForm){

      // let account = new AccountModel();

      // account.AccountID = this.accountID;
      // account.AccountName = form.value.accountname;
      // account.AccountGroup = form.value.accountgroup;

      // this.spinnerService.show();

      // this.serverAPIService.updateAccount(account)
      // .subscribe(
      //   (data: any) => {
      //     this.spinnerService.hide();
      //     this.snackbar.open("Account updated successfully!");
      //     this.router.navigate(['/account-list']);
      //   },
      //   (error) => {
      //     this.spinnerService.hide();
      //     this.snackbar.open(error);
      //   });
    }

}
