import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CustomDateAdapter, CustomDateParserFormatter } from 'src/app/custom-ngbDateParserFormatter';
import { FormGroup, FormControl } from '@angular/forms';
import { Client } from 'src/app/masters/client.model';
import { AccountLedger } from 'src/app/home/account-ledger/account-ledger.model';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from 'src/app/spinner.service';
import { ServerAPIService } from 'src/app/serverapi.service';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-account-ledger',
  templateUrl: './account-ledger.component.html',
  styleUrls: ['./account-ledger.component.css'],
  providers: [{provide: NgbDateAdapter, useClass: CustomDateAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}]
})
export class AccountLedgerComponent implements OnInit {
  currentDate = new Date();
  clients:Client[];
  accountLedger:AccountLedger[];
  astarray:object[];
   uid:number=0;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  profileForm = new FormGroup({
    txtFromDate: new FormControl(''),
    txtToDate:new FormControl(''),
    ddlClient:new FormControl('')
  });
  constructor(private serverAPIService:ServerAPIService,
    private router:Router, private toaster:ToastrService, private spinnerService:SpinnerService
    ,private route : ActivatedRoute,private ref: ChangeDetectorRef) {
      this.dtOptions = {
        processing: true,
        paging:false,
        destroy: true,
        order:[],
      }
  }

   // convenience getter for easy access to form fields
   get f() { return this.profileForm.controls; }

  ngOnInit() {
    let day:number=this.currentDate.getDate();
    let month:number=this.currentDate.getMonth();
    let year:number=this.currentDate.getFullYear();
    if(month==12){
      month=0;
      year=year+1;
    }
    this.f.txtFromDate.setValue((day.toString().length==1?'0'+day.toString():day.toString())+ '/'+((month+1).toString().length==1
      ?'0'+(month+1).toString()
      :(month+1).toString())
      +'/'+year.toString());
    this.f.txtToDate.setValue((day.toString().length==1?'0'+day.toString():day.toString())+'/'+((month+1).toString().length==1
      ?'0'+(month+1).toString()
      :(month+1).toString())
      +'/'+year.toString());
      this.getClient();
  }

   getClient(){
    //this.spinnerService.show();
    this.serverAPIService.getClients()
    .subscribe(
      (response : Client[]) => {
        this.astarray = [{ id: 0, text: ''}];
        response.forEach(element => {
          this.astarray.push({id:element.ClientID,text:element.EntityName});
         });
         this.astarray.shift();
        this.f.ddlClient.setValue(response[0].ClientID);
        this.getData();
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error("Something went wrong!");
      }
    )
  }

   viewRecord(){
   $('#example').DataTable().destroy();
    this.accountLedger=null;
    this.dtTrigger.next();
    setTimeout(() => this.getData(),0)

  }

  getData(){
    this.spinnerService.show();
    this.serverAPIService.getAccountLedger(this.f.ddlClient.value,this.f.txtFromDate.value,this.f.txtToDate.value)
    .subscribe(
      (response : AccountLedger[]) => {
        this.accountLedger=response;
        this.dtTrigger.next();
        this.ref.detectChanges();
        this.spinnerService.hide();
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error("Something went wrong!");
      }
    )
  }

}
