import { Component, OnInit, AfterViewInit, ChangeDetectorRef, AfterContentChecked, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ServerAPIService } from 'src/app/serverapi.service';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from 'src/app/spinner.service';
import { Subject } from 'rxjs';
import { Service } from '../service.model';
import { FinancialYear } from 'src/app/fy.model';
import { FormControl, FormGroup } from '@angular/forms';
import { Client } from 'src/app/masters/client.model';
import { DataTablesModule } from 'angular-datatables';
import { NgbModal, NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CustomDateAdapter, CustomDateParserFormatter } from 'src/app/custom-ngbDateParserFormatter';
import { Work } from '../work.model';
declare let $: any;


@Component({
  selector: 'app-client-service-list',
  templateUrl: './client-service-list.component.html',
  styleUrls: ['./client-service-list.component.css'],
  providers: [{provide: NgbDateAdapter, useClass: CustomDateAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}]
})
export class ClientServiceListComponent implements OnInit,AfterViewInit,AfterContentChecked {

  services:Service[];
  client:Client;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  fylist:FinancialYear[];
  fydata:string[];
  srv:object[];
  @ViewChild('fyli') select;
  profileForm = new FormGroup({
    Fynm:new FormControl(''),
    Fynsd:new FormControl('')
  });
  clientId:number;
  public tableWidget: any;
  tempServiceName:string;
  tempServiceId:number;
  fyYear:string;
  tempDate:string='';
  tempReason:string='';
  tempWorkId:number=0;

  constructor(public serverAPIService:ServerAPIService,private route : ActivatedRoute,
    private router:Router, private toaster:ToastrService, private spinnerService:SpinnerService
    ,private ref: ChangeDetectorRef,private modalService: NgbModal) {
      this.dtOptions = {
        // pagingType: 'full_numbers',
        // pageLength: 10,
        processing: true,
        destroy: true,
        responsive: true,
        paging:false,
      }
  }
  ngAfterViewInit(){
    setTimeout(() => {
      this.spinnerService.show();
      this.route.params
      .subscribe(
        (params: Params) => {
          if(params["id"] != null){
            this.clientId=parseInt( params["id"]);
            this.getClientById(params["id"]);
            this.getFy(params["id"]);
          } else{
            this.spinnerService.hide();
          }
        }
      );
   });
  }

  onFYChanged(data: { value: string[] }): void {
     //get services
      this.spinnerService.show();
      console.log(data.toString());
     if(data.toString() !=''){

      // if (this.tableWidget) {
      //   this.tableWidget.destroy()
      //   this.tableWidget=null
      // }
      $('#example').DataTable().destroy();
      this.services=null;
      this.dtTrigger.next();
      setTimeout(() => this.getServices(data.toString()),0)
      }


      this.spinnerService.hide();
  }

  get f() { return this.profileForm.controls; }

  ngAfterContentChecked() {
    this.ref.detectChanges();
}

  ngOnInit() {
    //this.data=['01','02','03','04','05','06','07','08','09','10','11','12'];
    let exampleId: any = $('#example');
    this.tableWidget = exampleId.DataTable({
      select: true
    });
  }

  addService(){
    if(this.f.Fynsd.value=="")
    {
      this.toaster.warning("Select service to add");
      return;
    }
    // if((this.client.GSTIN==null || this.client.GSTIN=="") && this.f.Fynsd.value!="1"){
    //   this.toaster.warning("Enter GST details of client first");
    //   return;
    // }

    //check service id
    //1-GST Registration / 11-Assessment /15-Project Report/ 16-Certification / 20-Trust Audit
    //21-Corporate Audit / 22-Bank Audit / 23-PAN Application / 24-TAN Registration / 25-PTEC Registration / 26-PTRC Registration
    //27-Partnership Registration / 29-Notices and Rectifications / 31-PF Registration / 33-PF Member Registration / 34-PF Scheme Registration
    // 35-PF Withdrawal / 36-ESIC Registration / 38-Labour Licence Registration / 40-DSC Application
    if(this.f.Fynsd.value=="1" || this.f.Fynsd.value=="11" || this.f.Fynsd.value=="15"
    || this.f.Fynsd.value=="16" || this.f.Fynsd.value=="20" || this.f.Fynsd.value=="21" || this.f.Fynsd.value=="22"
    || this.f.Fynsd.value=="23" || this.f.Fynsd.value=="24"|| this.f.Fynsd.value=="25" || this.f.Fynsd.value=="26"
    || this.f.Fynsd.value=="27"|| this.f.Fynsd.value=="29" || this.f.Fynsd.value=="31" || this.f.Fynsd.value=="33"
    || this.f.Fynsd.value=="34" || this.f.Fynsd.value=="35" || this.f.Fynsd.value=="36"
    || this.f.Fynsd.value=="38" || this.f.Fynsd.value=="40"){

      this.router.navigate(['/service/gstreg-service-details',this.client.ClientID,this.f.Fynsd.value
      ,this.f.Fynm.value]);
    }
    //2-GST Monthly /3-GST Quarterly /4-GST Yearly /18-GST TDS Return
    else if(this.f.Fynsd.value=="2" || this.f.Fynsd.value=="3" || this.f.Fynsd.value=="4"
    || this.f.Fynsd.value=="14" || this.f.Fynsd.value=="18"){

      this.router.navigate(['/service/gst-mothly-details',this.client.ClientID,this.f.Fynsd.value
        ,this.f.Fynm.value]);
    }
    //5-Accounting / 19-SFC Return
    else if(this.f.Fynsd.value=="5" || this.f.Fynsd.value=="19"){

      this.router.navigate(['/service/accounting-details',this.client.ClientID,this.f.Fynsd.value
        ,this.f.Fynm.value]);
    }
    //6-IT Audit / 7-IT Return
    else if(this.f.Fynsd.value=="6" || this.f.Fynsd.value=="7"){

      this.router.navigate(['/service/it-details',this.client.ClientID,this.f.Fynsd.value
        ,this.f.Fynm.value]);
    }
    //8-PTRC Monthly / 9-PTRC-yearly/17-Company-Audit
    else if(this.f.Fynsd.value=="8" || this.f.Fynsd.value=="9" || this.f.Fynsd.value=="10" || this.f.Fynsd.value=="17"){

      this.router.navigate(['/service/ptrc-details',this.client.ClientID,this.f.Fynsd.value
        ,this.f.Fynm.value]);
    }
    //12-tds / 28-VAT Return
    else if(this.f.Fynsd.value=="12" || this.f.Fynsd.value=="28"){

      this.router.navigate(['/service/tds-details',this.client.ClientID,this.f.Fynsd.value
        ,this.f.Fynm.value]);
    }
    //13-Accounting monthly
    else{
      this.router.navigate(['/service/accounting-details',this.client.ClientID,this.f.Fynsd.value
      ,this.f.Fynm.value]);
    }
  }

  getClientById(id : number){

    this.serverAPIService.getClientById(id)
    .subscribe(
      (data:Client) => {
        this.client=data;
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error('Something went wrong!');
      }
    );


  }

  getUnaddedServices(){
    this.serverAPIService.getServiceTaskForClientUnadded(this.clientId,this.f.Fynm.value)
     .subscribe(
       (response : Service[]) => {
         this.srv=[];
         this.srv = [{ id: 0, text: ''}];
         response.forEach(element => {
          this.srv.push({id:element.ServiceID,text:element.ServiceName});
         });
         this.srv.shift();
         this.dtTrigger.next();
         this.spinnerService.hide();
       },
       (error) => {
         this.spinnerService.hide();
         this.toaster.error("Something went wrong!");
       }
     )
  }

  getServices(fy:string){
    $('#example').DataTable().destroy();
      this.services=null;
      this.dtTrigger.next();
    this.serverAPIService.getServiceTaskForClient(this.clientId,fy)
        .subscribe(
          (response : Service[]) => {
            this.services=response;
            this.dtTrigger.next();
            this.spinnerService.hide();
            this.getUnaddedServices();
          },
          (error) => {
            this.spinnerService.hide();
            this.toaster.error("Something went wrong!");
          }
        )
  }

  getFy(clientId:number){
    this.spinnerService.show();
    this.serverAPIService.getUsersFy()
    .subscribe(
      (response : FinancialYear[]) => {
        //this.spinnerService.hide();
        this.fylist=response;
        this.fydata=[''];
        response.forEach(role => {
          this.fydata.push(role.FinancialYear);
        });

        this.serverAPIService.getCurrentFy()
        .subscribe(
          (response : any) => {
            this.fyYear=response;
          },
          (error) => {
          }
        )
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error("Something went wrong!");
      }
    )
  }

  deleteconfirm(content,name:string,serviceId:number){
    this.tempServiceId=serviceId;
    this.tempServiceName=name;
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'});
  }

  disconopen(content,name:string,serviceId:number,workid:number){
    this.tempServiceId=0;
    this.tempServiceName='';
    this.tempDate='';
    this.tempReason='';
    this.tempWorkId=0;

    this.tempServiceId=serviceId;
    this.tempServiceName=name;
    this.tempWorkId=workid;
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'});
  }

  deleteService(){
    this.serverAPIService.deleteService(this.tempServiceId,this.client.ClientID,this.f.Fynm.value)
        .subscribe(
          (response : any) => {
            this.toaster.success("Service deleted successfully!");
            this.modalService.dismissAll();
            this.getServices(this.f.Fynm.value)
            //this.spinnerService.hide();
            this.getUnaddedServices();
          },
          (error) => {
            this.spinnerService.hide();
            this.toaster.error("Something went wrong!");
          }
        )
  }

  discontinueConfirm(){
    if(this.tempDate=='' || this.tempReason=='')
    {this.toaster.warning("Enter all details");
  return;}

    let wc=new  Work();
    wc.ClientID=this.clientId;
    wc.ServiceID=this.tempServiceId;
    wc.IsDiscontinue=true;
    wc.DiscontinueFromStr=this.tempDate;
    wc.DiscontinueReason=this.tempReason;
    wc.WorkID=this.tempWorkId;

    this.serverAPIService.discontinueService(wc)
        .subscribe(
          (response : any) => {
            this.toaster.success("Service Deactivated successfully!");
            this.modalService.dismissAll();
            this.getServices(this.f.Fynm.value)
            //this.spinnerService.hide();
            this.getUnaddedServices();
          },
          (error) => {
            this.spinnerService.hide();
            this.toaster.error("Something went wrong!");
          }
        )
  }

}
