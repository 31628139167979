import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Receivables } from './receivables.model';
import { FormGroup, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { ServerAPIService } from 'src/app/serverapi.service';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from 'src/app/spinner.service';

@Component({
  selector: 'app-receivables',
  templateUrl: './receivables.component.html',
  styleUrls: ['./receivables.component.css']
})
export class ReceivablesComponent implements OnInit {
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject();
  profileForm = new FormGroup({
  });
  receivables:Receivables[];

  constructor(public serverAPIService:ServerAPIService,
    private router:Router, private toaster:ToastrService, private spinnerService:SpinnerService
    ,private route : ActivatedRoute,private ref: ChangeDetectorRef) {
      this.dtOptions = {
        processing: true,
        paging:false,
        destroy: true,
        order:[],
        dom: "Bftrpi",
        scrollY:"55vh",
        scrollX: true,
        buttons: {
          buttons: [{
             extend: 'excel',
             text: '<i class="mdi mdi-file-excel"></i> Export To Excel',
             titleAttr: 'Export To Excel',
             title:'ReceivablesReport - Aareasy'
            }
          ],
          dom: {
            button: {
              tag: "button",
              className: "btn btn-gradient-success btn-fw text-light btn-sm"
            },
            buttonLiner: {
              tag: null
            }
          }
        }
      }
  }

  ngOnInit(): void {
    this.getData();
  }

  getData(){
    this.spinnerService.show();
    this.serverAPIService.getReceivables()
    .subscribe(
      (response : Receivables[]) => {
        this.receivables=response;
        this.dtTrigger.next();
        this.ref.detectChanges();
        this.spinnerService.hide();
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error("Something went wrong!");
      }
    )
  }

}
