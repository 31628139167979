import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServerAPIService } from 'src/app/serverapi.service';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from 'src/app/spinner.service';
import { UserProfile } from 'src/app/user-profile/user-profile.model';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
declare let $: any;

@Component({
  selector: 'app-assistant',
  templateUrl: './assistant.component.html',
  styleUrls: ['./assistant.component.css']
})
export class AssistantComponent implements OnInit,AfterViewInit {
  assitants:UserProfile[];
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  constructor(private serverAPIService:ServerAPIService,
    private router:Router, private toaster:ToastrService, private spinnerService:SpinnerService) {
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,

      }
  }


  ngOnInit() {
   // $('.js-example-basic-single').select2();
      this.getData();
  }

  ngAfterViewInit() {
    
  }

  getData(){
    this.spinnerService.show();
    this.serverAPIService.getAssistants()
    .subscribe(
      (response : UserProfile[]) => {
        this.spinnerService.hide();
        this.assitants=response;
        this.dtTrigger.next();
        // this.dataSource.data = response;
        // this.dataSource.sort = this.sort;
        // this.dataSource.paginator = this.paginator;
      },
      (error) => {
        this.spinnerService.hide();
        this.toaster.error("Something went wrong!");
      }
    )
  }

}
