import { Component, OnInit, AfterViewInit, ChangeDetectorRef, AfterContentChecked, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ServerAPIService } from 'src/app/serverapi.service';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from 'src/app/spinner.service';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { Client } from 'src/app/masters/client.model';
import { Firm } from 'src/app/masters/firm.model';
import { UserProfile } from 'src/app/user-profile/user-profile.model';
import { Work } from 'src/app/user-service/work.model';
import { Service } from '../service.model';
declare let $: any;
import { Options } from 'select2';

@Component({
  selector: 'app-edit-service',
  templateUrl: './edit-service.component.html',
  styleUrls: ['./edit-service.component.css']
})
export class EditServiceComponent implements OnInit,AfterViewInit,AfterContentChecked  {
  clientId:number;
  serviceId:number;
  workId:number;
  fy:string;
  client:Client;
  work:Work;
  profileForm = new FormGroup({
    ddlFirm: new FormControl(''),
    txtFee:new FormControl(''),
    ddlAssistant:new FormControl(''),
    txtRec:new FormControl(''),
    ddlBillingTp:new FormControl('')
  });
  firms:object[];
  assitants:object[];
  billingtps:string[];
  serviceName:string;
  comp:boolean=false;
  public options: Options;
  actualAssi:string[]=[];
  returnUrl:string='';
  taskSwitchBit:boolean=false;

  constructor(private serverAPIService:ServerAPIService,private route : ActivatedRoute,
    private router:Router, private toaster:ToastrService, private spinnerService:SpinnerService
    ,private ref: ChangeDetectorRef) { }

    ngAfterViewInit(){
    }

     // convenience getter for easy access to form fields
     get f() { return this.profileForm.controls; }


    ngAfterContentChecked() {
      this.ref.detectChanges();
    }

    ngOnInit() {
      // $(".js-example-basic-single").select2({dropdownAutoWidth : true});
      //$('.js-example-basic-single').select2({width: 'resolve'});
      //this.data=['01','02','03','04','05','06','07','08','09','10','11','12'];
      this.options = {
        width: '300',
        multiple: true,
        tags: true
      };

      this.billingtps=this.serverAPIService.BillingTypes;
      this.spinnerService.show();
       if(this.route.snapshot.queryParams["returnUrl"]!=null){
            this.returnUrl = this.route.snapshot.queryParams["returnUrl"];
          }
       if(this.route.snapshot.queryParams["taskSwitchBit"]!=null){
            this.taskSwitchBit = this.route.snapshot.queryParams["taskSwitchBit"];
       }

      this.route.params
      .subscribe(
        (params: Params) => {

          if(params["id"] != null){
            this.workId=parseInt( params["id"]);
            this.getWorkById(this.workId);

          } else{
            this.spinnerService.hide();
          }
        }
      );
    }

    getFirms(){
      //this.spinnerService.show();
      this.serverAPIService.getFirms()
      .subscribe(
        (response : Firm[]) => {
          this.firms = [{ id: 0, text: ''}];
          response.forEach(element => {
            this.firms.push({id:element.FirmID,text:element.FirmName});
           });
           this.firms.shift();
         // this.spinnerService.hide();
         this.getAssistant();
        },
        (error) => {
          this.spinnerService.hide();
          this.toaster.error("Something went wrong!");
        }
      )
    }

    getAssistant(){
      //this.spinnerService.show();
      this.serverAPIService.getAssistants()
      .subscribe(
        (response : UserProfile[]) => {
          this.assitants = [{ id: 0, text: ''}];
          response.forEach(element => {
            this.assitants.push({id:element.AppUserID,text:element.AppUserName});
           });
           this.assitants.shift();
          this.spinnerService.hide();
        },
        (error) => {
          this.spinnerService.hide();
          this.toaster.error("Something went wrong!");
        }
      )
    }

    getWorkById(id : number){
     // this.spinnerService.show();
      this.serverAPIService.getClientWork(id)
      .subscribe(
        (data:Work) => {
          this.work=data;
          this.clientId=this.work.ClientID;
          this.serviceId=this.work.ServiceID;
         // this.f.ddlFirm.setValue(this.work.FirmID);
          // if(this.work.AppUserID !=0)
          //   this.f.ddlAssistant.setValue(this.work.AppUserID);
          this.f.txtFee.setValue(this.work.Fee);
          this.f.ddlBillingTp.setValue(this.work.BillingType);
          this.f.txtRec.setValue(this.work.FileNo);
          this.actualAssi=[];
          this.work.AssistantMap.forEach(element => {
            this.actualAssi.push(element.AssistantID.toString());
           });
          if(this.work.Completed){
            this.f.txtFee.disable();
            this.f.ddlBillingTp.disable();
          }
          this.getClientById(this.clientId);
          this.getFirms();
          this.spinnerService.hide();
        },
        (error) => {
          this.spinnerService.hide();
          this.toaster.error('Something went wrong!');
        }
      );
    }

    getClientById(id : number){
      this.serverAPIService.getClientById(id)
      .subscribe(
        (data:Client) => {
          this.client=data;
          this.getFirms();
          this.getServiceById(this.serviceId);
        },
        (error) => {
          this.toaster.error('Something went wrong!');
        }
      );
    }

    getServiceById(id : number){
      this.serverAPIService.getServiceById(id)
      .subscribe(
        (data:Service) => {
          this.serviceName=data.ServiceName;
        },
        (error) => {
          this.toaster.error('Something went wrong!');
        }
      );
    }

    onSubmit(form: NgForm){

      // if(this.isEditMode)
      //   this.update(form);
      // else
        this.create(form);
    }

    create(form: NgForm){

      this.spinnerService.show();
      let t = new Work();

      if(this.f.ddlAssistant.value !=undefined && this.f.ddlAssistant.value!=''){
      //t.AppUserID= parseInt( this.f.ddlAssistant.value);
        t.AssistantMap=[];
      this.f.ddlAssistant.value.forEach(element => {
        t.AssistantMap.push({AssistantMapID:0, AssistantID:element,WorkID:0});
       });
    }
      t.BillingType=this.f.ddlBillingTp.value;
      t.Fee=this.f.txtFee.value;
      t.FileNo=this.f.txtRec.value;
      t.FirmID=this.f.ddlFirm.value;
      t.WorkID=this.work.WorkID;
      t.ClientID=this.clientId;
      t.ServiceID=this.serviceId;

      this.serverAPIService.updateWork(t)
      .subscribe(
        (data: any) => {
          this.spinnerService.hide();
          this.toaster.success("Service updated successfully!");
          if(this.returnUrl!=''){
            //this.router.navigate([this.returnUrl]);
            this.router.navigate([this.returnUrl],{queryParams:{ fyYear: this.work?.FinancialYear,selectedTasks:this.taskSwitchBit }});
          }
          else{
            this.router.navigate(['/service/client-service-list/'+this.clientId]);
          }

        },
        (error) => {
          this.spinnerService.hide();
          this.toaster.error(error);
        });


    }
    onCancel(){
      if(this.returnUrl!=""){
        this.router.navigate([this.returnUrl],{queryParams:{ fyYear: this.work?.FinancialYear,selectedTasks:this.taskSwitchBit }});
      }
      else{
        this.router.navigate(['/service/client-service-list/'+this.clientId]);
      }
      //[routerLink]='returnUrl!=""?returnUrl:"/service/client-service-list/"+this.clientId' [queryParams]="{ fyYear: work?.FinancialYear }"
    }
}
