
export class Receipt{
    public  ReceiptID:number;
    public ClientID:number;
    public  ReceiptDate:Date;
    public  Amount:number;
    public  ReceiptNo:string;
    public  CreatedBy:string;
    public  CreationDate:Date;
    public  AppUserId:number;
    public  FinancialYear:string;
    public  ReceiptType:string;
    public  ChequeNo:string;
    public  ChequeDate:Date;
    public  BankDetails:string;
    public  ReceiptDateStr :string;
    public  EntityName :string;
    public ChequeDateStr:string;
    public Description:string;
    public  FirmID:number;
    public  FirmName:string;
    public  ClientName:string;

    constructor(){}
}
